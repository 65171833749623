/* eslint-disable react/prop-types */

import React, { useState } from "react";
import PropTypes from "prop-types";
import AssignmentDataTable from "examples/Tables/AssignmentDatatable";
import ButtonCell from "./components/ButtonCell";
import { Modal, Backdrop, Fade, Card, CardContent, Button } from "@mui/material";

const AllApplicationTracking = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  // console.log("course", data);

  const openModal = (application) => {
    setSelectedApplication(application);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedApplication(null);
    setIsModalOpen(false);
  };

  const handleApprove = () => {
    if (selectedApplication) {
      const userId = selectedApplication.user_id;
      const courseId = selectedApplication.course_id;
      const token = localStorage.getItem("token");

      const requestData = {
        user_id: userId,
        data: [
          {
            meta_key: `application_${courseId}`,
            meta_value: "approved",
          },
        ],
      };

      fetch("https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (response.ok) {
            closeModal();
          } else {
            console.error("API Request Error");
          }
        })
        .catch((error) => {
          console.error("Fetch Error:", error);
        });
    }
  };

  const handleReject = () => {
    if (selectedApplication) {
      const userId = selectedApplication.user_id;
      const courseId = selectedApplication.course_id;
      const token = localStorage.getItem("token");

      const requestData = {
        user_id: userId,
        data: [
          {
            meta_key: `application_${courseId}`,
            meta_value: "rejected",
          },
        ],
      };

      fetch("https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (response.ok) {
            closeModal();
          } else {
            console.error("API Request Error");
          }
        })
        .catch((error) => {
          console.error("Fetch Error:", error);
        });
    }
  };

  const columns = [
    {
      Header: "User ID",
      accessor: "user_id",
    },
    {
      Header: "Name",
      accessor: "full_name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone No.",
      accessor: "phone_number",
    },
    {
      Header: "Program",
      accessor: "course_title",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      accessor: "course_id",
      Cell: ({ row }) => (
        <>
          {row.original.status === "applied" && (
            <ButtonCell color="primary" label="Approve" onClick={() => openModal(row.original)} />
          )}
        </>
      ),
    },
  ];

  const rows = data.map((application) => ({
    user_id: application.user_id,
    full_name: application.full_name,
    email: application.email,
    phone_number: application.phone_number,
    course_title: application.course_title,
    status: application.status,
    course_id: application.course_id,
  }));

  const customStyles = {
    background: "#9425B9",
    color: "#FFFFFF",
  };

  return (
    <>
      <AssignmentDataTable
        heading="Application Tracking"
        table={{ columns, rows }}
        canSearch
        sx={customStyles}
      />

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Card>
              <CardContent>
                <h2>Application Details</h2>
                {selectedApplication && (
                  <>
                    <div style={{ marginTop: "20px" }}>
                      <p>Are you sure you want to take action on this application?</p>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleApprove}
                        style={{ marginRight: "10px" }}
                      >
                        Approve
                      </Button>
                      <Button variant="contained" color="error" onClick={handleReject}>
                        Reject
                      </Button>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

AllApplicationTracking.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone_number: PropTypes.string.isRequired,
      course_title: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      course_id: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AllApplicationTracking;
