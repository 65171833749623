import React, { useEffect, useState } from "react";
import { Card, Grid, Typography, Button } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import Slider from "react-slick"; // Import the Slider component from react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider-custom.css";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

const eventData1 = {
  image:
    "https://static.vecteezy.com/system/resources/thumbnails/022/799/016/small/a-man-meditate-in-music-relaxation-meditation-generative-ai-photo.jpeg",
  title: "Event Title",
  date: "August 25, 2023",
  city: "New Delhi",
  registerLink: "https://example.com/register",
  sections: [
    { title: "Program Details", isSelected: false },
    { title: "Program Iternary", isSelected: false },
    { title: "Location Details", isSelected: false },
    { title: "Event Confirmation", isSelected: false },
  ],
  suggestions: [
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy7DIDiEA2OJ3Tf_VulwJEIkIhGhAAsgQqyb4lepa5qMMeJCxvDd2WM6qs50WeH2wqquY&usqp=CAU",
      title: "Suggested Event 1",
      description: "Description of Suggested Event 1",
      action: {
        type: "internal",
        route: "/suggested-event",
        color: "info",
        label: "View",
      },
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy7DIDiEA2OJ3Tf_VulwJEIkIhGhAAsgQqyb4lepa5qMMeJCxvDd2WM6qs50WeH2wqquY&usqp=CAU",
      title: "Suggested Event 2",
      description: "Description of Suggested Event 2",
      action: {
        type: "internal",
        route: "/suggested-event",
        color: "info",
        label: "View",
      },
    },
    // Add more suggestions
  ],
};
const data = [
  {
    image: "https://www.playpodcast.net/wp-content/uploads/2022/05/sleep-meditations-2048x908.png",
    title: "Event Title",
    description: "Description of Event",
    action: {
      type: "internal",
      route: "/podcast-listing",
      color: "info",
      label: "View Event",
    },
  },
  {
    image: "https://www.playpodcast.net/wp-content/uploads/2022/05/sleep-meditations-2048x908.png",
    title: "Event Title",
    description: "Description of Event",
    action: {
      type: "internal",
      route: "/podcast-listing",
      color: "info",
      label: "View Event",
    },
  },
  {
    image: "https://www.playpodcast.net/wp-content/uploads/2022/05/sleep-meditations-2048x908.png",
    title: "Event Title",
    description: "Description of Event",
    action: {
      type: "internal",
      route: "/podcast-listing",
      color: "info",
      label: "View Event",
    },
  },
  {
    image: "https://www.playpodcast.net/wp-content/uploads/2022/05/sleep-meditations-2048x908.png",
    title: "Event Title",
    description: "Description of Event",
    action: {
      type: "internal",
      route: "/podcast-listing",
      color: "info",
      label: "View Event",
    },
  },
  {
    image: "https://www.playpodcast.net/wp-content/uploads/2022/05/sleep-meditations-2048x908.png",
    title: "Event Title",
    description: "Description of Event",
    action: {
      type: "internal",
      route: "/podcast-listing",
      color: "info",
      label: "View Event",
    },
  },
  {
    image: "https://www.playpodcast.net/wp-content/uploads/2022/05/sleep-meditations-2048x908.png",
    title: "Event Title",
    description: "Description of Event",
    action: {
      type: "internal",
      route: "/podcast-listing",
      color: "info",
      label: "View Event",
    },
  },
  {
    image: "https://www.playpodcast.net/wp-content/uploads/2022/05/sleep-meditations-2048x908.png",
    title: "Event Title",
    description: "Description of Event",
    action: {
      type: "internal",
      route: "/podcast-listing",
      color: "info",
      label: "View Event",
    },
  },
  {
    image: "https://www.playpodcast.net/wp-content/uploads/2022/05/sleep-meditations-2048x908.png",
    title: "Event Title",
    description: "Description of Event",
    action: {
      type: "internal",
      route: "/podcast-listing",
      color: "info",
      label: "View Event",
    },
  },
  {
    image: "https://www.playpodcast.net/wp-content/uploads/2022/05/sleep-meditations-2048x908.png",
    title: "Event Title",
    description: "Description of Event",
    action: {
      type: "internal",
      route: "/podcast-listing",
      color: "info",
      label: "View Event",
    },
  },
  {
    image: "https://www.playpodcast.net/wp-content/uploads/2022/05/sleep-meditations-2048x908.png",
    title: "Event Title",
    description: "Description of Event",
    action: {
      type: "internal",
      route: "/podcast-listing",
      color: "info",
      label: "View Event",
    },
  },
  {
    image: "https://www.playpodcast.net/wp-content/uploads/2022/05/sleep-meditations-2048x908.png",
    title: "Event Title",
    description: "Description of Event",
    action: {
      type: "internal",
      route: "/podcast-listing",
      color: "info",
      label: "View Event",
    },
  },
];

function EventDetails() {
  const [selectedSection, setSelectedSection] = useState(0); // Select the first section by default
  const eventID = localStorage.getItem("eventId");
  const eventType = localStorage.getItem("eventType");
  const [eventData, setEventData] = useState(null); // State to store event data

  const handleSectionClick = (index) => {
    setSelectedSection(index);
  };
  function formatDate(inputDate) {
    // Split the input date string into day, month, and year
    const [day, month, year] = inputDate?.split("/");

    // Create a JavaScript Date object
    const date = new Date(`${year}-${month}-${day}`);

    // Define an array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get the day, month, and year from the Date object
    const dayOfMonth = date.getDate();
    const monthIndex = date.getMonth();
    const fullYear = date.getFullYear();

    // Create a function to add the appropriate suffix to the day
    function getDayWithSuffix(day) {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      } else {
        switch (day % 10) {
          case 1:
            return `${day}st`;
          case 2:
            return `${day}nd`;
          case 3:
            return `${day}rd`;
          default:
            return `${day}th`;
        }
      }
    }

    // Format the date string
    const formattedDate = `${getDayWithSuffix(dayOfMonth)} ${monthNames[monthIndex]} ${fullYear}`;

    return formattedDate;
  }

  const renderSelectedSectionBody = () => {
    if (selectedSection !== null) {
      return (
        <div>
          {/* Content for the selected section */}
          <Typography variant="body1">
            This is the content for {eventData1.sections[selectedSection].title}.
          </Typography>
        </div>
      );
    }
    return null;
  };
  const fetchEventData = async () => {
    try {
      let apiUrl = "";

      if (eventType === "online") {
        apiUrl = `https://dev.guruatmananda.org/wp-json/bts/v1/single-webinar/${eventID}/1`;
      } else if (eventType === "city") {
        apiUrl = `https://dev.guruatmananda.org/wp-json/bts/v1/single-city/${eventID}/1`;
      }

      const response = await fetch(apiUrl);
      const data = await response.json();
      setEventData(data);
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  // Fetch event data when the component mounts
  useEffect(() => {
    fetchEventData();
  }, []);
  // console.log("eventData", eventData);
  const slidertwoSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const eventDate =
    eventData && eventData.start_day && eventData.end_day
      ? `${formatDate(eventData.start_day)} to ${formatDate(eventData.end_day)}`
      : "";

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3} py={5}>
        {/* Left Section */}
        <Grid item xs={12} md={9}>
          <Card>
            <Grid container>
              {/* Left Image */}
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "16px",
                }}
              >
                <img
                  src={eventData?.featured_image}
                  alt="Event"
                  style={{ maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto" }}
                />
              </Grid>

              {/* Right Event Details */}
              <Grid item xs={12} md={6} lg={9} style={{ padding: "16px" }}>
                <Typography variant="h4" gutterBottom>
                  {eventData?.title}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Event Date :{eventDate}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Event Address :{eventData?.address}
                </Typography>
                <MDButton variant="contained" color="warning" href={eventData1.registerLink}>
                  Register
                </MDButton>
              </Grid>
            </Grid>
            {/* Headings */}
            <div
              style={{ display: "flex", justifyContent: "space-between", padding: "16px 16px 0" }}
            >
              {eventData1.sections.map((section, index) => (
                <Typography
                  key={index}
                  variant="subtitle1"
                  onClick={() => handleSectionClick(index)}
                  style={{
                    cursor: "pointer",
                    marginBottom: "8px",
                    color: selectedSection === index ? "#9609AE" : "inherit", // Change color based on selection
                  }}
                >
                  {section.title}
                </Typography>
              ))}
            </div>
            {/* Selected Section Body */}
            <div style={{ padding: "16px" }}>{renderSelectedSectionBody()}</div>
          </Card>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={3}>
          <Typography variant="h5" gutterBottom>
            Event Suggestions
          </Typography>
          <Grid container spacing={2}>
            {eventData1.suggestions.map((suggestion, index) => (
              <Grid key={index} item xs={12}>
                <DefaultProjectCard
                  image={suggestion.image}
                  title={suggestion.title}
                  description={suggestion.description}
                  action={suggestion.action}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <MDBox py={3}>
        <MDTypography
          variant="h2"
          fontWeight="bold"
          style={{ color: "#9103A7" }}
          textTransform="capitalize"
          py={3}
        >
          Suggested Meditation
        </MDTypography>
        <Slider {...slidertwoSettings}>
          {data.map((project, index) => (
            <Grid item xs={12} md={6} xl={3} key={index}>
              <MDBox px={2}>
                <img src={project.image} style={{ height: "100%", width: "100%" }} />
              </MDBox>
            </Grid>
          ))}
        </Slider>
      </MDBox>
      <MDTypography
        variant="h2"
        fontWeight="bold"
        style={{ color: "#9103A7" }}
        textTransform="capitalize"
        py={3}
      >
        Suggested Events
      </MDTypography>
      <Slider {...slidertwoSettings}>
        {data.map((podcast, index) => (
          <Grid key={index} item xs={12} sm={6} md={3}>
            <MDBox px={2}>
              <DefaultProjectCard
                image={podcast.image}
                title={podcast.title}
                description={podcast.description}
                action={podcast.action}
              />
            </MDBox>
          </Grid>
        ))}
      </Slider>
    </DashboardLayout>
  );
}

export default EventDetails;
