import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/account/components/FormField";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import { TextField } from "@mui/material";
import { Padding } from "@mui/icons-material";
import PropTypes from "prop-types";
import Loader from "components/Loader/Loader";
import ToasterMessage from "components/Toaster";

function BillingAddressMain({ userData }) {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    billing_firstName: "",
    billing_lastName: "",
    billing_country: "",
    billing_phoneNumber: "",
    billing_emailAddress: "",
    billing_address: "",
    billing_state: "",
    billing_city: "",
    billing_pincode: "",
    billing_governmentId: "",
  });

  useEffect(() => {
    if (userData) {
      setFormData({
        billing_firstName: userData.billing_firstName ? userData.billing_firstName : "",
        billing_lastName: userData.billing_lastName ? userData.billing_lastName : "",
        billing_country: userData.billing_country ? userData.billing_country : "",
        billing_phoneNumber: userData.billing_phoneNumber ? userData.billing_phoneNumber : "",
        billing_emailAddress: userData.billing_emailAddress ? userData.billing_emailAddress : "",
        billing_address: userData.billing_address ? userData.billing_address : "",
        billing_state: userData.billing_state ? userData.billing_state : "",
        billing_city: userData.billing_city ? userData.billing_city : "",
        billing_pincode: userData.billing_pincode ? userData.billing_pincode : "",
        billing_governmentId: userData.billing_governmentId ? userData.billing_governmentId : "",
      });
    }
  }, [userData]);

  // console.log("formData", formData);

  const formRef = useRef(null);

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validations
    const {
      billing_firstName,
      billing_lastName,
      billing_country,
      billing_phoneNumber,
      billing_emailAddress,
      billing_address,
      billing_state,
      billing_city,
      billing_pincode,
      billing_governmentId,
    } = formData;

    if (
      !billing_firstName ||
      !billing_lastName ||
      !billing_country ||
      !billing_phoneNumber ||
      !billing_emailAddress ||
      !billing_address ||
      !billing_state ||
      !billing_city ||
      !billing_pincode ||
      !billing_governmentId
    ) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }
    const payload = {
      user_id: userDetails?.user_data?.sub,
      data: [
        ...Object.entries(formData).map(([key, value]) => ({
          meta_key: key,
          meta_value: value,
        })),
      ],
    };

    try {
      const response = await fetch(
        "https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/update",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        // console.log("Profile updated successfully!");
        setSuccessMessage("Profile updated successfully!");

        // Fetch the updated user data from the API
        const updatedDataResponse = await fetch(
          `https://dev.guruatmananda.org//wp-json/custom-user-meta/v1/fetch/${userDetails?.user_data?.sub}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (updatedDataResponse.ok) {
          const updatedUserData = await updatedDataResponse.json();
          // Handle the updated user data as needed
          // console.log("Updated user data:", updatedUserData);
        } else {
          setErrorMessage("Error fetching updated user data");

          console.error("Error fetching updated user data:", updatedDataResponse.statusText);
        }

        // Redirect to the desired page after successful update
        // navigate("/dashboards/enrolled-courses");
      } else {
        setErrorMessage("Error updating profile");

        console.error("Error updating profile:", response.statusText);
      }
    } catch (error) {
      setErrorMessage("Error updating profile");

      console.error("Error updating profile:", error);
    }
  };
  const handleToasterDismiss = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  if (!userDetails) {
    return <Navigate to="/auth/login" replace />;
  }

  return (
    <>
      <Card id="basic-info" pb={3} sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">Billing Address</MDTypography>
        </MDBox>
        <form onSubmit={handleSubmit}>
          <MDBox component="form" pb={3} px={3} ref={formRef}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="First Name"
                  value={formData.billing_firstName}
                  onChange={handleInputChange}
                  name="billing_firstName"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Last Name"
                  value={formData.billing_lastName}
                  onChange={handleInputChange}
                  name="billing_lastName"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Address"
                  value={formData.billing_address || ""}
                  onChange={handleInputChange}
                  name="billing_address"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="tel"
                  label="Phone Number"
                  value={formData.billing_phoneNumber}
                  onChange={handleInputChange}
                  name="billing_phoneNumber"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="email"
                  label="Email Address"
                  value={formData.billing_emailAddress}
                  onChange={handleInputChange}
                  name="billing_emailAddress"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Government ID"
                  value={formData.billing_governmentId}
                  onChange={handleInputChange}
                  name="billing_governmentId"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Country"
                  value={formData.billing_country}
                  onChange={handleInputChange}
                  name="billing_country"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="State"
                  value={formData.billing_state}
                  onChange={handleInputChange}
                  name="billing_state"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="City"
                  value={formData.billing_city}
                  onChange={handleInputChange}
                  name="billing_city"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  label="Pincode"
                  value={formData.billing_pincode}
                  onChange={handleInputChange}
                  name="billing_pincode"
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" alignItems="center" p={2}>
            <MDButton
              color="dark"
              type="submit"
              size="small"
              sx={{
                background: "linear-gradient(310deg, #7928CA, #FF0080)",
                color: "white", // You might need to adjust the text color for better contrast
              }}
            >
              Update
            </MDButton>
          </MDBox>
        </form>
      </Card>
      {successMessage && (
        <ToasterMessage
          message={successMessage}
          color="success"
          duration={1000}
          onDismiss={handleToasterDismiss}
        />
      )}

      {errorMessage && (
        <ToasterMessage
          message={errorMessage}
          color="error"
          duration={1000}
          onDismiss={handleToasterDismiss}
        />
      )}
    </>
  );
}

BillingAddressMain.propTypes = {
  userData: PropTypes.object,
};

export default BillingAddressMain;
