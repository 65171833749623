import React, { useState, useEffect } from "react";
import { Card, Grid, Typography, List, ListItem, ListItemText } from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars"; // Import Scrollbars from react-custom-scrollbars
import AudioPlayerTest from "layouts/courses/classroom/lecture/components/AudioPlayerTest";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider-custom.css";
import MDBox from "components/MDBox";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

function PodcastDetails() {
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [selectedEpisodeIndex, setSelectedEpisodeIndex] = useState(0);
  const [podcasts, setPodcasts] = useState([]);
  const [storedPodcastID, setStoredPodcastID] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://dev.guruatmananda.org/wp-json/podcast/v1/podcasts")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setPodcasts(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // You can add additional error handling here, such as displaying an error message to the user.
      });

    // Retrieve the podcast ID from localStorage
    const storedID = localStorage.getItem("podcastID");
    setStoredPodcastID(storedID);
  }, []);

  const selectedPodcast = podcasts.find((podcast) => podcast.ID === parseInt(storedPodcastID));

  const slidertwoSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (selectedPodcast) {
      setSelectedEpisode(selectedPodcast.podcast_list[0]);
    }
  }, [selectedPodcast]);

  const handleEpisodeClick = (index) => {
    setSelectedEpisodeIndex(index);
    setSelectedEpisode(selectedPodcast.podcast_list[index]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card py={3} style={{ padding: "16px", backgroundColor: "#E5E7E8" }}>
        <Grid container spacing={2} alignItems="center">
          {/* Left Section: Podcast Image */}
          <Grid item xs={12} md={6}>
            <img
              src={selectedPodcast?.featured_image_url}
              alt="Podcast"
              style={{ width: "100%", height: "auto" }}
            />
            {selectedEpisode && <AudioPlayerTest source={selectedEpisode.podcast_link} />}
          </Grid>

          {/* Right Section: Episode List */}
          <Grid item xs={12} md={6}>
            {/* Podcast Title */}
            <Typography variant="h4" gutterBottom>
              {selectedPodcast ? selectedPodcast.title : "Podcast Title Not Found"}
            </Typography>

            {/* Episode List with Scrollbar */}
            {selectedPodcast ? (
              <Scrollbars style={{ height: 300 }}>
                {" "}
                {/* Adjust the height as needed */}
                <List>
                  {selectedPodcast.podcast_list.map((episode, index) => (
                    <ListItem
                      button
                      onClick={() => handleEpisodeClick(index)}
                      key={index}
                      style={{
                        padding: "8px 0",
                        backgroundColor: selectedEpisodeIndex === index ? "#581C76" : "transparent",
                        color: selectedEpisodeIndex === index ? "white" : "black",
                      }}
                    >
                      <ListItemText primary={episode.podcast_name} />
                    </ListItem>
                  ))}
                </List>
              </Scrollbars>
            ) : (
              <Typography variant="body2">No episodes found for this podcast.</Typography>
            )}

            {/* Audio Player */}
          </Grid>
        </Grid>
      </Card>
      <Typography variant="h5" py={3}>
        Podcasts Suggestion
      </Typography>

      {/* <Slider {...slidertwoSettings}>
        {data.map((podcast, index) => (
          <Grid key={index} item xs={12} sm={6} md={3}>
            <MDBox px={2}>
              <DefaultProjectCard
                image={podcast.image}
                title={podcast.title}
                description={podcast.description}
                action={podcast.action}
              />
            </MDBox>
          </Grid>
        ))}
      </Slider> */}
    </DashboardLayout>
  );
}

export default PodcastDetails;
