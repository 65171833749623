import React, { useState } from "react";
import moment from "moment";
import "./ChatWindow.css";
import ChatHeader from "./ChatHeader";
import PropTypes from "prop-types"; // Import the prop-types library
import styled from "styled-components";

function ChatWindow({ selectedChat }) {
  // <-- Accept selectedChat as a prop
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;
    const message = {
      text: newMessage.trim(),
      timestamp: moment().format("HH:mm"),
      sender: "user", // You can use 'user' or 'recipient' depending on the sender
    };
    // Update the messages array of the selected chat
    selectedChat.messages.push(message);
    setNewMessage("");
  };

  return (
    <ChatWindowContainer>
      <ChatHeader selectedChat={selectedChat} />
      <div className="message-container">
        {selectedChat.messages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.sender === "user" ? "user" : "recipient"}`}
          >
            <div className="message-text">{message.text}</div>
            <div className="message-timestamp">{message.timestamp}</div>
          </div>
        ))}
      </div>
      <InputContainer>
        <Input
          type="text"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <SendButton onClick={handleSendMessage}>Send</SendButton>
      </InputContainer>
    </ChatWindowContainer>
  );
}

const ChatWindowContainer = styled.div`
  flex: 0.7;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e0e0e0;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  position: sticky;
  bottom: 0;
`;

const Input = styled.input`
  flex: 1;
  background-color: transparent;
  border: none;
  outline: none;
  color: #344767;
  font-size: 16px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;

  &::placeholder {
    color: #919191;
  }
`;

const SendButton = styled.button`
  background-color: #e91e63;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 10px;
`;

// Prop type validation for the ChatWindow component
ChatWindow.propTypes = {
  selectedChat: PropTypes.shape({
    profilePic: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        timestamp: PropTypes.string.isRequired,
        sender: PropTypes.oneOf(["user", "recipient"]).isRequired,
      })
    ).isRequired,
    // Add additional properties here, if needed
  }).isRequired,
};

export default ChatWindow;
