import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Grid } from "@mui/material";
import ComplexProjectCardClone from "examples/Cards/ProjectCards/ComplexProjectCard copy";
import OrderList from "../order-list";
import SubscriptionsList from "../subscriptions-list";
import LoaderNew from "components/Loader/LoaderNew";

function CompleteOrderList() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [order, setOrder] = useState(true);
  const [subscription, setSubscription] = useState(false);
  const [balance, setBalance] = useState("");
  const [loading, setLoading] = useState(true); // Loading indicator
  const [error, setError] = useState(null);

  useEffect(() => {
    // const token = localStorage.getItem("token");
    const username = "ck_e07619a34325d4367c758704b04844c734ecc86d";
    const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
    const basicAuth = btoa(`${username}:${password}`);

    const apiUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/wallet/balance?email=${user?.user_email}`;

    const fetchWalletBalance = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Basic ${basicAuth}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const balance = data?.balance;
          setBalance(balance);
          setError(null);
        } else {
          setError("Error fetching wallet balance");
        }
      } catch (error) {
        setError("Error fetching wallet balance");
      } finally {
        setLoading(false); // Turn off loading indicator whether successful or not
      }
    };

    fetchWalletBalance();
  }, []);

  const handleOrder = () => {
    setOrder(true);
    setSubscription(false);
  };

  const handleSubscription = () => {
    setOrder(false);
    setSubscription(true);
  };

  const handleTransfer = () => {
    console.log("Transfer Working");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        {/* <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={6}>
              <ComplexProjectCardClone
                icon="currency_exchange_icon"
                iconColor="info"
                title="Upcoming Donation"
                description="Transfer funds seamlessly using digital wallet: instant, secure, and convenient transactions."
                buttonLabel="Pay"
                onButtonClick={handleTransfer}
                buttonColor="info"
                webinarTitle="Savyasachi Program"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              {loading ? ( // Display loading spinner while fetching data
                "Loading..."
              ) : (
                <ComplexProjectCardClone
                  color="info"
                  icon="currency_rupee_icon"
                  iconColor="info"
                  title="Balance"
                  description="Wallet securely stores payment methods for convenient and fast online transactions."
                  value={`₹ ${error ? "Error" : balance}`}
                />
              )}
            </Grid>
          </Grid>
        </MDBox> */}
        <MDBox m={3}>
          <MDBox display="flex" justifyContent="normal" alignItems="flex-start" mb={2}>
            <MDButton
              variant="contained"
              style={{
                background: order ? "linear-gradient(310deg, #7928CA, #FF0080)" : "",
                color: order ? "white" : "#344767",
                marginRight: "10px",
              }}
              onClick={handleOrder}
              px={2}
            >
              Order
            </MDButton>
            <MDButton
              variant="contained"
              style={{
                background: subscription ? "linear-gradient(310deg, #7928CA, #FF0080)" : "",
                color: subscription ? "white" : "#344767",
              }}
              onClick={handleSubscription}
            >
              Subscription
            </MDButton>
          </MDBox>
        </MDBox>
        {order && <OrderList />}
        {subscription && <SubscriptionsList />}
      </MDBox>
    </DashboardLayout>
  );
}

export default CompleteOrderList;
