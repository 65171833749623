import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import EventCard from "examples/Cards/BlogCards/EventCard";
import logoSd from "./SD_LOGO.png";
import LoaderNew from "components/Loader/LoaderNew";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

function Programs() {
  const [meditationData, setMeditationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { programSlugGlobal } = useHookstate(globalStore);
  const handleExploreClick = (programId) => {
    // console.log("Explore clicked for program ID:", programId);
    const clickedMeditation = meditationData.find((item) => item.id === programId);
    // console.log("Meditation Data for clicked program:", clickedMeditation);
    // localStorage.setItem("programSlug", clickedMeditation.slug);
    programSlugGlobal.set(clickedMeditation.slug);
  };

  useEffect(() => {
    fetch("https://dev.guruatmananda.org/wp-json/bts/v1/courses_programs")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setMeditationData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  // Return an error message if there's an error
  if (error) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDTypography variant="h6" color="error">
            An error occurred while fetching data.
          </MDTypography>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }

  // Return a loader if data is still being fetched
  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <LoaderNew />
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            {meditationData.length > 0 ? (
              meditationData.map((item) => (
                <Grid item xs={12} md={6} lg={4} key={item?.id}>
                  <MDBox mb={3}>
                    <EventCard
                      // totalCourses={item?.post_count}
                      image={item?.category_image}
                      title={item?.name}
                      description={item?.description}
                      action={{
                        type: "internal",
                        route: `/dashboards/enrolled-courses`,
                        color: "success",
                        label: "View Courses",
                        onClick: () => handleExploreClick(item.id), // Pass program ID
                      }}
                    />
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <MDBox mt={3}>
                  <MDTypography variant="h6" fontWeight="bold">
                    No Subscription found
                  </MDTypography>
                </MDBox>
              </Grid>
            )}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Programs;
