import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import logo from "./Sanmarga Awakening 01.png";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

function SignInIllustration() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { userGlobal, tokenGlobal } = useHookstate(globalStore);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://dev.guruatmananda.org/wp-json/jwt-auth/v1/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: email,
          password: password,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        let errorMessage = "An error occurred during login.";

        console.log(response);
        if (response.status === 403) {
          if (responseData.code === "[jwt_auth] incorrect_password") {
            errorMessage = "The password you entered is incorrect.";
          } else if (responseData.code === "[jwt_auth] invalid_email") {
            errorMessage = "Unknown email address. Check again or try your username.";
          }
        }

        throw new Error(errorMessage);
      }

      const token = responseData?.token;
      localStorage.setItem("user", JSON.stringify(responseData));
      localStorage.setItem("token", token);
      tokenGlobal.set(token);
      userGlobal.set(JSON.stringify(responseData));

      navigate("/dashboards/programs");
    } catch (error) {
      console.log(error);
      setError(
        "The email address or password you entered isn't connected to an account." ||
          error.message ||
          "An unexpected error occurred during login."
      );
    }
  };

  return (
    <IllustrationLayout
      // title="Sign In"
      logo={logo}
      // description="Enter your email and password to sign in"
      illustration="https://runcloud-programs.s3.ap-south-1.amazonaws.com/SD+login.png"
    >
      <MDBox component="form" role="form" onSubmit={handleFormSubmit}>
        <MDBox mb={2}>
          <MDInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            fullWidth
          />
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Remember me
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" size="large" fullWidth>
            sign in
          </MDButton>
        </MDBox>
        <MDBox textAlign="center">
          <MDTypography variant="button" color="text">
            Trouble logging in ?{"  "}
            <MDTypography
              component={Link}
              to="/reset-password"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Reset Password
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
      {error && <MDBox mb={2} color="error" dangerouslySetInnerHTML={{ __html: error }} />}
    </IllustrationLayout>
  );
}

export default SignInIllustration;
