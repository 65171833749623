import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Forward10SharpIcon from "@mui/icons-material/Forward10Sharp";
import Replay10SharpIcon from "@mui/icons-material/Replay10Sharp";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import brand from "./SD_LOGO.png";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import "./AudioPlayerTest.css";

const AudioPlayerTest = ({ source }) => {
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showControls, setShowControls] = useState(true);
  const [volume, setVolume] = useState(1.0);
  const volumeInputRef = useRef(null);
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);

  useEffect(() => {
    const audioElement = new Audio(source);
    audioElement.addEventListener("loadedmetadata", () => {
      setDuration(audioElement.duration);
    });
    setAudio(audioElement);

    return () => {
      if (audioElement) {
        audioElement.pause();
      }
    };
  }, [source]);

  useEffect(() => {
    if (audio) {
      isPlaying ? audio.play() : audio.pause();
    }
  }, [audio, isPlaying]);

  useEffect(() => {
    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    if (audio) {
      audio.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      if (audio) {
        audio.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [audio]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleSeek = (event) => {
    const seekTime = parseFloat(event.target.value);
    setCurrentTime(seekTime);
    if (audio) {
      audio.currentTime = seekTime;
    }
  };

  const handleSkipForward = () => {
    const newTime = Math.min(currentTime + 10, duration);
    setCurrentTime(newTime);
    if (audio) {
      audio.currentTime = newTime;
    }
  };

  const handleSkipBackward = () => {
    const newTime = Math.max(currentTime - 10, 0);
    setCurrentTime(newTime);
    if (audio) {
      audio.currentTime = newTime;
    }
  };

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (audio) {
      audio.volume = newVolume;
    }
  };

  const handleVolumeIconClick = () => {
    setShowVolumeSlider(!showVolumeSlider);
  };

  const handleVolumeSliderBlur = () => {
    setShowVolumeSlider(false);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const calculateSeekColor = () => {
    const percent = (currentTime / duration) * 100;
    return `linear-gradient(to right, #fff   0%, #fff   ${percent}%, #A58CB3  ${percent}%, #A58CB3  100%)`;
  };

  const calculateVolumeColor = () => {
    return `linear-gradient(to left, #A58CB3 0%, #A58CB3 ${(1 - volume) * 100}%, #fff ${
      (1 - volume) * 100
    }%, #fff 100%)`;
  };
  useEffect(() => {
    if (audio) {
      if (isPlaying) {
        audio.play();
        audio.classList.add("playing");
      } else {
        audio.pause();
        audio.classList.remove("playing");
      }
    }
  }, [audio, isPlaying]);

  const mediaPlayerButtonStyles = ({ functions: { pxToRem } }) => ({
    width: pxToRem(34),
    height: pxToRem(34),
    minWidth: pxToRem(34),
    minHeight: pxToRem(34),
    mr: 1,
  });
  return (
    <div className={`audio-player ${showControls ? "" : "hide-controls"}`}>
      <div className="control-group">
        <div className="time">
          <span>{formatTime(currentTime)}</span>
        </div>
        <input
          type="range"
          className="seek-bar"
          min={0}
          max={duration}
          step={0.1}
          value={currentTime}
          onChange={handleSeek}
          style={{ background: calculateSeekColor() }}
        />
        <div className="time">
          <span>{formatTime(duration)}</span>
        </div>
      </div>{" "}
      <div className="controls">
        <div className="left-control">
          {/* <div className={`player-image ${isPlaying ? "rotate" : ""}`}>
            <img src={brand} alt="Circular Image" className="image" />
          </div> */}
        </div>
        <div className="center-control">
          <div className="seek-button px-4" onClick={handleSkipBackward}>
            <SkipPreviousIcon sx={mediaPlayerButtonStyles} />
          </div>
          <div className="play-pause" onClick={handlePlayPause}>
            {isPlaying ? (
              <PauseIcon sx={mediaPlayerButtonStyles} />
            ) : (
              <PlayArrowIcon sx={mediaPlayerButtonStyles} />
            )}
          </div>
          <div className="seek-button" onClick={handleSkipForward}>
            <SkipNextIcon sx={mediaPlayerButtonStyles} />
          </div>
        </div>
        <div className="right-control-large">
          <div className="volume-controls">
            {showVolumeSlider && (
              <input
                ref={volumeInputRef}
                type="range"
                className="volume-slider"
                min={0}
                max={1}
                step={0.01}
                value={volume}
                onChange={handleVolumeChange}
                onBlur={handleVolumeSliderBlur}
                style={{ background: calculateVolumeColor() }}
              />
            )}
            {!showVolumeSlider && (
              <div className="volume-button" onClick={handleVolumeIconClick}>
                {volume > 0 ? (
                  <VolumeUpIcon sx={mediaPlayerButtonStyles} />
                ) : (
                  <VolumeDownIcon sx={mediaPlayerButtonStyles} />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="right-control-small">
          <div className="volume-controls">
            {showVolumeSlider && (
              <input
                ref={volumeInputRef}
                type="range"
                className="volume-slider"
                min={0}
                max={1}
                step={0.01}
                value={volume}
                onChange={handleVolumeChange}
                onBlur={handleVolumeSliderBlur}
                style={{ background: calculateVolumeColor() }}
              />
            )}
            <div className="volume-button" onClick={handleVolumeIconClick}>
              {volume > 0 ? (
                <VolumeUpIcon sx={mediaPlayerButtonStyles} />
              ) : (
                <VolumeDownIcon sx={mediaPlayerButtonStyles} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AudioPlayerTest.propTypes = {
  source: PropTypes.string.isRequired,
};

export default AudioPlayerTest;
