import { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import EventCalendar from "examples/Calendar";
import WebinarEvents from "./components/NextEvents";
import Loader from "components/Loader/Loader";
import Calendar from "examples/Calendar";

function WebinarFinal() {
  const [calendarData, setCalendarData] = useState(null);
  const [error, setError] = useState(null);
  const userData = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetch(
      `https://dev.guruatmananda.org//wp-json/custom/v1/webinars?user_id=${userData?.user_data?.sub}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCalendarData(data);
        setError(null);
      })
      .catch((error) => {
        setError(error.message);
        console.error(error);
      });
  }, [userData?.user_data?.sub]);
  console.log("data", calendarData);
  function convertDateFormat(dateString) {
    if (!dateString) return null;
    const parts = dateString.split("/");
    const day = parts[0].padStart(2, "0");
    const month = parts[1].padStart(2, "0");
    const year = parts[2];
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const getColor = (webinarDateTime) => {
    const webinarDate = new Date(webinarDateTime);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 2);

    if (webinarDate < today) {
      return "light";
    } else if (webinarDate.toDateString() === tomorrow.toDateString()) {
      return "success";
    } else {
      return "info";
    }
  };
  const today = new Date();
  const calendarEventsDataFinal = [];

  if (calendarData?.webinars && Array.isArray(calendarData.webinars)) {
    calendarData.webinars.forEach((webinar) => {
      if (
        webinar?.webinar_data?.course_content &&
        Array.isArray(webinar.webinar_data.course_content)
      ) {
        webinar.webinar_data.course_content.forEach((content) => {
          if (
            content?.webinar_data &&
            Array.isArray(content.webinar_data) &&
            content.webinar_data[0]?.course_data_type === "Webinar"
          ) {
            content.webinar_data.forEach((webinarData) => {
              const startDate = convertDateFormat(
                webinarData?.webinar_date_and_time?.substring(0, 10)
              );
              const startTime = webinarData?.webinar_date_and_time?.substring(11, 16);
              const endDate = startDate;
              const endTime = startTime;
              const startDateTime = `${startDate} ${startTime}`;
              const endDateTime = `${endDate} ${endTime}`;
              const color = getColor(startDateTime);
              // console.log("startDate", startDate);
              // console.log("startTime", startTime);

              calendarEventsDataFinal.push({
                title: webinarData?.title,
                start: startDateTime,
                end: endDateTime,
                url: webinarData?.webinar_link,
                className: color,
              });
            });
          }
        });
      }
    });
  }

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const initialDate = `${currentYear}-${currentMonth}-01`;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0}>
        <MDBox mb={3}>{calendarData && <WebinarEvents />}</MDBox>

        {error && <div>No webinar available</div>}
        {useMemo(
          () => (
            <Calendar
              initialView="dayGridMonth"
              initialDate={initialDate}
              events={calendarEventsDataFinal}
              selectable
              editable
            />
          ),
          [calendarEventsDataFinal]
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default WebinarFinal;
