import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import Loader from "components/Loader/Loader";
import VideoModal from "./VideoModal";
import imageComing from "./ComingSoon2.png";

function TutorialVideos() {
  const [openModal, setOpenModal] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [videoData, setVideoData] = useState([]); // Initialize with an empty array

  const handleVideoClick = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    fetch("https://dev.guruatmananda.org/wp-json/custom/v1/get-tutorial_videos/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setVideoData(data || []); // Use the data from the API
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  // console.log("vid", videoData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            {videoData.length > 0 ? (
              videoData.map((item) => (
                <Grid item xs={12} md={6} lg={3} key={item.id}>
                  <MDBox mt={3}>
                    <SimpleBlogCard
                      image={item.featured_image_url}
                      title={
                        <MDTypography variant="h6" fontWeight="bold" fontSize={18}>
                          {item.title}
                        </MDTypography>
                      }
                      // description={item.excerpt || "No description available."}
                      action={{
                        type: "internal",
                        color: "info",
                        label: (
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => handleVideoClick(item.post_video_link)}
                          >
                            View Video
                          </span>
                        ),
                      }}
                    />
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <MDBox mt={3}>
                  <img src={imageComing} style={{ height: "auto", width: "100%" }} />
                </MDBox>
              </Grid>
            )}
            <VideoModal open={openModal} onClose={handleCloseModal} videoUrl={currentVideoUrl} />
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TutorialVideos;
