import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
/* eslint-disable react/prop-types */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
function SubscriptionsList() {
  const [menu, setMenu] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    let period = "AM";

    if (hours > 12) {
      hours -= 12;
      period = "PM";
    }

    const formattedTime = `${hours}:${minutes} ${period}`;

    return `${day}/${month}/${year} ${formattedTime}`;
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const token = localStorage.getItem("token");
        const user = JSON.parse(localStorage.getItem("user"));
        const username = "ck_e07619a34325d4367c758704b04844c734ecc86d";
        const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
        const basicAuth = btoa(`${username}:${password}`);
        const url = `https://dev.guruatmananda.org/wp-json/wc/v3/subscriptions?customer=${user?.user_data?.sub}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Basic ${basicAuth}`,
          },
        });
        const data = await response.json();
        setSubscriptions(data);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    };

    fetchSubscriptions();
  }, []);
  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Active</MenuItem>
      <MenuItem onClick={closeMenu}>Status: On Hold</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Expired</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  const dataTableDataSubscription = {
    columns: [
      { Header: "order id", accessor: "id", Cell: ({ value }) => <IdCell id={value} /> },
      {
        Header: "Subscription",
        accessor: "product",
        Cell: ({ value }) => {
          const [name, data] = value;

          return (
            <DefaultCell
              value={typeof value === "string" ? value : name}
              suffix={data.suffix || false}
            />
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          let status;

          if (value === "active") {
            status = <StatusCell icon="done" color="success" status="Active" />;
          } else if (value === "onhold") {
            status = <StatusCell icon="replay" color="dark" status="On Hold" />;
          } else {
            status = <StatusCell icon="close" color="error" status="Not Active" />;
          }

          return status;
        },
      },
      {
        Header: "Next Payment Date",
        accessor: "date",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      { Header: "Price", accessor: "revenue", Cell: ({ value }) => <DefaultCell value={value} /> },
    ],
    // rows: subscriptions.map((subscription) => ({
    //   id: `#${subscription?.id}`,
    //   plan: subscription?.plan.name,
    //   startDate: subscription.start_date,
    //   endDate: subscription.end_date,
    //   status: subscription.status,
    // })),
    rows:
      subscriptions?.map((subscription) => ({
        id: `#${subscription?.id}`,
        product: subscription?.line_items?.map((item) => item.name).join(", "),
        date: subscription?.next_payment_date_gmt,
        revenue: subscription?.line_items?.reduce(
          (price, item) => price + parseFloat(item.price),
          0
        ),
        status: subscription?.status,
      })) || [],
  };

  return (
    <Card>
      <DataTable
        heading="Subscription List"
        table={dataTableDataSubscription}
        entriesPerPage={true}
        canSearch
      />
    </Card>
  );
}

export default SubscriptionsList;
