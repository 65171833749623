import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
// import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import PropTypes from "prop-types";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const key = "ck_e07619a34325d4367c758704b04844c734ecc86d";
const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";

function Transactions(rechargeModalOpen, openModal) {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const userEmail = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const fetchTransactions = async () => {
      try {
        const response = await fetch(
          `https://dev.guruatmananda.org/wp-json/wc/v3/wallet?email=${userEmail?.user_email}&per_page=100&page=1`,
          {
            headers: {
              Authorization: `Basic ${btoa(`${key}:${password}`)}`, // Include key and password in the Authorization header
            },
          }
        );

        if (response.ok) {
          const transactionsData = await response.json();
          setTransactions(transactionsData);
        } else {
          console.error("Error fetching transactions:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, [openModal, rechargeModalOpen]);
  const dataTableDataTransactions = {
    columns: [
      {
        Header: "Transaction Amount",
        accessor: "amount",
        Cell: ({ value, row }) => (
          <DefaultCell
            value={`${row.original.type === "credit" ? "+" : "-"} ${Number(value).toFixed(2)}`}
            style={{
              color:
                row.original.type === "debit"
                  ? "red"
                  : row.original.type === "credit"
                  ? "green"
                  : "inherit",
            }}
          />
        ),
      },
      {
        Header: "Transaction Date",
        accessor: "date",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Transaction ID",
        accessor: "transaction_id",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Transaction Note",
        accessor: "details",
        Cell: ({ value }) => <DefaultCell value={capitalizeFirstLetter(value)} />,
      },

      {
        Header: "Transaction Type",
        accessor: "type",
        Cell: ({ value }) => <DefaultCell value={capitalizeFirstLetter(value)} />,
      },
    ],
    rows: transactions.map((transaction) => ({
      transaction_id: transaction.transaction_id,
      details: transaction.details,
      date: transaction.date,
      type: transaction.type,
      amount: transaction.amount,
    })),
  };

  return (
    <Card>
      <DataTable
        heading="Transactions"
        table={dataTableDataTransactions}
        entriesPerPage={true}
        canSearch
      />
    </Card>
  );
}

Transactions.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
};

export default Transactions;
