import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, CardContent } from "@mui/material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

function PaymentPlans() {
  const [courseData, setCourseData] = useState();
  const [selectedPlan, setSelectedPlan] = useState(null); // To keep track of the selected plan
  const userId = JSON.parse(localStorage.getItem("user"));
  // const Slug = localStorage.getItem("pslug");
  const navigate = useNavigate();
  const { spidGlobal, pslugGlobal } = useHookstate(globalStore);

  const fetchCourseData = async () => {
    try {
      const courseApiUrl = `https://dev.guruatmananda.org/wp-json/bts/v1/single_user_course/${pslugGlobal.get(
        { noproxy: true }
      )}/${userId?.user_data?.sub}`;

      const response = await fetch(courseApiUrl);

      if (response.ok) {
        const data = await response.json();
        setCourseData(data);
      } else {
        console.error("Failed to fetch course data");
      }
    } catch (error) {
      console.error("Error fetching course data:", error);
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, []);

  useEffect(() => {
    if (courseData?.packages?.length > 0 && selectedPlan === null) {
      setSelectedPlan(courseData.packages[0]);
    }
  }, [courseData]);

  const handlePlanClick = (plan) => {
    setSelectedPlan(plan);
  };
  const handleSelectPlan = () => {
    if (selectedPlan) {
      // localStorage.setItem("spid", selectedPlan?.id);
      spidGlobal.set(selectedPlan?.id);

      navigate("/checkout");
    } else {
      console.log("No plan selected.");
    }
  };
  // console.log("pslugGlobal.get({noproxy:true})", pslugGlobal.get({ noproxy: true }));
  return pslugGlobal.get({ noproxy: true }) ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} textAlign="left">
        <MDTypography variant="h3" fontWeight="bold">
          {courseData?.title}
        </MDTypography>
      </MDBox>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={6} lg={8}>
          <MDBox mt={1}>
            <img src={courseData?.featured_image} alt="Program" style={{ maxWidth: "100%" }} />
          </MDBox>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <MDBox
            mt={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            {courseData?.packages?.length > 0 ? (
              courseData.packages.map((plan, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "16px",
                    cursor: "pointer",
                    width: "100%",
                    textAlign: "center",
                  }}
                  onClick={() => handlePlanClick(plan)}
                >
                  <Card
                    style={{
                      width: "100%",
                      backgroundColor: selectedPlan === plan ? "#dfc5fe" : "white",
                    }}
                  >
                    <CardContent
                      style={{
                        padding: "10px",
                        color: selectedPlan === plan ? "green" : "inherit",
                      }}
                    >
                      <MDTypography
                        variant="h5"
                        fontWeight="bold"
                        style={{ color: selectedPlan === plan ? "green" : "inherit" }}
                      >
                        {plan.name}
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        color="success"
                        style={{ color: selectedPlan === plan ? "black" : "green" }}
                      >
                        Price: ₹ {plan.price}
                      </MDTypography>
                    </CardContent>
                  </Card>
                </div>
              ))
            ) : (
              <MDTypography>No payment plans available.</MDTypography>
            )}
            <div
              style={{
                justifyContent: "center",
                marginTop: "20px",
                width: "50%",
              }}
            >
              <MDButton variant="contained" color="info" onClick={handleSelectPlan}>
                Proceed to checkout
              </MDButton>
            </div>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  ) : (
    navigate("/")
  );
}

export default PaymentPlans;
