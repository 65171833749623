import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AssignmentDataTable from "examples/Tables/AssignmentDatatable";
import ButtonCell from "./components/ButtonCell";
import { Modal, Backdrop, Fade, Card, CardContent } from "@mui/material";
import MDButton from "components/MDButton";
/* eslint-disable react/prop-types */

const AllCertificateTracking = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [updatedData, setUpdatedData] = useState(data);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setUpdatedData(data);
  }, [data]);

  const openModal = (rowData) => {
    setSelectedApplication(rowData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedApplication(null);
    setIsModalOpen(false);
  };

  const handleApprove = () => {
    if (selectedApplication) {
      const userId = selectedApplication.user_id;
      const courseId = selectedApplication.course_id;
      const token = localStorage.getItem("token");

      const requestData = {
        user_id: userId,
        data: [
          {
            meta_key: `course_completed_${courseId}`,
            meta_value: "certificateApproved",
          },
        ],
      };

      fetch("https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (response.ok) {
            closeModal();
            const updated = updatedData.map((item) =>
              item.user_id === userId ? { ...item, status: "certificateApproved" } : item
            );
            setUpdatedData(updated);
          } else {
            console.error("API Request Error");
          }
        })
        .catch((error) => {
          console.error("Fetch Error:", error);
        });
    }
  };

  const handleReject = () => {
    if (selectedApplication) {
      const userId = selectedApplication.user_id;
      const courseId = selectedApplication.course_id;
      const token = localStorage.getItem("token");

      const requestData = {
        user_id: userId,
        data: [
          {
            meta_key: `course_completed_${courseId}`,
            meta_value: "certificateRejected",
          },
        ],
      };

      fetch("https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (response.ok) {
            closeModal();
            const updated = updatedData.map((item) =>
              item.user_id === userId ? { ...item, status: "certificateRejected" } : item
            );
            setUpdatedData(updated);
          } else {
            console.error("API Request Error");
          }
        })
        .catch((error) => {
          console.error("Fetch Error:", error);
        });
    }
  };

  const handleUpload = (rowData) => {
    setSelectedApplication(rowData);
    setIsUploadModalOpen(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setUploadedFile(URL.createObjectURL(file));
    } else {
      setSelectedFile(null);
      setUploadedFile(null);
    }
  };

  const handleFileUpload = () => {
    if (selectedApplication && selectedFile) {
      const userId = selectedApplication.user_id;
      const courseId = selectedApplication.course_id;
      const token = localStorage.getItem("token");

      setUploading(true);

      const formData = new FormData();
      formData.append("certificate_file", selectedFile);

      fetch(
        `https://dev.guruatmananda.org/wp-json/custom/v1/upload-certificate/${userId}/${courseId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      )
        .then((response) => {
          setUploading(false);
          if (response.ok) {
            closeModal();
            // console.log(userId, token, courseId);
            const requestData = {
              user_id: userId,
              data: [
                {
                  meta_key: `course_completed_${courseId}`,
                  meta_value: "certificate_issued",
                },
              ],
            };

            fetch("https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/update", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(requestData),
            })
              .then((response) => {
                if (response.ok) {
                  closeModal();
                  const updated = updatedData.map((item) =>
                    item.user_id === userId ? { ...item, status: "certificateApproved" } : item
                  );
                  setUpdatedData(updated);
                } else {
                  console.error("API Request Error");
                }
              })
              .catch((error) => {
                console.error("Fetch Error:", error);
              });
          } else {
            console.error("API Request Error");
          }
        })
        .catch((error) => {
          setUploading(false);
          console.error("Fetch Error:", error);
        });
    }
  };

  const columns = [
    {
      Header: "User ID",
      accessor: "user_id",
    },
    {
      Header: "Name",
      accessor: "full_name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone No.",
      accessor: "phone_number",
    },
    {
      Header: "Program",
      accessor: "course_title",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      accessor: "course_id",
      Cell: ({ row }) => (
        <>
          {row.original.status === "certificateApproved" ? (
            <MDButton
              variant="contained"
              color="primary"
              onClick={() => handleUpload(row.original)}
            >
              {uploading ? "Uploading..." : "Upload Certificate"}
            </MDButton>
          ) : (
            <ButtonCell
              color="primary"
              label="Certificate"
              onClick={() => openModal(row.original)}
              rowData={row.original}
            />
          )}
        </>
      ),
    },
  ];

  const rows = updatedData.map((application) => ({
    course_id: application.course_id,
    full_name: application.full_name,
    email: application.email,
    phone_number: application.phone_number,
    course_title: application.course_title,
    status: application.status,
    user_id: application.user_id,
  }));

  const customStyles = {
    background: "#9425B9",
    color: "#FFFFFF",
  };
  const paginationOptions = {
    variant: "contained", // or "gradient" based on your design
    color: "primary", // or any other color
  };

  return (
    <>
      <AssignmentDataTable
        heading="Certificate Tracking"
        table={{ columns, rows }}
        canSearch
        sx={customStyles}
        entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
        pagination={paginationOptions}
      />
      <Modal
        open={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isUploadModalOpen}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Card
              style={{
                width: "400px",
                borderRadius: "8px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <h2 style={{ color: "#333", marginBottom: "10px" }}>Upload Certificate</h2>
                  <p style={{ color: "#777", fontSize: "14px" }}>
                    Please upload your certificate file.
                  </p>
                </div>

                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <label
                    htmlFor="certificate-upload"
                    style={{ display: "block", cursor: "pointer" }}
                  >
                    <input
                      type="file"
                      id="certificate-upload"
                      accept=".pdf, .jpg, .png"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <div
                      style={{
                        background: "#42A5F5",
                        color: "#FFF",
                        borderRadius: "4px",
                        padding: "10px 20px",
                        display: "inline-block",
                        cursor: "pointer",
                      }}
                    >
                      Choose File
                    </div>
                  </label>
                </div>

                {uploadedFile && (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <img
                      src={uploadedFile}
                      alt="Uploaded Certificate"
                      style={{ maxWidth: "100%", maxHeight: "150px", borderRadius: "4px" }}
                    />
                  </div>
                )}

                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <p style={{ color: "#777", fontSize: "12px" }}>
                    Accepted file formats: PDF, JPG, PNG
                  </p>
                </div>

                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <MDButton
                    variant="contained"
                    color="primary"
                    style={{ borderRadius: "4px" }}
                    onClick={handleFileUpload}
                  >
                    Upload
                  </MDButton>
                </div>
              </CardContent>
            </Card>
          </div>
        </Fade>
      </Modal>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Card>
              <CardContent>
                <h2>Certificate</h2>

                <div>
                  <p>
                    You wont be able to revert the status.
                    <br />
                    Are you sure?
                  </p>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}
                >
                  <MDButton variant="contained" color="success" onClick={handleApprove}>
                    Approve
                  </MDButton>
                  <MDButton variant="contained" color="error" onClick={handleReject}>
                    Reject
                  </MDButton>
                </div>
              </CardContent>
            </Card>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

AllCertificateTracking.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      course_id: PropTypes.number.isRequired,
      full_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone_number: PropTypes.string.isRequired,
      course_title: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      user_id: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default AllCertificateTracking;
