import React, { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  const passwordRequirements = [
    "One special character",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const handleUpdateClick = async () => {
    const currentPassword = document.getElementById("current-password").value;
    const newPassword = document.getElementById("new-password").value;
    const confirmNewPassword = document.getElementById("confirm-new-password").value;

    if (newPassword !== confirmNewPassword) {
      setPasswordError("New password and confirm password do not match.");
      return;
    }

    const token = localStorage.getItem("token"); // Assuming you have stored the token in localStorage

    const data = {
      current_password: currentPassword,
      new_password: newPassword,
      confirm_password: confirmNewPassword,
    };

    try {
      const response = await fetch(
        "https://dev.guruatmananda.org/wp-json/custom/v1/change-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/authentication/sign-in/illustration");
      } else {
        const errorData = await response.json();
        setPasswordError(errorData.message); // Update the error message based on the server response.
      }
    } catch (error) {
      // Handle network or other errors
      console.error(error);
    }
  };

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="Current Password"
              inputProps={{ type: "password", autoComplete: "current-password" }} // Add autoComplete attribute
              id="current-password"
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="New Password"
              inputProps={{ type: "password", autoComplete: "new-password" }} // Add autoComplete attribute
              id="new-password"
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="Confirm New Password"
              inputProps={{ type: "password", autoComplete: "new-password" }} // Add autoComplete attribute
              id="confirm-new-password"
            />
          </Grid>
        </Grid>
        <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">Password Requirements</MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {passwordRequirements.map((item, key) => (
              <MDBox
                key={`element-${key}`}
                component="li"
                color="text"
                fontSize="1.25rem"
                lineHeight={1}
              >
                <MDTypography
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  verticalAlign="middle"
                >
                  {item}
                </MDTypography>
              </MDBox>
            ))}
          </MDBox>
          <MDBox ml="auto">
            <MDButton
              color="dark"
              type="button" // Change type to "button" to prevent form submission
              size="small"
              sx={{
                background: "linear-gradient(310deg, #7928CA, #FF0080)",
                color: "white",
              }}
              onClick={handleUpdateClick} // Add click event handler
            >
              Update
            </MDButton>
          </MDBox>
        </MDBox>
        {passwordError && (
          <MDBox mb={1}>
            <MDTypography variant="body2" color="error">
              {passwordError}
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

export default ChangePassword;
