import React from "react";
import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import MDInput from "components/MDInput";

const datePickerStyles = {
  display: "-webkit-inline-box",
  display: "-webkit-inline-flex",
  display: "-ms-inline-flexbox",
  display: "inline-flex",
  WebkitFlexDirection: "column",
  msFlexDirection: "column",
  flexDirection: "column",
  position: "relative",
  minWidth: 0,
  padding: 0,
  margin: 0,
  border: 0,
  verticalAlign: "top",
  width: "100%",
  backgroundColor: "transparent",
  pointerEvents: "auto",
};

function MDDatePicker({ label, input, ...rest }) {
  return (
    <div style={datePickerStyles}>
      <Flatpickr
        {...rest}
        render={({ defaultValue }, ref) => (
          <MDInput
            {...input}
            defaultValue={defaultValue}
            inputRef={ref}
            label={label}
            InputLabelProps={{
              shrink: true,
              style: { color: "#7502A8", fontWeight: 700 },
            }}
            style={datePickerStyles} // Apply the styles here
          />
        )}
      />
    </div>
  );
}

// Setting default values for the props of MDDatePicker
MDDatePicker.defaultProps = {
  input: {},
  label: "",
};

// Typechecking props for the MDDatePicker
MDDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.string,
};

export default MDDatePicker;
