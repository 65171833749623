import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import MDInput from "components/MDInput";

function RechargeModal({
  open,
  onClose,
  onRecharge,
  onRechargeAmountChange,
  successMessage,
  errorMessage,
  disable,
}) {
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [amountError, setAmountError] = useState("");

  useEffect(() => {
    // Reset input fields and errors when the modal is opened
    if (open) {
      setAmount("");
      setNote("");
      setAmountError("");
    }
  }, [open]);

  const handleRecharge = () => {
    // Validate the amount input
    if (!isValidNumber(amount)) {
      setAmountError("Please enter a valid amount.");
    } else {
      setAmountError("");
      onRecharge(parseFloat(amount), note); // Convert the amount to a float before passing
    }
  };

  const isValidNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          margin: "auto",
          marginTop: 200,
          width: 400,
          backgroundColor: "#FFF",
          padding: 20,
          borderRadius: 8,
        }}
      >
        <MDInput
          label="Amount"
          type="number"
          value={amount}
          onChange={(event) => {
            setAmount(event.target.value);
            onRechargeAmountChange(parseFloat(event.target.value));
          }}
          name="amount"
          style={{ marginBottom: 20, width: "100%" }}
        />
        {amountError && <p style={{ color: "red", marginBottom: 10 }}>{amountError}</p>}
        <MDInput
          label="Note"
          type="text"
          value={note}
          onChange={(event) => setNote(event.target.value)}
          name="note"
          style={{ marginBottom: 20, width: "100%" }}
        />
        {successMessage && <p style={{ color: "green", marginBottom: 10 }}>{successMessage}</p>}
        {errorMessage && <p style={{ color: "red", marginBottom: 10 }}>{errorMessage}</p>}
        <div style={{ textAlign: "center" }}>
          <MDButton variant="contained" color="primary" disable={disable} onClick={handleRecharge}>
            Recharge
          </MDButton>
        </div>
      </div>
    </Modal>
  );
}

RechargeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRecharge: PropTypes.func.isRequired,
  onRechargeAmountChange: PropTypes.func.isRequired, // Add the missing prop validation
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  disable: PropTypes.bool,
};

export default RechargeModal;
