import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import StudentDetails from "./components/StudentDetails";
import MedicalHistory from "./components/MedicalHistory";
import AdditionalInformation from "./components/AdditionalInformation";
import FamilyDetails from "./components/FamilyDetails";
import { useNavigate } from "react-router-dom";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

function getSteps() {
  return ["Student Details", "Family Details", "Additional Information"];
}

function getStepContent(
  stepIndex,
  formData,
  setFormData,
  errors,
  setErrors,
  batchData,
  batchId,
  setBatchId
) {
  switch (stepIndex) {
    case 0:
      return (
        <StudentDetails
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
        />
      );
    case 1:
      return (
        <FamilyDetails
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
        />
      );
    case 2:
      //   return (
      //     <MedicalHistory
      //       formData={formData}
      //       setFormData={setFormData}
      //       errors={errors}
      //       setErrors={setErrors}
      //     />
      //   );
      // case 3:
      return (
        <AdditionalInformation
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          batchData={batchData}
          batchId={batchId}
          setBatchId={setBatchId}
        />
      );
    default:
      return null;
  }
}

function WizardDetails() {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const { courseIdGlobal } = useHookstate(globalStore);
  const { linkedGlobal } = useHookstate(globalStore);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [batchData, setBatchData] = useState([]);
  const [batchId, setBatchId] = useState("");
  const [selectedBatchData, setSelectedBatchData] = useState();
  const [subId, setSubId] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    educationalQualification: "",
    phoneNumber: "",
    // emailAddress: "",
    fatherName: "",
    fatherMailId: "",
    fatherMobileNumber: "",
    fatherOccupation: "",
    motherName: "",
    motherMailId: "",
    motherMobileNumber: "",
    motherOccupation: "",
    // medicalHistory: "",
    // studentDescription: "",
    // enrollmentReasons: "",
    // goals: "",
    // interests: "",
    // learningChallenges: "",
    course_completed: "start",
    batchId: "",
  });
  const [errors, setErrors] = useState({});

  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  fetch(
    `https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/fetch/${userDetails?.user_data?.sub}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      const subDetails = data?.meta_data;
      // console.log("subDetails", subDetails);
      const subscriptionIdProp = `subscription_id_${linkedGlobal.get({ noproxy: true })}`;
      // console.log("subscriptionIdProp", subscriptionIdProp);
      const subscriptionIdValue = subDetails[subscriptionIdProp];
      // console.log("subscriptionIdValue", subscriptionIdValue);

      setSubId(subscriptionIdValue);
    })
    .catch((error) => {
      console.error("Failed to fetch data:", error);
    });
  // console.log("subId", subId);

  const handleNext = async () => {
    const currentStepErrors = validateFormStep(activeStep);
    if (Object.keys(currentStepErrors).length === 0) {
      if (activeStep === steps.length - 1) {
        const formattedData = {
          user_id: userDetails?.user_data?.sub,
          data: [
            ...Object.entries(formData).map(([key, value]) => ({
              meta_key: key,
              meta_value: value,
            })),
            {
              meta_key: `course_completed_${courseIdGlobal.get({ noproxy: true })}`,
              meta_value: formData.course_completed,
            },
            {
              meta_key: `batchId_${courseIdGlobal.get({ noproxy: true })}`,
              meta_value: formData?.batchId,
            },
          ],
        };

        try {
          // First, update the user's meta_data
          const updateResponse = await fetch(
            "https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/update",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(formattedData),
            }
          );

          if (!updateResponse.ok) {
            throw new Error("Failed to update user meta_data.");
          }

          const updateData = await updateResponse.json();
          if (selectedBatchData === undefined) {
            navigate("/dashboards/active-courses");
          }

          // Now, make the POST request to the subscriptions API
          const postData = {
            start_date: selectedBatchData?.program_start_date,
            next_payment_date:
              selectedBatchData?.next_payment_date !== selectedBatchData?.end_date
                ? selectedBatchData?.next_payment_date
                : null,
            end_date: selectedBatchData?.end_payment_date,
            status: "active",
            setpaid: true,
          };
          const consumerKey = "ck_e07619a34325d4367c758704b04844c734ecc86d";
          const consumerSecret = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
          const authToken = btoa(`${consumerKey}:${consumerSecret}`);
          const subscriptionResponse = await fetch(
            `https://dev.guruatmananda.org/wp-json/wc/v3/subscriptions/${subId}`,
            {
              method: "POST",
              headers: {
                Authorization: `Basic ${authToken}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(postData),
            }
          );

          if (!subscriptionResponse.ok) {
            throw new Error("Failed to update subscription data.");
          }

          const subscriptionData = await subscriptionResponse.json();
          // console.log("Subscription updated successfully:", subscriptionData);
          navigate("/dashboards/active-courses");
        } catch (error) {
          console.error("Error occurred:", error);
        }
      } else {
        setActiveStep(activeStep + 1);
      }
      setErrors({});
    } else {
      setErrors(currentStepErrors);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setErrors({});
  };
  const fetchBatchData = async () => {
    try {
      const response = await fetch(
        `https://dev.guruatmananda.org/wp-json/custom/v1/batches/${courseIdGlobal.get({
          noproxy: true,
        })}/${userDetails?.user_data?.sub}`
      );
      if (response.ok) {
        const data = await response.json();
        // Assuming the API returns an array of batch options
        setBatchData(data);
      } else {
        console.error("Failed to fetch batch data:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };

  // Fetch batch data when the component mounts
  useEffect(() => {
    fetchBatchData();
  }, []);
  useEffect(() => {
    fetch(
      `https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/fetch/${userDetails?.user_data?.sub}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const fetchedData = data?.meta_data || {};
        setFormData((prevFormData) => ({
          ...prevFormData,
          firstName: fetchedData?.firstName || "",
          lastName: fetchedData?.lastName || "",
          dateOfBirth: fetchedData?.dateOfBirth || "",
          educationalQualification: fetchedData?.educationalQualification || "",
          phoneNumber: fetchedData?.phoneNumber || "",
          // emailAddress: fetchedData?.emailAddress || "",
          fatherName: fetchedData?.fatherName || "",
          fatherMailId: fetchedData?.fatherMailId || "",
          fatherMobileNumber: fetchedData?.fatherMobileNumber || "",
          fatherOccupation: fetchedData?.fatherOccupation || "",
          motherName: fetchedData?.motherName || "",
          motherMailId: fetchedData?.motherMailId || "",
          motherMobileNumber: fetchedData?.motherMobileNumber || "",
          motherOccupation: fetchedData?.motherOccupation || "",
          // medicalHistory: fetchedData?.medicalHistory || "",
          // studentDescription: fetchedData?.studentDescription || "",
          // enrollmentReasons: fetchedData?.enrollmentReasons || "",
          // goals: fetchedData?.goals || "",
          // interests: fetchedData?.interests || "",
          // learningChallenges: fetchedData?.learningChallenges || "",
        }));
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
      });
  }, [token]);

  useEffect(() => {
    // Find the matching batch data based on batchId whenever batchData or batchId changes
    const matchingBatch = batchData.find((batch) => batch.id === batchId);
    if (matchingBatch) {
      setSelectedBatchData(matchingBatch);
    } else {
      // console.log("No matching batch found for batchId:", batchId);
    }
  }, [batchData, batchId]);
  // console.log("fetch", selectedBatchData);

  const validateFormStep = (stepIndex) => {
    let stepErrors = {};

    if (stepIndex === 0) {
      if (!formData?.firstName) {
        stepErrors.firstName = "First Name is required";
      }
      if (!formData?.lastName) {
        stepErrors.lastName = "Last Name is required";
      }
      if (!formData?.dateOfBirth) {
        stepErrors.dateOfBirth = "Date of Birth is required";
      }
      if (!formData?.educationalQualification) {
        stepErrors.educationalQualification = "Educational Qualification is required";
      }
      if (!formData?.phoneNumber) {
        stepErrors.phoneNumber = "Phone Number is required";
      }
      // if (!formData?.emailAddress) {
      //   stepErrors.emailAddress = "Email Address is required";
      // }
    } else if (stepIndex === 1) {
      if (!formData?.fatherName) {
        stepErrors.fatherName = "Father's Name is required";
      }
      if (!formData?.fatherMailId) {
        stepErrors.fatherMailId = "Father's Email is required";
      }
      if (!formData?.fatherMobileNumber) {
        stepErrors.fatherMobileNumber = "Father's Mobile Number is required";
      }
      if (!formData?.fatherOccupation) {
        stepErrors.fatherOccupation = "Father's Occupation is required";
      }
      if (!formData?.motherName) {
        stepErrors.motherName = "Mother's Name is required";
      }
      if (!formData?.motherMailId) {
        stepErrors.motherMailId = "Mother's Email is required";
      }
      if (!formData?.motherMobileNumber) {
        stepErrors.motherMobileNumber = "Mother's Mobile Number is required";
      }
      if (!formData?.motherOccupation) {
        stepErrors.motherOccupation = "Mother's Occupation is required";
      }
      // } else if (stepIndex === 2) {
      //   if (!formData?.medicalHistory) {
      //     stepErrors.medicalHistory = "Medical History is required";
      //   }
      // } else if (stepIndex === 2) {
      //   if (!formData?.studentDescription) {
      //     stepErrors.studentDescription = "Student Description is required";
      //   }
      //   if (!formData?.enrollmentReasons) {
      //     stepErrors.enrollmentReasons = "Enrollment Reasons are required";
      //   }
      //   if (!formData?.goals) {
      //     stepErrors.goals = "Goals are required";
      //   }
      //   if (!formData?.interests) {
      //     stepErrors.interests = "Interests are required";
      //   }
      //   if (!formData?.learningChallenges) {
      //     stepErrors.learningChallenges = "Learning Challenges are required";
      //   }
    }

    return stepErrors;
  };

  return courseIdGlobal.get({ noproxy: true }) && linkedGlobal.get({ noproxy: true }) ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Onboarding Form
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  sx={{
                    background: "linear-gradient(310deg, #7928CA, #FF0080)",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(
                    activeStep,
                    formData,
                    setFormData,
                    errors,
                    setErrors,
                    batchData,
                    batchId,
                    setBatchId
                  )}
                </MDBox>
                <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                  {activeStep === 0 ? (
                    <MDBox />
                  ) : (
                    <MDButton variant="outlined" color="dark" onClick={handleBack}>
                      Back
                    </MDButton>
                  )}
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleNext}
                    disabled={Object.keys(errors).some((key) => errors[key] !== "")}
                    sx={{
                      background: "linear-gradient(310deg, #7928CA, #FF0080)",
                      color: "white",
                    }}
                  >
                    {isLastStep ? "Submit" : "Next"}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  ) : (
    navigate("/dashboards/active-courses")
  );
}

export default WizardDetails;
