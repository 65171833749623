import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import FormField from "layouts/applications/wizard/components/FormField";
import MDDatePicker from "components/MDDatePicker";

function StudentDetails({ formData, setFormData }) {
  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const currentDate = new Date().toISOString().split("T")[0];
  const validateDate = (selectedDate) => {
    if (selectedDate > currentDate) {
      return currentDate;
    }
    return selectedDate;
  };
  const handleDateChange = (date) => {
    handleInputChange("dateOfBirth", date.toISOString().split("T")[0]);
  };

  const validatePhoneNumber = (value) => {
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    handleInputChange("phoneNumber", numericValue); // Update the form data
  };

  return (
    <MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <MDBox mb={2}>
              <FormField
                type="text"
                label="First Name"
                value={formData.firstName || " "}
                onChange={(e) => handleInputChange("firstName", e.target.value)}
                // disabled={formData.firstName !== undefined && formData.firstName !== ""}
              />
            </MDBox>
            <MDBox mb={2}>
              <FormField
                type="text"
                label="Last Name"
                value={formData.lastName || ""}
                onChange={(e) => handleInputChange("lastName", e.target.value)}
                // disabled={formData.lastName !== undefined && formData.lastName !== ""}
              />
            </MDBox>
            <MDBox mb={2}>
              <FormField
                type="date"
                label="Date of Birth"
                value={formData.dateOfBirth || ""}
                onChange={(e) => handleInputChange("dateOfBirth", validateDate(e.target.value))}
                // disabled={formData.dateOfBirth !== undefined && formData.dateOfBirth !== ""}
                max={currentDate}
              />
            </MDBox>
            <MDBox mb={2}>
              <FormField
                type="text"
                label="Educational Qualification"
                value={formData.educationalQualification || ""}
                onChange={(e) => handleInputChange("educationalQualification", e.target.value)}
                // disabled={
                //   formData.educationalQualification !== undefined &&
                //   formData.educationalQualification !== ""
                // }
              />
            </MDBox>
            <MDBox mb={2}>
              <FormField
                type="tel"
                label="Whatsapp Number"
                value={formData.phoneNumber || ""}
                onChange={(e) => validatePhoneNumber(e.target.value)}
                // disabled={formData.phoneNumber !== undefined && formData.phoneNumber !== ""}
                inputProps={{
                  title: "Please enter a valid phone number",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

StudentDetails.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default StudentDetails;
