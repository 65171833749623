import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import FormField from "layouts/applications/wizard/components/FormField";
import { Autocomplete, Chip } from "@mui/material";
import MDInput from "components/MDInput";

function AdditionalInformation({
  formData,
  setFormData,
  errors,
  setErrors,
  batchData,
  batchId,
  setBatchId,
}) {
  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleBatchChange = (event, value) => {
    // Update 'formData' state with the selected batch ID
    const selectedBatchId = value?.id || ""; // If no batch is selected, set the ID to an empty string
    setBatchId(selectedBatchId);
    setFormData((prevData) => ({
      ...prevData,
      batchId: selectedBatchId, // Set the batch ID in formData
    }));
  };

  // selected batch data
  const batchDetails = batchData?.find((option) => option?.id === batchId);
  const startDate = batchDetails?.program_start_date;
  const formattedStartDate = startDate ? new Date(startDate).toLocaleDateString() : "";

  return (
    <MDBox>
      {/* Your other form fields here */}
      {batchData.length > 0 ? (
        <MDBox mt={2} p={2}>
          <Autocomplete
            options={batchData}
            getOptionLabel={(option) => option?.title}
            getOptionSelected={(option, value) => option.id === value?.id}
            value={batchData?.find((option) => option?.id === batchId) || null} // Find the selected batch by its ID
            onChange={handleBatchChange}
            renderInput={(params) => (
              <MDInput {...params} variant="standard" label="Select Batch" />
            )}
          />
        </MDBox>
      ) : (
        <p>Click on submit to complete onboarding.</p>
      )}
      {startDate && <Chip label={`Batch starts on ${formattedStartDate}`} color="primary" />}
    </MDBox>
  );
}

AdditionalInformation.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  batchData: PropTypes.array.isRequired,
  batchId: PropTypes.string.isRequired,
  setBatchId: PropTypes.func.isRequired,
};

export default AdditionalInformation;
