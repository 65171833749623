import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function ComplexProjectCardClone({
  gradient,
  buttonColor,
  icon,
  title,
  dateTime,
  description,
  value,
  buttonLabel,
  onButtonClick,
  webinarTitle,
  webinarSchedule,
}) {
  return (
    <Card style={{ height: "auto", overflow: "hidden" }}>
      <MDBox p={4}>
        <MDBox display="flex" alignItems="center">
          <MDBox
            display="grid"
            justifyContent="center"
            alignItems="center"
            style={{ background: gradient }}
            color="white"
            width="4rem"
            height="4rem"
            shadow="md"
            borderRadius="lg"
            variant="gradient"
          >
            <Icon fontSize="medium">{icon}</Icon>
          </MDBox>
          <MDBox ml={2} lineHeight={0}>
            <MDTypography variant="h4" textTransform="capitalize" fontWeight="medium">
              {title}
            </MDTypography>
            {webinarSchedule ? (
              <MDBox display="flex" flexDirection="column">
                <MDTypography variant="button" fontWeight="medium">
                  {webinarSchedule}
                </MDTypography>
              </MDBox>
            ) : null}
          </MDBox>
        </MDBox>
        <MDBox my={2} lineHeight={1}>
          <MDTypography variant="h4" textTransform="capitalize" fontWeight="medium">
            {webinarTitle}
          </MDTypography>
          <MDTypography variant="button" fontWeight="light" color="text">
            {description}
          </MDTypography>
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          {value !== undefined ? (
            <MDBox display="flex" flexDirection="column" lineHeight={0}>
              <MDTypography variant="h4" fontWeight="medium" color="success">
                {value}
              </MDTypography>
            </MDBox>
          ) : (
            <MDBox>
              <MDTypography variant="button" fontWeight="regular" color="text">
                <MDButton
                  variant="contained"
                  style={{ background: "linear-gradient(310deg,#7928CA,#FF0080)", color: "white" }}
                  onClick={onButtonClick}
                >
                  {buttonLabel}
                </MDButton>
              </MDTypography>
            </MDBox>
          )}
          {dateTime ? (
            <MDBox display="flex" flexDirection="column">
              <MDTypography variant="button" fontWeight="medium">
                {dateTime}
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="secondary">
                Today
              </MDTypography>
            </MDBox>
          ) : null}
        </MDBox>
      </MDBox>
    </Card>
  );
}

ComplexProjectCardClone.defaultProps = {
  gradient: "linear-gradient(310deg,#7928CA,#FF0080)",
  buttonColor: "primary",
  dateTime: "",
  webinarSchedule: "",
  value: undefined,
  buttonLabel: "Join Project",
  webinarTitle: "",
};

ComplexProjectCardClone.propTypes = {
  gradient: PropTypes.string,
  buttonColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  webinarSchedule: PropTypes.string,
  webinarTitle: PropTypes.string,
  description: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default ComplexProjectCardClone;
