import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

function SimpleBlogCard({ image, title, description, onClick, action }) {
  return (
    <Card>
      {image && ( // Conditionally render the image elements if image is provided
        <MDBox position="relative" borderRadius="lg" mt={-3} mx={2}>
          <MDBox
            component="img"
            src={image}
            alt={title}
            borderRadius="lg"
            shadow="md"
            width="100%"
            height="100%"
            position="relative"
            zIndex={1}
          />
          <MDBox
            borderRadius="lg"
            shadow="md"
            width="100%"
            height="100%"
            position="absolute"
            left={0}
            top="3%"
            sx={{
              backgroundImage: `url(${image})`,
              transform: "scale(0.94)",
              filter: "blur(12px)",
              backgroundSize: "cover",
            }}
          />
        </MDBox>
      )}
      <MDBox p={3}>
        <MDTypography display="inline" variant="h3" textTransform="capitalize" fontWeight="bold">
          {title}
        </MDTypography>
        <MDBox mt={2} mb={3}>
          <MDTypography variant="body2" component="p" color="text">
            {description}
          </MDTypography>
        </MDBox>
        {action && action?.type === "external" ? (
          <MuiLink href={action?.route} target="_blank" rel="noreferrer">
            <MDButton color={action?.color ? action?.color : "dark"} onClick={onClick}>
              {action?.label}
            </MDButton>
          </MuiLink>
        ) : (
          <Link to={action?.route}>
            <MDButton color={action?.color ? action?.color : "dark"} onClick={onClick}>
              {action?.label}
            </MDButton>
          </Link>
        )}
      </MDBox>
    </Card>
  );
}

SimpleBlogCard.propTypes = {
  image: PropTypes.string, // Make image prop optional
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default SimpleBlogCard;
