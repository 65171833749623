import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Dummy data for individual and group chats
const individualChats = [
  {
    id: 1,
    name: "Pukhraj",
    lastMessage: "Hey there!",
    timestamp: "2023-07-20T09:15:00Z",
    unreadCount: 2,
    profilePic:
      "https://sukshmadhrashta.guruatmananda.org/wp-content/uploads/2023/01/Guruji_new.jpeg",
    messages: [
      { text: "Hi!", timestamp: "2023-07-20T09:16:00Z", sender: "user" },
      { text: "Hello!", timestamp: "2023-07-20T09:18:00Z", sender: "recipient" },
    ],
  },
  {
    id: 2,
    name: "Arjun",
    lastMessage: "How are you?",
    timestamp: "2023-07-19T15:30:00Z",
    unreadCount: 0,
    profilePic:
      "https://sukshmadhrashta.guruatmananda.org/wp-content/uploads/2023/01/Guruji_new.jpeg",
    messages: [
      { text: "Hi!", timestamp: "2023-07-20T09:16:00Z", sender: "user" },
      { text: "Hello!", timestamp: "2023-07-20T09:18:00Z", sender: "recipient" },
    ],
  },
  {
    id: 3,
    name: "Teja",
    lastMessage: "See you later!",
    timestamp: "2023-07-19T10:45:00Z",
    unreadCount: 1,
    profilePic:
      "https://sukshmadhrashta.guruatmananda.org/wp-content/uploads/2023/01/Guruji_new.jpeg",
    messages: [
      { text: "Hi!", timestamp: "2023-07-20T09:16:00Z", sender: "user" },
      { text: "Hello!", timestamp: "2023-07-20T09:18:00Z", sender: "recipient" },
    ],
  },
  {
    id: 4,
    name: "Rahul",
    lastMessage: "Good morning!",
    timestamp: "2023-07-18T08:00:00Z",
    unreadCount: 0,
    profilePic:
      "https://sukshmadhrashta.guruatmananda.org/wp-content/uploads/2023/01/Guruji_new.jpeg",
    messages: [
      { text: "Hi!", timestamp: "2023-07-20T09:16:00Z", sender: "user" },
      { text: "Hello!", timestamp: "2023-07-20T09:18:00Z", sender: "recipient" },
    ],
  },
  {
    id: 5,
    name: "Rohit",
    lastMessage: "Thanks!",
    timestamp: "2023-07-17T14:20:00Z",
    unreadCount: 0,
    profilePic:
      "https://sukshmadhrashta.guruatmananda.org/wp-content/uploads/2023/01/Guruji_new.jpeg",
    messages: [
      { text: "Hi!", timestamp: "2023-07-20T09:16:00Z", sender: "user" },
      { text: "Hello!", timestamp: "2023-07-20T09:18:00Z", sender: "recipient" },
    ],
  },
];
const groupChats = [
  {
    id: 1,
    name: "Program 1",
    lastMessage: "Webinar at 3 PM",
    timestamp: "2023-07-20T12:00:00Z",
    unreadCount: 5,
    profilePic:
      "https://atmananda.guru/wp-content/uploads/2021/03/Sukshma-drashta-logo-1024x1024.jpg",
    messages: [
      {
        text: "Reminder: Webinar starts at 3 PM.",
        timestamp: "2023-07-20T11:55:00Z",
        sender: "user",
      },
      { text: "Got it!", timestamp: "2023-07-20T11:56:00Z", sender: "recipient" },
    ],
  },
  {
    id: 2,
    name: "Program 2",
    lastMessage: "Happy Birthday!",
    timestamp: "2023-07-19T20:30:00Z",
    unreadCount: 0,
    profilePic:
      "https://atmananda.guru/wp-content/uploads/2021/03/Sukshma-drashta-logo-1024x1024.jpg",
    messages: [
      {
        text: "Reminder: Webinar starts at 3 PM.",
        timestamp: "2023-07-20T11:55:00Z",
        sender: "user",
      },
      { text: "Got it!", timestamp: "2023-07-20T11:56:00Z", sender: "recipient" },
    ],
  },
  {
    id: 3,
    name: "Program 3",
    lastMessage: "Let's plan a trip.",
    timestamp: "2023-07-19T18:15:00Z",
    unreadCount: 1,
    profilePic:
      "https://atmananda.guru/wp-content/uploads/2021/03/Sukshma-drashta-logo-1024x1024.jpg",
    messages: [
      {
        text: "Reminder: Webinar starts at 3 PM.",
        timestamp: "2023-07-20T11:55:00Z",
        sender: "user",
      },
      { text: "Got it!", timestamp: "2023-07-20T11:56:00Z", sender: "recipient" },
    ],
  },
  {
    id: 4,
    name: "Program 4",
    lastMessage: "Lunchtime!",
    timestamp: "2023-07-18T13:00:00Z",
    unreadCount: 0,
    profilePic:
      "https://atmananda.guru/wp-content/uploads/2021/03/Sukshma-drashta-logo-1024x1024.jpg",
    messages: [
      {
        text: "Reminder: Webinar starts at 3 PM.",
        timestamp: "2023-07-20T11:55:00Z",
        sender: "user",
      },
      { text: "Got it!", timestamp: "2023-07-20T11:56:00Z", sender: "recipient" },
    ],
  },
  {
    id: 5,
    name: "Program 5",
    lastMessage: "GG, well played!",
    timestamp: "2023-07-17T22:45:00Z",
    unreadCount: 0,
    profilePic:
      "https://atmananda.guru/wp-content/uploads/2021/03/Sukshma-drashta-logo-1024x1024.jpg",
    messages: [
      {
        text: "Reminder: Webinar starts at 3 PM.",
        timestamp: "2023-07-20T11:55:00Z",
        sender: "user",
      },
      { text: "Got it!", timestamp: "2023-07-20T11:56:00Z", sender: "recipient" },
    ],
  },
];

const Sidebar = ({ onSelectChat, selectedChat }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchVisible, setSearchVisible] = useState(false);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  };

  const filteredIndividualChats = individualChats.filter((chat) =>
    chat.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredGroupChats = groupChats.filter((chat) =>
    chat.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <SidebarContainer>
      <SidebarHeader isSearchVisible={isSearchVisible}>
        {isSearchVisible ? (
          <BackArrowIcon className="material-icons" onClick={() => setSearchVisible(false)}>
            arrow_back
          </BackArrowIcon>
        ) : (
          <div className="search-icon" onClick={() => setSearchVisible(!isSearchVisible)}>
            <SearchInputIcon className="material-icons">search</SearchInputIcon>
          </div>
        )}
        {!isSearchVisible && (
          <div className="name-container">
            <ProfilePic
              src="https://media.licdn.com/dms/image/D4D03AQHpJAzQ7-G9Rg/profile-displayphoto-shrink_800_800/0/1683040873834?e=1695254400&v=beta&t=ol6o-AMyY8yiWmmFJ39cByKDiUyAFbGj3MUbSLSUYsE"
              alt="Your Profile"
            />
            <h3>Naveen Yaduvanshi</h3>
          </div>
        )}

        <SearchInputContainer>
          <SearchInputIcon className="material-icons">search</SearchInputIcon>
          <SearchInput
            type="text"
            placeholder="Search chats"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </SearchInputContainer>
      </SidebarHeader>
      <SidebarChats>
        {/* Group Chats */}
        <SidebarSection>
          <h4>Channels</h4>
          {filteredGroupChats.map((chat) => (
            <ChatItem
              key={chat.id}
              onClick={() => onSelectChat(chat)} // Set the selected chat when clicked
              active={selectedChat && selectedChat.id === chat.id} // Apply the 'active' style to the selected chat
            >
              <ProfilePic src={chat.profilePic} alt={chat.name} />
              <ChatDetails>
                <ChatName>{chat.name}</ChatName>
                <LastMessage>{chat.lastMessage}</LastMessage>
              </ChatDetails>
              <Timestamp count={chat.unreadCount}>{formatTimestamp(chat.timestamp)}</Timestamp>
              <UnreadCount count={chat.unreadCount}>{chat.unreadCount}</UnreadCount>
            </ChatItem>
          ))}
        </SidebarSection>

        {/* Individual Chats */}
        <SidebarSection>
          <h4>Direct Messages</h4>
          {filteredIndividualChats.map((chat) => (
            <ChatItem
              key={chat.id}
              onClick={() => onSelectChat(chat)} // Set the selected chat when clicked
              active={selectedChat && selectedChat.id === chat.id} // Apply the 'active' style to the selected chat
            >
              <ProfilePic src={chat.profilePic} alt={chat.name} />
              <ChatDetails>
                <ChatName>{chat.name}</ChatName>
                <LastMessage>{chat.lastMessage}</LastMessage>
              </ChatDetails>
              <Timestamp count={chat.unreadCount}>{formatTimestamp(chat.timestamp)}</Timestamp>
              <UnreadCount count={chat.unreadCount}>{chat.unreadCount}</UnreadCount>
            </ChatItem>
          ))}
        </SidebarSection>
      </SidebarChats>
    </SidebarContainer>
  );
};

// Styled components for sidebar styling
const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 4px;
`;
const BackArrowIcon = styled.i`
  font-size: 24px;
  color: #344767;
  cursor: pointer;
  margin-right: 10px;
`;
const SearchInputIcon = styled.i`
  font-size: 20px;
  color: #919191;
`;

const SearchInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: none;
  outline: none;
  color: #344767;
  font-size: 16px;
  margin-left: 8px;

  &::placeholder {
    color: #919191;
  }
`;
const SidebarContainer = styled.div`
  flex: 0.3;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  overflow-y: auto;
  scrollbar-width: thin; /* Add thin scrollbar for Firefox */
  scrollbar-color: #767676 #f8f8f8; /* Scrollbar color for Firefox */
  ::-webkit-scrollbar {
    /* Width of the scrollbar for Chrome, Safari, and Opera */
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    /* Background of the scrollbar */
    background: #f8f8f8;
  }
  ::-webkit-scrollbar-thumb {
    /* Custom scrollbar thumb */
    background: #767676;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    /* Custom scrollbar thumb on hover */
    background: #555;
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  color: #344767;

  .name-container {
    display: flex;
    align-items: center;
  }

  .name-container h3 {
    margin-left: 10px;
  }

  .search-bar {
    display: ${({ isSearchVisible }) => (isSearchVisible ? "none" : "block")};
  }

  ${SearchInputContainer} {
    display: ${({ isSearchVisible }) => (isSearchVisible ? "flex" : "none")};
  }

  .search-icon {
    cursor: pointer;
    margin-right: 10px;
  }
`;

const ProfilePic = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const SidebarChats = styled.div`
  flex: 1;
  padding: 10px;
`;

const SidebarSection = styled.div`
  margin-bottom: 20px;

  h4 {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
`;

const ChatItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: #f0f0f0;
  }

  ${({ active }) =>
    active &&
    `
    background-color: #ebebeb;
  `}
`;

const ChatDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  margin-left: 10px;
`;

const ChatName = styled.p`
  font-weight: bold;
`;

const LastMessage = styled.p`
  color: #777;
  font-size: 14px;
`;

const UnreadCount = styled.div`
  display: ${({ count }) => (count > 0 ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #e91e63;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  margin-left: 5px;
`;

const Timestamp = styled.p`
  color: ${({ count }) => (count > 0 ? "#e91e63" : "#777")};
  font-size: 14px;
`;

Sidebar.propTypes = {
  onSelectChat: PropTypes.func.isRequired,
  selectedChat: PropTypes.object,
};

export default Sidebar;
