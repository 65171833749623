import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

function ProductInfo() {
  const [topicData, setTopicData] = useState(null);
  // const courseId = localStorage.getItem("courseId");
  const { courseIdGlobal } = useHookstate(globalStore);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const url = `https://dev.guruatmananda.org/wp-json/my-plugin/v1/courses_lesson/${courseIdGlobal.courseIdGlobal.get(
    { noproxy: true }
  )}/subscription`;
  const token = localStorage.getItem("token");
  useEffect(() => {
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTopicData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const openPopup = (url) => {
    const width = 600;
    const height = 400;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const popupWindow = window.open(
      url,
      "Popup",
      `width=${width}, height=${height}, top=${top}, left=${left}`
    );

    if (window.focus) {
      popupWindow.focus();
    }
  };

  return (
    <MDBox>
      <MDBox mb={1}>
        {topicData?.extra_fields?.length > 0 && (
          <MDTypography variant="h3" fontWeight="bold">
            Important Links
          </MDTypography>
        )}
      </MDBox>
      {topicData?.extra_fields?.length > 0 &&
        topicData.extra_fields.map((link, index) => (
          <MDButton
            key={index}
            variant="text"
            color="info"
            onClick={() => {
              if (link.type === "Popup") {
                openPopup(link.button_link);
                // openPopup("https://www.youtube.com/watch?v=1OjgwDQPNC4&list=RDQvzvAsyrWUM&index=2");
              } else {
                window.open(link.button_link, "_blank");
              }
            }}
          >
            {link?.button_name}
          </MDButton>
        ))}

      <MDBox mt={3} mb={1} ml={0.5}>
        <MDTypography variant="h4" fontWeight="bold">
          Share your experience
        </MDTypography>
        <MDInput label="Type here..." multiline rows={5} fullWidth />{" "}
      </MDBox>

      <MDBox mt={3}>
        <Grid item xs={12} lg={5} container>
          {/* Center the button on desktop screens */}
          <MDBox display="flex" justifyContent="center" width={isDesktop ? "100%" : "auto"}>
            <MDButton variant="gradient" color="info" width={isDesktop ? "500px" : "100%"}>
              Share
            </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default ProductInfo;
