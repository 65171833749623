import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ModifiedProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard copy";
import AllPaymentTracking from "layouts/ecommerce/orders/order-list copy 4";

/* eslint-disable react/prop-types */

function TrackPayments() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [activeStatus, setActiveStatus] = useState("All");
  const [subscriptions, setSubscriptions] = useState([]); // Store fetched subscriptions data
  const [overdueSubscription, setOverdueSubscription] = useState([]); // Store fetched subscriptions data
  const [dropoutSubscription, setDropoutSubscription] = useState([]); // Store fetched subscriptions data

  const handleStatusChange = (status) => {
    setActiveStatus(status);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const key = "ck_e07619a34325d4367c758704b04844c734ecc86d";
    const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";

    // Function to get the current date
    // const basicAuth = btoa(`${username}:${password}`);

    if (token) {
      fetch("https://dev.guruatmananda.org/wp-json/custom/v1/all_subscriptions", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Include key and password in the Authorization header
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setSubscriptions(data);
        })
        .catch((error) => {
          console.error("Error fetching subscriptions:", error);
        });
    }
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const key = "ck_e07619a34325d4367c758704b04844c734ecc86d";
    const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";

    // Function to get the current date
    // const basicAuth = btoa(`${username}:${password}`);

    if (token) {
      fetch(
        "https://dev.guruatmananda.org/wp-json/custom/v1/all_subscriptions?subscription_status=on-hold",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include key and password in the Authorization header
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setOverdueSubscription(data);
        })
        .catch((error) => {
          console.error("Error fetching subscriptions:", error);
        });
    }
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const key = "ck_e07619a34325d4367c758704b04844c734ecc86d";
    const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";

    // Function to get the current date
    // const basicAuth = btoa(`${username}:${password}`);

    if (token) {
      fetch(
        "https://dev.guruatmananda.org/wp-json/custom/v1/all_subscriptions?subscription_status=pending-cancel",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include key and password in the Authorization header
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setDropoutSubscription(data);
        })
        .catch((error) => {
          console.error("Error fetching subscriptions:", error);
        });
    }
  }, []);
  const today = new Date(); // Get the current date

  const allSubscriptions = subscriptions;
  const activeSubscriptions = subscriptions.filter((item) => item.subscription_status === "active");
  // const dropoutSubscriptions = subscriptions.filter(
  //   (item) => item.subscription_status == "pending-cancel"
  // );
  const overdueSubscriptions = activeSubscriptions.filter(
    (item) => item.subscription_status == "on-hold"
  );
  const dropoutCount = dropoutSubscription.length;
  const totalAmount = overdueSubscription.reduce((accumulator, subscription) => {
    // Remove commas if present, parse the amount as a float, and add it to the accumulator
    return accumulator + parseFloat(subscription.amount.replace(/,/g, ""));
  }, 0);
  const overdue = `₹ ${totalAmount}`;
  const overdueCount = overdueSubscription.length;

  // Filter subscriptions due within upcoming 5 days
  const upcoming5DaysSubscriptions = allSubscriptions.filter((subscription) => {
    const nextPaymentDate = new Date(subscription.next_payment_date);
    const fiveDaysFromNow = new Date(today);
    fiveDaysFromNow.setDate(today.getDate() + 5);

    return nextPaymentDate >= today && nextPaymentDate <= fiveDaysFromNow;
  });

  // Filter subscriptions due in the past 5 days
  const past5DaysSubscriptions = allSubscriptions.filter((subscription) => {
    const nextPaymentDate = new Date(subscription.next_payment_date);
    const fiveDaysAgo = new Date(today);
    fiveDaysAgo.setDate(today.getDate() - 5);

    return nextPaymentDate >= fiveDaysAgo && nextPaymentDate < today;
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox my={3}>
        <div
          style={{
            overflowX: "auto",
            display: "flex",
            flexDirection: "row", // Change to row on smaller screens
            alignItems: "flex-start",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={4}>
              <ModifiedProjectCard
                icon="sync"
                number={overdueCount}
                description={
                  <span
                    style={{ fontSize: "20px", fontWeight: 600, color: "black", lineHeight: "1.5" }}
                  >
                    Payments Overdue
                  </span>
                }
                iconBackgroundColor="linear-gradient(310deg, #7928CA, #FF0080)"
                iconColor="white"
                numberColor="#851aa1"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <ModifiedProjectCard
                icon="sync"
                number={overdue}
                description={
                  <span
                    style={{ fontSize: "20px", fontWeight: 600, color: "black", lineHeight: "1.5" }}
                  >
                    Total Overdue Amount
                  </span>
                }
                iconBackgroundColor="linear-gradient(310deg, #7928CA, #FF0080)"
                iconColor="white"
                numberColor="#0d7419"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <ModifiedProjectCard
                icon="sync"
                number={dropoutCount}
                description={
                  <span
                    style={{ fontSize: "20px", fontWeight: 600, color: "black", lineHeight: "1.5" }}
                  >
                    Dropouts
                  </span>
                }
                iconBackgroundColor="linear-gradient(310deg, #7928CA, #FF0080)"
                iconColor="white"
                numberColor="#740d0d"
              />
            </Grid>
          </Grid>
        </div>
      </MDBox>
      <MDBox my={3}>
        <div style={{ overflowX: "auto", display: "flex" }}>
          {["All", "Active", "Overdue in 5 Days", "Overdue", "Overdue for 5 Days", "Dropout"].map(
            (status, index) => (
              <MDButton
                key={index}
                variant="contained"
                style={{
                  background:
                    activeStatus === status ? "linear-gradient(310deg, #7928CA, #FF0080)" : "",
                  color: activeStatus === status ? "white" : "#344767",
                  marginRight: "10px",
                }}
                onClick={() => handleStatusChange(status)}
                px={2}
              >
                {status}
              </MDButton>
            )
          )}
        </div>
      </MDBox>
      {activeStatus === "All" && <AllPaymentTracking data={allSubscriptions} />}
      {activeStatus === "Overdue in 5 Days" && (
        <AllPaymentTracking data={upcoming5DaysSubscriptions} />
      )}
      {activeStatus === "Overdue" && <AllPaymentTracking data={overdueSubscription} />}
      {activeStatus === "Overdue for 5 Days" && (
        <AllPaymentTracking data={past5DaysSubscriptions} />
      )}
      {activeStatus === "Dropout" && <AllPaymentTracking data={dropoutSubscription} />}
      {activeStatus === "Active" && <AllPaymentTracking data={activeSubscriptions} />}
    </DashboardLayout>
  );
}

export default TrackPayments;
