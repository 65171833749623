import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
/* eslint-disable react/prop-types */
import burceMars from "assets/images/bruce-mars.jpg";

const API_URL = "https://dev.guruatmananda.org//wp-json/custom/v1/timezones";

function Header({ userData, metaData }) {
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [userDataHeader, setUserDataHeader] = useState(userData);
  const [updated, setUpdated] = useState();
  const hasProfilePicture =
    userDataHeader?.profile_picture === undefined || userDataHeader?.profile_picture === "";

  const firstName = metaData?.firstName || "";
  const lastName = metaData?.lastName || "";
  const [uploadError, setUploadError] = useState(null);

  // Find the first non-space character in either firstName or lastName
  const initials = (firstName.trim()[0] + lastName.trim()[0] || "").toUpperCase();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handleTimezoneSelect = (timezone) => {
    setSelectedTimezone(timezone);
    console.log("Selected timezone:", timezone); // Log the selected timezone

    // Update the user's timezone with the API
    updateUserTimezone(timezone);
  };

  const updateUserTimezone = (timezone) => {
    const token = localStorage.getItem("token");
    // Prepare the data for the POST request
    const requestData = {
      user_id: userDataHeader?.user_data?.sub,
      data: [
        {
          meta_key: "user_timezone",
          meta_value: timezone,
        },
      ],
    };

    // Send a POST request to update the user's timezone
    fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("User timezone updated:", data);
        // You can add further logic here if needed
      })
      .catch((error) => {
        console.error("Error updating user timezone:", error);
      });
  };

  useEffect(() => {
    // Fetch timezone options from the API
    fetch("https://dev.guruatmananda.org/wp-json/custom/v1/timezones")
      .then((response) => response.json())
      .then((data) => {
        setTimezoneOptions(data);
      })
      .catch((error) => {
        console.error("Error fetching timezone options:", error);
      });
  }, []);

  const handleConfirm = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      // Handle the case where the token is not available
      return;
    }

    const formData = new FormData();
    formData.append("avatar", selectedFile);

    fetch("https://dev.guruatmananda.org/wp-json/custom/v1/update-profile/", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          closeModal();

          // Update the profile picture in the local state
          return response.json();
        } else {
          // Handle errors
          return response.json().then((data) => {
            throw new Error(data.message || "Failed to upload profile picture");
          });
        }
      })
      .catch((error) => {
        // Handle errors
        setUploadError(error.message);
      });
  };

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {/* Clickable area for both image and initials */}
            <div style={circleStyle} onClick={openModal}>
              {hasProfilePicture ? (
                <span style={initialsStyle}>{initials}</span>
              ) : (
                <MDAvatar
                  src={
                    selectedFile
                      ? URL.createObjectURL(selectedFile)
                      : userDataHeader?.profile_picture
                  }
                  alt="profile-image"
                  size="xl"
                  shadow="sm"
                  onClick={openModal}
                />
              )}
            </div>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {metaData?.firstName.toUpperCase()} {metaData?.lastName.toUpperCase()}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="medium">
                Referral Code - {userDataHeader?.referral_code}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <MDTypography variant="caption" fontWeight="regular">
                Select Timezone
              </MDTypography>
              <MDBox ml={1}>
                <select
                  value={selectedTimezone}
                  onChange={(e) => handleTimezoneSelect(e.target.value)}
                  className="custom-select"
                  style={{
                    padding: "10px",
                    border: "2px solid #ededed",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    color: "#333",
                  }}
                >
                  <option value="">Select Timezone</option>
                  {timezoneOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      {/* Upload Picture Modal */}
      <Dialog open={isModalOpen} onClose={closeModal} fullWidth maxWidth="sm">
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="200px"
          >
            <IconButton
              edge="end"
              color="inherit"
              onClick={closeModal}
              aria-label="close"
              sx={{
                position: "absolute",
                top: 8,
                right: 15,
              }}
            >
              <CloseIcon />
            </IconButton>
            {selectedFile ? (
              <div>
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt="selected-profile"
                  style={{
                    display: "block",
                    margin: "auto",
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginBottom: "16px",
                  }}
                />
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setSelectedFile(null);
                    }}
                    sx={{
                      marginTop: "16px",
                      marginRight: "8px",
                      background: "linear-gradient(310deg,#7928CA,#FF0080)",
                      color: "white",
                    }}
                  >
                    Choose File
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirm}
                    style={{
                      marginTop: "16px",
                      marginRight: "8px",
                      background: "linear-gradient(310deg,#7928CA,#FF0080)",
                      color: "white",
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <Box
                  border="2px dashed #ccc"
                  borderRadius="50%"
                  width="200px"
                  height="200px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  cursor="pointer"
                  onClick={() => document.getElementById("file-input").click()}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ marginTop: "12px", textAlign: "center" }}
                  >
                    Click to upload a profile picture
                  </Typography>
                </Box>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="file-input"
                />
              </div>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Card>
  );
}

const circleStyle = {
  width: "64px",
  height: "64px",
  borderRadius: "50%",
  backgroundColor: "#f0f0f0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const initialsStyle = {
  fontSize: "24px",
  fontWeight: "bold",
};

export default Header;
