import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import ComplexProjectCardClone from "examples/Cards/ProjectCards/ComplexProjectCard copy";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import RechargeModal from "./components/RechargeModal/RechargeModal";
import Transactions from "layouts/pages/account/wallet/components/Transactions";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { CircularProgress, Dialog } from "@mui/material";

// Define API endpoint URLs as constants
const WALLET_BALANCE_API = "https://dev.guruatmananda.org/wp-json/wc/v3/wallet/balance";
const WALLET_TRANSACTION_API = "https://dev.guruatmananda.org/wp-json/wc/v3/wallet";
const META_API = "https://dev.guruatmananda.org//wp-json/custom-user-meta/v1/fetch/";

function Wallet() {
  const [amount, setAmount] = useState(0);
  const [razorpayAmount, setRazorpayAmount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [balance, setBalance] = useState(null);
  const [amountError, setAmountError] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [note, setNote] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [rechargeModalOpen, setRechargeModalOpen] = useState(false);
  const [rechargeSuccessMessage, setRechargeSuccessMessage] = useState("");
  const [rechargeErrorMessage, setRechargeErrorMessage] = useState("");
  const userId = JSON.parse(localStorage.getItem("user"));
  const [apiResponse, setApiResponse] = useState(null);
  const key = "ck_e07619a34325d4367c758704b04844c734ecc86d";
  const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
  const [metaData, setMetaData] = useState();
  const token = localStorage.getItem("token");
  const [loadingRazorpay, setLoadingRazorpay] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [walletTrans, setWalletTrans] = useState(false);
  const [completingOrder, setCompletingOrder] = useState(false);
  const [transactionCancelled, setTransactionCancelled] = useState(false);
  const [rechargeButton, setRechargeButton] = useState(false);
  const [transferTracker, setTransferTracker] = useState(false);
  const [userIp, setUserIp] = useState("");
  const [orderId, setOrderId] = useState();
  // Function to get the current date
  function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${day}-${month}-${year}`;
    const formattedDateWithTime = `${formattedDate} ${currentDate.toLocaleTimeString()}`;
    return {
      formattedDate,
      formattedDateWithTime,
      year,
      month,
      day,
    };
  }
  // metadata useEffect

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await fetch(`${META_API}${userId?.user_data?.sub}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setMetaData(data.meta_data);
        } else {
          throw new Error("Failed to fetch metadata");
        }
      } catch (error) {
        console.error("Error fetching metadata:", error);
      }
    };

    fetchMetadata();
  }, [userId?.user_data?.sub, token]);
  const currentDate = getCurrentDate();

  // useEffect for fetching wallet balance
  useEffect(() => {
    const userEmail = JSON.parse(localStorage.getItem("user"));

    // API call to fetch wallet balance
    const fetchWalletBalance = async () => {
      try {
        const response = await fetch(`${WALLET_BALANCE_API}?email=${userEmail?.user_email}`, {
          headers: {
            Authorization: `Basic ${btoa(`${key}:${password}`)}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const balance = data?.balance;
          setBalance(balance);
        } else {
          console.error("Error fetching wallet balance:", response.statusText);
          setBalance("Error");
        }
      } catch (error) {
        console.error("Error fetching wallet balance:", error);
        setBalance("Error");
      }
    };

    fetchWalletBalance();
  }, [amount, rechargeModalOpen, openModal]);

  useEffect(() => {
    if (amount > 0) {
      setAmountError("");
    }
  }, [amount, token, metaData]);

  // modal handling
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  // userIp
  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setUserIp(data.ip))
      .catch((error) => setUserIp("IP Not Found"));
  }, []);
  // Recharge Modal
  const handleRechargeModal = () => {
    setRechargeModalOpen(true);
  };

  // reset recharge form
  const resetForm = () => {
    setAmount(0);
    setRazorpayAmount(amount);
    setReceiverEmail("");
    setNote("");
    setSuccessMessage("");
    setErrorMessage("");
    setRechargeSuccessMessage("");
    setRechargeErrorMessage("");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    resetForm();
  };

  const fetchData = async () => {
    const url = "https://dev.guruatmananda.org//wp-json/myplugin/v1/create_razorpay_order/";
    const username = "rzp_live_BEu8ZlChbYTdWW";
    const password = "OxFhXEVWMWOBwfDdpCcy67wD";
    const razorPayAuth = btoa(`${username}:${password}`);

    const requestBody = {
      amount: amount * 100,
      currency: "INR",
      receipt: "receipt",
      notes: {
        state: metaData?.billing_state || "N/A",
        city: metaData?.billing_city || "N/A",
        pincode: metaData?.billing_pincode || "N/A",
        donors_email: metaData?.billing_email || "N/A",
        donors_name: metaData?.billing_firstName || "N/A",
        donors_address: metaData?.billing_address || "N/A",
        mentorship_program: "Wallet Recharge",
        donors_governmentId: metaData?.billing_governmentId || "N/A",
      },
    };

    const requestBodyJSON = JSON.stringify(requestBody);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: requestBodyJSON,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      return responseData;
      // setApiResponse(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  function loadScript(src) {
    setLoadingRazorpay(true);

    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        setLoadingRazorpay(false);
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function createOrder() {
    try {
      setRechargeButton(true);
      const fetchedData = await fetchData();
      if (fetchedData) {
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
        if (!res) {
          alert("Razorpay SDK failed to load. Are you online?");
          setRechargeButton(false);
          return;
        }
        const order = {
          customer_id: userId?.user_data?.sub,
          billing: {
            first_name: metaData?.billing_firstName || "N/A",
            last_name: metaData?.billing_lastName || "N/A",
            address_1: metaData?.billing_address || "N/A",
            city: metaData?.billing_city || "N/A",
            state: metaData?.billing_state || "N/A",
            postcode: metaData?.billing_pincode || "N/A",
            country: metaData?.billing_country || "N/A",
            email: userId?.user_data.user_email || "N/A",
            phone: userId?.user_data.phone || "N/A",
            donors_governmentId: metaData?.billing_governmentId || "N/A",
          },
          line_items: [
            {
              product_id: "64",
              quantity: 1,
              subtotal: amount.toFixed(2),
              total: amount.toFixed(2),
            },
          ],
        };
        const apiUrl = "https://dev.guruatmananda.org/wp-json/wc/v3/orders";
        const consumerKey = "ck_e07619a34325d4367c758704b04844c734ecc86d";
        const consumerSecret = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";

        const authToken = btoa(`${consumerKey}:${consumerSecret}`);
        try {
          setLoadingOrder(true);
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${authToken}`,
            },
            body: JSON.stringify(order),
          });

          if (response.ok) {
            const responseData = await response.json();
            setLoadingOrder(false);

            fetch("https://dev.guruatmananda.org/wp-json/wc/v3/payment_gateways/razorpay", {
              headers: {
                Authorization: `Basic ${authToken}`,
              },
            })
              .then((response) => {
                if (response.ok) {
                  return response.json();
                } else {
                  setRechargeButton(false);
                  throw new Error("Failed to fetch API: " + response.status);
                }
              })
              .then((data) => {
                setOrderId(responseData?.id);
                console.log("orderId", orderId);
                const opt = {
                  id: responseData?.id,
                  order_id: fetchedData?.id,
                  amount: amount * 100,
                  handler: async (response) => {
                    if (response.razorpay_payment_id && responseData?.id) {
                      var paymentId = response.razorpay_payment_id;
                      // simulateWebhook(paymentId);
                      const data = {
                        orderCreationId: responseData?.id,
                        razorpayPaymentId: response?.razorpay_payment_id,
                        razorpayOrderId: response?.razorpay_order_id,
                        razorpaySignature: response?.razorpay_signature,
                        payment_method: "razorpay",
                        payment_method_title: "Razorpay",
                        set_paid: true,
                        status: "completed",
                        transaction_id: response.razorpay_payment_id,
                      };

                      const username = "ck_e07619a34325d4367c758704b04844c734ecc86d";
                      const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
                      const basicAuth = btoa(`${username}:${password}`); // Base64 encoding

                      try {
                        const apiUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/orders/${responseData?.id}`;
                        const response = await fetch(apiUrl, {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Basic ${basicAuth}`,
                          },
                          body: JSON.stringify(data),
                        });

                        if (response.ok) {
                          setLoadingOrder(false);
                          setCompletingOrder(false);
                          setWalletTrans(true);
                          // Add your wallet recharge API call here
                          try {
                            const userEmail = JSON.parse(localStorage.getItem("user"));
                            const rechargeAmount = amount;
                            const rechargeNote = `Wallet Recharge ${note}`;

                            const creditPayload = {
                              email: userEmail?.user_email,
                              type: "credit",
                              amount: rechargeAmount,
                              note: rechargeNote,
                            };

                            const creditResponse = await fetch(
                              `${WALLET_TRANSACTION_API}?email=${creditPayload.email}&type=${creditPayload.type}&amount=${creditPayload.amount}`,
                              {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Basic ${btoa(`${key}:${password}`)}`,
                                },
                                body: JSON.stringify(creditPayload),
                              }
                            );
                            if (creditResponse.ok) {
                              const success = await creditResponse.json();
                              if (Boolean(success)) {
                                setWalletTrans(false);
                                setRechargeButton(false);
                              }
                            }
                            if (!creditResponse.ok) {
                              const errorMessage = await creditResponse.json();
                              // if credit fails
                              setRechargeButton(false);
                              throw new Error(
                                `Credit API request for recharge failed: ${errorMessage}`
                              );
                            }
                          } catch (error) {
                            console.error("Error recharging wallet:", error);
                            setRechargeButton(false);
                            setRechargeErrorMessage(error.message);
                          }
                          // End of wallet recharge API call
                          const data = {
                            orderCreationId: responseData.id,
                            razorpayPaymentId: response.razorpay_payment_id,
                            razorpayOrderId: response.razorpay_order_id,
                            razorpaySignature: response.razorpay_signature,
                            payment_method: "razorpay",
                            payment_method_title: "Razorpay",
                            set_paid: true,
                            status: "completed",
                            transaction_id: response.razorpay_payment_id,
                          };

                          const apiUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/orders/${responseData.id}`;
                          const response = await fetch(apiUrl, {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Basic ${basicAuth}`,
                            },
                            body: JSON.stringify(data),
                          });
                          if (response.ok) {
                          }
                          const orderId2 = responseData.id;
                          setOpenModal(false);
                          setRechargeSuccessMessage("Recharge successful!");
                          setRechargeButton(false);
                          navigate("/sign-up");
                        } else {
                          const errorData = await response.json();
                          setRechargeButton(false);

                          console.error("Error while posting data to /payment/sign-up:", errorData);
                        }
                      } catch (error) {
                        console.error("Error while posting data to /payment/sign-up:", error);
                      }
                    }
                  },
                  modal: {
                    ondismiss: function () {
                      setRechargeButton(false);
                      window.location.reload();
                      resetForm();
                    },
                  },
                  prefill: {
                    name: metaData?.billing_name,
                    email: metaData?.billing_email,
                    contact: metaData?.billing_phoneNumber,
                  },
                  currency: "INR",
                  name: "Sri Siddha Sanmarga",
                  key: data?.settings?.key_id?.value,
                };
                const razorpay = new window.Razorpay(opt);

                razorpay.on("payment.failed", (response) => {
                  // console.log("Payment Failed here", response);
                  setTransactionCancelled(true);
                  setRechargeButton(false);
                });

                razorpay.on("payment.success", async (res) => {
                  // console.log("Payment success", res);
                  setRechargeButton(false);

                  try {
                    navigate("/sign-up");
                    setRechargeButton(false);
                  } catch (error) {
                    setRechargeButton(false);

                    console.error("Error handling payment success:", error);
                    navigate("/sign-up");
                  }
                });
                razorpay.open();
              })
              .catch((error) => {
                setRechargeButton(false);

                console.error("API error:", error);
              });
          } else {
            // if order creation fails
            setRechargeButton(false);
            console.error("Failed to create order:", response.status, response.statusText);
          }
        } catch (error) {
          setRechargeButton(false);

          console.error("Error creating order:", error);
        }
      } else {
        console.error("No apiResponse received from fetchData");
      }
    } catch (error) {
      console.error("Error in createOrder:", error);
    }
  }
  //it's amount transfer function named as add amount
  const handleAddAmount = async () => {
    if (amount <= 0) {
      setAmountError("Please enter an amount above 0.");
      return;
    }

    if (!receiverEmail || !receiverEmail.trim()) {
      setErrorMessage("Receiver's Email is required.");
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(receiverEmail)) {
      setErrorMessage("Invalid email format.");
      return;
    }
    if (balance === null || Math.floor(balance) < amount) {
      // console.log("sdfghj", parseFloat(amount?.toFixed(2)));
      setErrorMessage("Insufficient balance to make this transfer.");
      return;
    }
    const userEmail = JSON.parse(localStorage.getItem("user"));
    // Check if the recipient's email is the same as the user's email
    if (receiverEmail === userEmail?.user_email) {
      setErrorMessage("You cannot transfer funds to your own account.");
      return;
    }
    setAmountError("");
    setErrorMessage("");

    try {
      setTransferTracker(true);
      const userEmail = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");
      const transferNote = `Transferred to ${receiverEmail} with note : ${note}`;

      // Debit from the sender's wallet
      const debitPayload = {
        email: userEmail?.user_email,
        type: "debit",
        amount: amount,
        note: transferNote, // Updated transfer note
      };

      const debitResponse = await fetch(
        `${WALLET_TRANSACTION_API}?email=${debitPayload.email}&type=${debitPayload.type}&amount=${debitPayload.amount}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${btoa(`${key}:${password}`)}`, // Include key and password in the Authorization header
          },
          body: JSON.stringify(debitPayload),
        }
      );

      if (!debitResponse.ok) {
        throw new Error("Debit API request failed");
      }

      const creditPayload = {
        email: receiverEmail,
        type: "credit",
        amount: amount,
        note: note,
      };

      // Credit to the receiver's wallet
      const creditResponse = await fetch(
        `${WALLET_TRANSACTION_API}?email=${creditPayload.email}&type=${creditPayload.type}&amount=${creditPayload.amount}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${btoa(`${key}:${password}`)}`,
          },
          body: JSON.stringify(creditPayload),
        }
      );

      if (!creditResponse.ok) {
        throw new Error("Credit API request failed");
      }
      setSuccessMessage("Transfer successful!");
      setTransferTracker(false);
      setOpenModal(false);
    } catch (error) {
      console.error("Error transferring amount:", error);
      setErrorMessage("Transfer failed. Please try again.");
    }
  };

  const handleCloseRechargeModal = () => {
    setRechargeModalOpen(false);
    setSuccessMessage("");
    setErrorMessage("");
    setRechargeSuccessMessage("");
    setRechargeErrorMessage("");
  };

  const handleRecharge = (rechargeAmount) => {
    if (rechargeAmount <= 0) {
      setAmountError("Please enter an amount above 0.");
      return;
    }
    setAmountError("");
    // setRazorpayAmount(rechargeAmount * 100); // Multiply by 100 to convert to the smallest currency unit (e.g., paisa)
    createOrder(rechargeAmount); // Call createOrder with the new amount
  };

  if (apiResponse && orderId) {
    const orderMeta = {
      post_id: orderId,
      data: [
        {
          meta_key: "Razorpay_order_id",
          meta_value: apiResponse?.id,
        },
        {
          meta_key: "product_id",
          meta_value: "64",
        },
        {
          meta_key: "user_id",
          meta_value: userId?.user_data?.sub,
        },
        {
          meta_key: "customer_ip",
          meta_value: userIp,
        },
      ],
    };

    const metaAPiUrl = "https://dev.guruatmananda.org//wp-json/custom-post-meta/v1/update";

    fetch(metaAPiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(orderMeta),
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <MDBox mb={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={4}>
              <ComplexProjectCardClone
                color="info"
                icon="currency_rupee_icon"
                iconColor="info"
                title="Balance"
                description="Wallet securely stores payment methods for convenient and fast online transactions."
                value={`₹ ${balance === null ? "Loading..." : balance}`}
                dateTime={currentDate.formattedDate}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <ComplexProjectCardClone
                icon="currency_exchange_icon"
                iconColor="info"
                title="Transfer"
                description="Transfer funds seamlessly using a digital wallet: instant, secure, and convenient transactions."
                buttonLabel="Transfer"
                onButtonClick={handleOpenModal}
                buttonColor="info"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <ComplexProjectCardClone
                icon="add_icon"
                iconColor="info"
                title="Recharge"
                description="Quickly and securely recharge your digital wallet for swift payments and seamless transactions."
                buttonLabel="Recharge"
                onButtonClick={handleRechargeModal}
                buttonColor="success"
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3} py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Transactions rechargeModalOpen={rechargeModalOpen} openModal={openModal} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Modal open={openModal} onClose={handleCloseModal}>
        <div
          style={{
            margin: "auto",
            marginTop: 200,
            width: 400,
            backgroundColor: "#FFF",
            padding: 20,
            borderRadius: 8,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <MDInput
            label="Amount"
            type="number"
            value={amount}
            onChange={(event) => setAmount(event.target.value)}
            style={{ marginBottom: 20, width: "100%" }}
          />
          <MDInput
            label="Receiver's Email"
            type="email"
            value={receiverEmail}
            onChange={(event) => setReceiverEmail(event.target.value)}
            style={{ marginBottom: 20, width: "100%" }}
          />
          {amountError && <p style={{ color: "red", marginBottom: 10 }}>{amountError}</p>}
          <MDInput
            label="Note"
            type="text"
            value={note}
            onChange={(event) => setNote(event.target.value)}
            style={{ marginBottom: 20, width: "100%" }}
          />
          {successMessage && <p style={{ color: "green", marginBottom: 10 }}>{successMessage}</p>}
          {errorMessage && <p style={{ color: "red", marginBottom: 10 }}>{errorMessage}</p>}
          <MDButton variant="contained" color="primary" onClick={handleAddAmount}>
            Transfer
          </MDButton>
        </div>
      </Modal>
      {rechargeButton ? (
        <Dialog open={rechargeButton} aria-labelledby="loading-dialog">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "16px",
            }}
          >
            <CircularProgress style={{ color: "#200043" }} />
            <p style={{ marginTop: "16px" }}>Completing Transaction Please Wait....</p>
          </div>
        </Dialog>
      ) : (
        <RechargeModal
          open={rechargeModalOpen}
          onClose={handleCloseRechargeModal}
          onRecharge={handleRecharge}
          successMessage={rechargeSuccessMessage}
          errorMessage={rechargeErrorMessage}
          onRechargeAmountChange={(rechargeAmount) => {
            // Update the recharge amount in the Wallet component state
            setAmount(rechargeAmount);
          }}
        />
      )}
      <Dialog open={loadingRazorpay} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#200043" }} />
          <p style={{ marginTop: "16px" }}>Loading Razorpay...</p>
        </div>
      </Dialog>
      <Dialog open={loadingOrder} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#200043" }} />
          <p style={{ marginTop: "16px" }}>
            {completingOrder ? "Completing order..." : "Generating order..."}
          </p>
        </div>
      </Dialog>
      <Dialog open={walletTrans} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#200043" }} />
          <p style={{ marginTop: "16px" }}>Adding money to wallet</p>
        </div>
      </Dialog>
      <Dialog open={transactionCancelled} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <p style={{ marginTop: "16px" }}>
            You may have cancelled the payment or there was a delay in response from the UPI app.
          </p>
        </div>
      </Dialog>
      <Dialog open={transferTracker} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <p style={{ marginTop: "16px" }}>Transferring balance to {receiverEmail}</p>
          <CircularProgress />
        </div>
      </Dialog>
    </DashboardLayout>
  );
}

export default Wallet;
