import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";
import LoaderNew from "components/Loader/LoaderNew";
import { useNavigate } from "react-router-dom";

function RenewCheckoutPage() {
  const [metaData, setMetaData] = useState(null);
  const { name, programSlugGlobal, orderIdGlobal } = useHookstate(globalStore);
  const [paymentMethod, setPaymentMethod] = useState("wallet");
  const [walletBalance, setWalletBalance] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState();
  const [matchingPackage, setMatchingPackage] = useState(null);
  const [orderId, setorderId] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const userId = JSON.parse(localStorage.getItem("user"));
  // const Slug = localStorage.getItem("programSlug");
  // const planId = localStorage.getItem("spid");
  // const linkedProduct = localStorage.getItem("linked");
  const linkedGlobal = useHookstate(globalStore);
  const [subscriptionData, setSubscriptionData] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const token = localStorage.getItem("token");

  const [billingDetails, setBillingDetails] = useState({
    firstName: metaData?.billing_firstName || "",
    lastName: metaData?.billing_lastName || "",
    email: metaData?.billing_emailAddress || "",
    phone: metaData?.billing_phoneNumber || "",
    address: metaData?.billing_address || "",
    country: metaData?.billing_country || "",
    state: metaData?.billing_state || "",
    city: metaData?.billing_city || "",
    pincode: metaData?.billing_pincode || "",
    governmentId: metaData?.billing_governmentId || "",
  });
  const [loadingRazorpay, setLoadingRazorpay] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [walletTrans, setWalletTrans] = useState(false);
  const [completingOrder, setCompletingOrder] = useState(false);
  const [transactionCancelled, setTransactionCancelled] = useState(false);
  const [rechargeButton, setRechargeButton] = useState(false);
  const [generatingOrderText, setGeneratingOrderText] = useState(false);
  const [balanceCheck, setBalanceCheck] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [updatingSubscription, setUpdateSubscription] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  // fetch user meta data
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      setLoading(true);
      fetch(
        `https://dev.guruatmananda.org//wp-json/custom-user-meta/v1/fetch/${userId?.user_data.sub}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((responseData) => {
          setMetaData(responseData.meta_data);
          fetchSubscriptionData(responseData.meta_data?.[subscriptionIdProp]);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      console.error("Bearer token not found in localStorage.");
    }
  }, []);
  // setting billing details
  useEffect(() => {
    if (metaData) {
      setBillingDetails({
        firstName: metaData?.billing_firstName || "",
        lastName: metaData?.billing_lastName || "",
        email: metaData?.billing_emailAddress || "",
        phone: metaData?.billing_phoneNumber || "",
        address: metaData?.billing_address || "",
        country: metaData?.billing_country || "",
        state: metaData?.billing_state || "",
        city: metaData?.billing_city || "",
        pincode: metaData?.billing_pincode || "",
        governmentId: metaData?.billing_governmentId || "",
      });
    }
  }, [metaData]);

  const packageIdProp = `package_id_${linkedGlobal.linkedGlobal.get({ noproxy: true })}`;
  const subscriptionIdProp = `subscription_id_${linkedGlobal.linkedGlobal.get({ noproxy: true })}`;
  const packageIdValue = metaData?.meta_data?.[packageIdProp] || null;
  const subscriptionIdValue = metaData?.meta_data?.[subscriptionIdProp] || null;

  async function fetchSubscriptionData(subscriptionIdValue) {
    const subscriptionApiUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/subscriptions/${subscriptionIdValue}`;
    const consumerKey = "ck_e07619a34325d4367c758704b04844c734ecc86d";
    const consumerSecret = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";

    const authToken = btoa(`${consumerKey}:${consumerSecret}`);

    try {
      const response = await fetch(subscriptionApiUrl, {
        method: "GET",
        headers: {
          Authorization: `Basic ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch subscription data: " + response.status);
      }

      const subscriptionResponse = await response.json();
      setSubscriptionData(subscriptionResponse);
    } catch (error) {
      navigate(-2);
      console.error("Error fetching subscription data:", error);
      return null;
    }
  }

  const lineItems = subscriptionData?.line_items;
  const extractedData = [];
  // const variationId = lineItems[0]?.variation_id;

  // Loop through the line_items array
  lineItems?.forEach((item) => {
    const name = item?.name;
    const imageSrc = item?.image.src;
    const price = item?.price;
    const product_id = item?.product_id;
    const quantity = item?.quantity;
    const variation_id = item?.variation_id;

    // Push the extracted data as an object into the extractedData array
    extractedData.push({
      name: name,
      imageSrc: imageSrc,
      price: price,
      product_id: product_id,
      quantity: quantity,
      variation_id: variation_id,
    });
  });
  // Now, extractedData contains an array of objects with name, image source, and price
  const fetchWalletBalance = async () => {
    try {
      const apiUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/wallet/balance?email=${userId?.user_data.user_email}`;

      const username = "ck_e07619a34325d4367c758704b04844c734ecc86d";
      const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";

      const base64Credentials = btoa(`${username}:${password}`);

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setWalletBalance(data.balance);
      } else {
        console.error("Failed to fetch wallet balance");
      }
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  useEffect(() => {
    fetchWalletBalance();
  }, [token]);

  const handleBillingDetailsChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails({ ...billingDetails, [name]: value });
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };
  /* Loading script for Razorpay*/
  function loadScript(src) {
    setLoadingRazorpay(true);
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        setLoadingRazorpay(false);
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const fetchData = async () => {
    const url = "https://dev.guruatmananda.org//wp-json/myplugin/v1/create_razorpay_order/";
    const username = "rzp_live_BEu8ZlChbYTdWW";
    const password = "OxFhXEVWMWOBwfDdpCcy67wD";
    const razorPayAuth = btoa(`${username}:${password}`);
    const token = localStorage.getItem("token");
    const requestBody = {
      amount: extractedData[0]?.price * 100,
      // amount: 100,
      currency: "INR",
      receipt: "receipt#1",
      notes: {
        key: "value",
      },
    };

    const requestBodyJSON = JSON.stringify(requestBody);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: requestBodyJSON,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setApiResponse(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleDonateNow = async () => {
    // Basic validation
    if (
      !billingDetails.firstName ||
      !billingDetails.lastName ||
      !billingDetails.email ||
      !billingDetails.phone ||
      !billingDetails.governmentId ||
      !billingDetails.address ||
      !billingDetails.country ||
      !billingDetails.state ||
      !billingDetails.city ||
      !billingDetails.pincode
    ) {
      alert("Please fill in all the required fields.");
      return;
    }
    fetchData();
    if (paymentMethod === "wallet") {
      if (walletBalance === null || walletBalance === "Loading") {
        console.log("Wallet balance is loading or unavailable.");
      } else if (parseFloat(walletBalance) >= parseFloat(extractedData[0]?.price)) {
        setLoadingOrder(true);

        const orderNew = {
          customer_id: userId?.user_data?.sub,
          billing: {
            first_name: billingDetails.firstName,
            last_name: billingDetails.lastName,
            email: billingDetails.email,
            phone: billingDetails.phone,
            address_1: billingDetails.address,
            country: billingDetails.country,
            state: billingDetails.state,
            city: billingDetails.city,
            postcode: billingDetails.pincode,
          },
          line_items: [
            {
              product_id: extractedData[0]?.variation_id,
              quantity: extractedData[0]?.quantity,
            },
          ],
          customer_note: "Please deliver",
        };

        const apiUrl = "https://dev.guruatmananda.org/wp-json/wc/v3/orders";
        const consumerKey = "ck_e07619a34325d4367c758704b04844c734ecc86d";
        const consumerSecret = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
        const authToken = btoa(`${consumerKey}:${consumerSecret}`);

        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${authToken}`,
            },
            body: JSON.stringify(orderNew),
          });

          if (response.ok) {
            const responseData = await response.json();
            setLoadingOrder(false);
            setWalletTrans(true);
            // After a successful order, hit the wallet API
            const walletApiUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/wallet?email=${userId?.user_data.user_email}&type=debit&amount=${extractedData[0]?.price}`;
            const walletAuthToken = btoa(`${consumerKey}:${consumerSecret}`);
            const walletResponse = await fetch(walletApiUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${walletAuthToken}`,
              },
            });

            if (walletResponse.ok) {
              // Wallet API call successful
              const walletData = await walletResponse.json();
              // setSuccessMessage("Initiating Transaction....");
              // setShowSuccessModal(true);
              orderIdGlobal.set(responseData.id);
              setWalletTrans(false);
              setLoadingOrder(true);
              setCompletingOrder(true);
              // Update the order with Wallet data
              try {
                const orderUpdateUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/orders/${responseData.id}`;
                const authToken = btoa(`${consumerKey}:${consumerSecret}`);
                // const walletTransactionId = walletData.id;
                const orderUpdateData = {
                  set_paid: true,
                  status: "completed",
                };

                const orderUpdateResponse = await fetch(orderUpdateUrl, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Basic ${authToken}`,
                  },
                  body: JSON.stringify(orderUpdateData),
                });

                if (orderUpdateResponse.ok) {
                  // setSuccessMessage("Generating subscription...");
                  // setShowSuccessModal(true);
                  setLoadingOrder(false);
                  setCompletingOrder(false);
                  setSubscription(true);
                  setUpdateSubscription(true);

                  // After a successful order update, hit the subscription API
                  const apiUrl =
                    "https://dev.guruatmananda.org/wp-json/custom/v1/update-subscription-dates";
                  const productId = extractedData[0].product_id;
                  const userID = userId?.user_data?.sub;

                  const token = localStorage.getItem("token");

                  if (!token) {
                    console.error("Token not found in localStorage");
                  } else {
                    const requestBody = {
                      product_id: productId,
                      user_id: userID,
                    };

                    fetch(apiUrl, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                      body: JSON.stringify(requestBody),
                    })
                      .then((response) => {
                        if (!response.ok) {
                          throw new Error("Network response was not ok");
                        }
                        return response.json();
                      })
                      .then((data) => {
                        // setSuccessMessage("Subscription generated successfully.");
                        // setShowSuccessModal(true);
                        setSubscription(false);
                        setUpdateSubscription(false);
                        navigate("/order-details");
                      })
                      .catch((error) => {
                        console.error("Error:", error);
                      });
                  }
                } else {
                  const orderUpdateErrorData = await orderUpdateResponse.json();
                  console.error("Error updating order:", orderUpdateErrorData);
                  setSuccessMessage("Order update failed. Contact support");
                  setShowSuccessModal(true);
                }
              } catch (error) {
                console.error("Error updating order:", error);
                // alert("An error occurred while processing your request. Please try again later.");
                setSuccessMessage(
                  "An error occurred while processing your request. Please try again later."
                );
                setShowSuccessModal(true);
              }
            } else {
              // Handle error response from the Wallet API
              console.error("Error hitting wallet API:", await walletResponse.json());
              setSuccessMessage("Error hitting wallet API. Contact Support");
              setShowSuccessModal(true);
            }
          } else {
            // Handle error response from the API
            const errorData = await response.json();
            console.error("Error creating order:", errorData);
            setSuccessMessage("Order creation failed. Please try again later.");
            setShowSuccessModal(true);
          }
        } catch (error) {
          console.error("Error creating order:", error);
          //   alert("An error occurred while processing your request. Please try again later.");
          setSuccessMessage(
            "An error occurred while processing your request. Please try again later."
          );
          setShowSuccessModal(true);
        }
      } else {
        setSuccessMessage("Insufficient Wallet Balance. Please choose another payment method.");
        setShowSuccessModal(true);
      }
    } else if (apiResponse) {
      setLoadingOrder(true);
      setRechargeButton(true);
      const isScriptLoaded = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

      if (!isScriptLoaded) {
        alert("Razorpay SDK failed to load. Please check your internet connection and try again.");
        setRechargeButton(false);
        return;
      }

      const orderData = {
        customer_id: userId?.user_data?.sub,
        billing: {
          first_name: billingDetails.firstName,
          last_name: billingDetails.lastName,
          email: billingDetails.email,
          phone: billingDetails.phone,
          address_1: billingDetails.address,
          country: billingDetails.country,
          state: billingDetails.state,
          city: billingDetails.city,
          postcode: billingDetails.pincode,
        },
        line_items: [
          {
            product_id: extractedData[0]?.variation_id,
            quantity: extractedData[0]?.quantity,
          },
        ],
        customer_note: "Please deliver",
      };
      const apiUrl = "https://dev.guruatmananda.org/wp-json/wc/v3/orders";
      const consumerKey = "ck_e07619a34325d4367c758704b04844c734ecc86d";
      const consumerSecret = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";

      const authToken = btoa(`${consumerKey}:${consumerSecret}`);
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${authToken}`,
          },
          body: JSON.stringify(orderData),
        });

        if (response.ok) {
          setLoadingOrder(false);

          const responseData = await response.json();

          fetch("https://dev.guruatmananda.org/wp-json/wc/v3/payment_gateways/razorpay", {
            headers: {
              Authorization: `Basic ${authToken}`,
            },
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                setRechargeButton(false);
                setTransactionCancelled(true);
                throw new Error("Failed to fetch API: " + response.status);
              }
            })
            .then((data) => {
              const opt = {
                id: responseData.id,
                order_id: apiResponse.id,
                amount: extractedData[0]?.price * 100,
                handler: async (response) => {
                  if (response.razorpay_payment_id) {
                    setLoadingOrder(true);
                    setCompletingOrder(true);
                    var paymentId = response.razorpay_payment_id;
                    // simulateWebhook(paymentId);
                    const data = {
                      orderCreationId: responseData.id,
                      razorpayPaymentId: response.razorpay_payment_id,
                      razorpayOrderId: response.razorpay_order_id,
                      razorpaySignature: response.razorpay_signature,
                      payment_method: "razorpay",
                      payment_method_title: "Razorpay",
                      set_paid: true,
                      status: "completed",
                    };

                    const username = "ck_e07619a34325d4367c758704b04844c734ecc86d";
                    const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
                    const basicAuth = btoa(`${username}:${password}`); // Base64 encoding

                    try {
                      const apiUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/orders/${responseData.id}`;
                      // localStorage.setItem("orderId", responseData.id);
                      orderIdGlobal.set(responseData.id);
                      setLoadingOrder(false);
                      setCompletingOrder(false);
                      setSubscription(true);
                      setUpdateSubscription(true);

                      const response = await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Basic ${basicAuth}`,
                        },
                        body: JSON.stringify(data),
                      });

                      if (response.ok) {
                        const apiUrl =
                          "https://dev.guruatmananda.org/wp-json/custom/v1/update-subscription-dates";
                        const productId = extractedData[0].product_id;
                        const userID = userId?.user_data?.sub;
                        // Retrieve the token from localStorage
                        const token = localStorage.getItem("token");

                        if (!token) {
                          console.error("Token not found in localStorage");
                        } else {
                          // Create a JSON object with the data to send in the request body
                          const requestBody = {
                            product_id: productId,
                            user_id: userID,
                          };

                          fetch(apiUrl, {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${token}`,
                            },
                            body: JSON.stringify(requestBody),
                          })
                            .then((response) => {
                              if (!response.ok) {
                                throw new Error("Network response was not ok");
                              }
                              return response.json();
                            })
                            .then((data) => {
                              // setSuccessMessage("Subscription generated successfully.");
                              // setShowSuccessModal(true);
                              // navigate("/dashboards/active-courses");
                              setSubscription(false);
                              setUpdateSubscription(false);
                              setRechargeButton(false);
                              navigate("/order-details");
                            })
                            .catch((error) => {
                              console.error("Error:", error);
                            });
                          // navigate("/dashboards/active-courses");
                        }
                      } else {
                        const errorData = await response.json();
                        console.error("Error while posting data to /payment/success:", errorData);
                      }
                    } catch (error) {
                      console.error("Error while posting data to /payment/success:", error);
                    }
                  }
                },
                modal: {
                  ondismiss: function () {
                    setRechargeButton(false);
                    navigate(-2);
                  },
                },
                currency: "INR",
                name: "Sri Siddha Sanmarga",
                prefill: {
                  name: `${billingDetails.firstName} ${billingDetails.lastName}`,
                  email: billingDetails.email,
                  contact: billingDetails.phone,
                },
                notes: {
                  first_name: billingDetails.firstName,
                  last_name: billingDetails.lastName,
                  email: billingDetails.email,
                  phone: billingDetails.phone,
                  address_1: billingDetails.address,
                  country: billingDetails.country,
                  state: billingDetails.state,
                  city: billingDetails.city,
                  postcode: billingDetails.pincode,
                  mentorship_program: courseData?.title,
                  donors_governmentId: billingDetails.governmentId || "NA",
                },
                key: data?.settings?.key_id?.value,
              };
              const razorpay = new window.Razorpay(opt);

              razorpay.on("payment.failed", (response) => {
                // console.log("Payment Failed here", response);
              });

              razorpay.on("payment.success", async (res) => {
                try {
                  navigate("/");
                  console.log("success");
                } catch (error) {
                  console.error("Error handling payment success:", error);
                }
              });
              razorpay.open();
            })
            .catch((error) => {
              console.error("API error:", error);
            });
        } else {
          console.error("Failed to create order:", response.status, response.statusText);
        }
      } catch (error) {
        console.error("Error creating order:", error);
      }
    }
  };

  const handleCloseSuccessModal = () => {
    // Close the success modal and reset the success message
    setShowSuccessModal(false);
    setSuccessMessage("");
  };
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
        <p style={{ marginTop: "10px" }}>Fetching Subscription Details...</p>
      </div>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container mt={3} spacing={3}>
        <Grid item xs={12} md={7.2} lg={7.2}>
          <MDTypography variant="h3" fontWeight="bold" mb={3}>
            Billing Details
          </MDTypography>
          <Card id="basic-info" pb={3} sx={{ overflow: "visible" }}>
            <MDBox p={2}>
              <form>
                <MDBox component="form" pb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="First Name"
                        name="firstName"
                        value={billingDetails.firstName}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Last Name"
                        name="lastName"
                        value={billingDetails.lastName}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Email"
                        name="email"
                        value={billingDetails.email}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Phone"
                        name="phone"
                        value={billingDetails.phone}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Government ID"
                        name="governmentId"
                        value={billingDetails.governmentId}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Address"
                        name="address"
                        value={billingDetails.address}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Country"
                        name="country"
                        value={billingDetails.country}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="State"
                        name="state"
                        value={billingDetails.state}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="City"
                        name="city"
                        value={billingDetails.city}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="PIN Code"
                        name="pincode"
                        value={billingDetails.pincode}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </form>
            </MDBox>
          </Card>
        </Grid>

        {extractedData.length > 0 ? (
          <Grid item xs={12} md={4.8} lg={4.8}>
            <MDBox mt={8}>
              <img
                src={extractedData[0]?.imageSrc}
                alt="Program"
                style={{
                  maxWidth: "100%",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                }}
              />
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6">Plan</MDTypography>
                <MDTypography style={{ textAlign: "right" }}>{extractedData[0]?.name}</MDTypography>
              </div>
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6">Donation</MDTypography>
                <MDTypography textAlign="right">₹ {extractedData[0]?.price}</MDTypography>
              </div>

              <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                {/* <MDTypography variant="h6">Donation Method</MDTypography> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={paymentMethod === "wallet"}
                      onChange={handlePaymentMethodChange}
                      value="wallet"
                    />
                  }
                  label={`Wallet (Balance: ₹ ${walletBalance || "Loading..."})`}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={paymentMethod === "online"}
                      onChange={handlePaymentMethodChange}
                      value="online"
                    />
                  }
                  label="Online(Credit/Debit/UPI)"
                />
              </div>

              <MDButton
                variant="contained"
                onClick={handleDonateNow}
                color="info"
                style={{
                  width: "100%",
                  //   background: "linear-gradient(310deg, #7928CA, #FF0080)",
                  //   color: "#FFFFFF",
                }}
              >
                Proceed to checkout
              </MDButton>
            </MDBox>
          </Grid>
        ) : null}
      </Grid>
      {/* Success Modal */}
      <Dialog open={showSuccessModal} onClose={handleCloseSuccessModal}>
        <DialogContent>
          {/* <MDTypography variant="h5" fontWeight="bold">
            Success!
          </MDTypography> */}
          <MDTypography>{successMessage}</MDTypography>
        </DialogContent>
      </Dialog>
      <Dialog open={balanceCheck} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#200043" }} />
          <p style={{ marginTop: "16px" }}>Balance is still loading or unavailable</p>
        </div>
      </Dialog>
      <Dialog open={loadingOrder} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#200043" }} />
          <p style={{ marginTop: "16px" }}>
            {completingOrder ? "Completing order..." : "Generating order..."}
          </p>
        </div>
      </Dialog>
      <Dialog open={walletTrans} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#200043" }} />
          <p style={{ marginTop: "16px" }}>Processing wallet transaction</p>
        </div>
      </Dialog>
      <Dialog open={subscription} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#200043" }} />
          <p style={{ marginTop: "16px" }}>
            {updatingSubscription ? "Updating Subscription..." : "Processing wallet transaction"}
          </p>
        </div>
      </Dialog>
      <Dialog open={transactionCancelled} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <p style={{ marginTop: "16px" }}>
            You may have cancelled the payment or there was a delay in response from the UPI app.
          </p>
        </div>
      </Dialog>
      <Dialog open={loadingRazorpay} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#200043" }} />
          <p style={{ marginTop: "16px" }}>Loading Razorpay...</p>
        </div>
      </Dialog>
      <Dialog open={rechargeButton} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#200043" }} />
          <p style={{ marginTop: "16px" }}>Processing...</p>
        </div>
      </Dialog>
    </DashboardLayout>
  );
}

export default RenewCheckoutPage;
