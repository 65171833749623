import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const RedirectLoginPage = () => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={12} textAlign="center">
        <Typography variant="h4" component="h1" gutterBottom>
          You are not logged in!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please login to access this page.
        </Typography>
        <Button component={Link} to="/login" variant="contained" color="primary">
          Go to Login
        </Button>
      </Grid>
    </Grid>
  );
};

export default RedirectLoginPage;
