import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Create a theme with the play button color
const theme = createTheme({
  palette: {
    action: {
      active: "#7879A", // Set the color to grey (#808080)
    },
  },
});

function VideoModal({ open, onClose, videoUrl }) {
  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            padding: 0.5,
            color: "rgba(255,255,255,1)",
          }}
        >
          <CloseIcon />
        </IconButton>
        <video controls controlsList="nodownload" width="100%" height="auto">
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Dialog>
    </ThemeProvider>
  );
}

VideoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  videoUrl: PropTypes.string.isRequired,
};

export default VideoModal;
