import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";

function BlogDetails() {
  const blogData = {
    image:
      "https://cdn.dribbble.com/users/788099/screenshots/17100366/media/c3bae91aa88ff647171e151a8c51c052.jpg?resize=1200x800&vertical=center",
    title: "Blog Title",
    body: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
  };

  const suggestedBlogs = [
    {
      image:
        "https://cdn.dribbble.com/users/2598141/screenshots/14169207/media/e06f744b0a501df048d74cd07c23a739.png?resize=1200x900&vertical=center",
      title: "Suggested Blog 1",
      description: "Description of Suggested Blog 1",
      action: {
        type: "internal",
        route: "/suggested-blog1",
        color: "info",
        label: "Read More",
      },
    },
    {
      image:
        "https://cdn.dribbble.com/users/2598141/screenshots/14169207/media/e06f744b0a501df048d74cd07c23a739.png?resize=1200x900&vertical=center",
      title: "Suggested Blog 2",
      description: "Description of Suggested Blog 2",
      action: {
        type: "internal",
        route: "/suggested-blog2",
        color: "info",
        label: "Read More",
      },
    },
    {
      image:
        "https://cdn.dribbble.com/users/2598141/screenshots/14169207/media/e06f744b0a501df048d74cd07c23a739.png?resize=1200x900&vertical=center",
      title: "Suggested Blog 3",
      description: "Description of Suggested Blog 3",
      action: {
        type: "internal",
        route: "/suggested-blog3",
        color: "info",
        label: "Read More",
      },
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3} style={{ padding: "20px" }}>
        {/* Left Section */}
        <Grid item xs={12} md={6} lg={8}>
          <div style={{ width: "100%", padding: "10px" }}>
            <img src={blogData.image} alt="Blog" style={{ width: "70%", marginBottom: "10px" }} />
            <h2>{blogData.title}</h2>
            <MDTypography variant="body1" color="text">
              {blogData.body}
            </MDTypography>
          </div>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={6} lg={4}>
          <h2>Suggested Blogs</h2>
          <Grid container spacing={2}>
            {suggestedBlogs.map((blog, index) => (
              <Grid key={index} item xs={12}>
                <DefaultProjectCard
                  image={blog.image}
                  title={blog.title}
                  description={blog.description}
                  action={blog.action}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default BlogDetails;
