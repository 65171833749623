import React, { useState, useEffect } from "react";
import "./Loader.css";

const Loader = () => {
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (loadingPercentage < 93) {
        setLoadingPercentage((prevPercentage) => prevPercentage + 1);
      } else if (loadingPercentage > 97) {
        setLoadingPercentage(97);
      }
    }, 250);

    return () => clearInterval(interval);
  }, [loadingPercentage]);

  return (
    <div className="container">
      <div className="loader">
        <h2>Loading...</h2>
        <div className="loader-bar" style={{ width: `${loadingPercentage}%` }}></div>
        <p>{loadingPercentage}%</p>
      </div>
    </div>
  );
};

export default Loader;
