import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import logo from "./Sanmarga Awakening 01.png";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

function SignupIllustration() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const { userGlobal, tokenGlobal } = useHookstate(globalStore);
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Add validation for password and confirm password
    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      setError("Please fill in all fields.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Email validation regex pattern
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailPattern.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      // First, make the registration API call
      const registrationResponse = await fetch(
        "https://dev.guruatmananda.org/wp-json/custom/v1/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
          }),
        }
      );

      if (registrationResponse.ok) {
        // Registration successful
        const registrationData = await registrationResponse.json();

        // Perform the login using the same email and password
        const loginResponse = await fetch(
          "https://dev.guruatmananda.org/wp-json/jwt-auth/v1/token",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: email,
              password: password,
            }),
          }
        );

        if (loginResponse.ok) {
          const loginData = await loginResponse.json();
          const token = loginData?.token;
          localStorage.setItem("user", JSON.stringify(loginData));
          userGlobal.set(loginData);
          localStorage.setItem("token", token);
          tokenGlobal.set(token);

          // Update user meta information
          const updateResponse = await fetch(
            "https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/update",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                user_id: registrationData.user_id, // Use the user_id from the registration API response
                data: [
                  {
                    meta_key: "firstName",
                    meta_value: firstName,
                  },
                  {
                    meta_key: "lastName",
                    meta_value: lastName,
                  },
                ],
              }),
            }
          );

          if (updateResponse.ok) {
            // User meta update successful, navigate to "/dashboards/programs"
            navigate("/dashboards/programs");
          } else {
            // User meta update failed
            const updateErrorData = await updateResponse.json();
            throw new Error(updateErrorData.message);
          }
        } else {
          // Login failed
          const loginErrorData = await loginResponse.json();
          throw new Error(loginErrorData.message);
        }
      } else {
        // Registration failed
        const errorData = await registrationResponse.json();
        throw new Error(errorData.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const illustration = "https://runcloud-programs.s3.ap-south-1.amazonaws.com/SD+login.png";

  return (
    <IllustrationLayout
      title="Sign Up"
      logo={logo}
      description="Enter your email and password to sign up"
      illustration={illustration}
    >
      <MDBox component="form" role="form" onSubmit={handleFormSubmit}>
        <MDBox mb={2}>
          <MDInput
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            label="First Name"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            label="Last Name"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            label="Confirm Password"
            fullWidth
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" size="large" fullWidth>
            Sign Up
          </MDButton>
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Already have an account ?
            <MDTypography
              component={Link}
              to="/authentication/sign-in/illustration"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Log In
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
      {error && (
        <MDBox mb={2} color="error">
          {error}
        </MDBox>
      )}
    </IllustrationLayout>
  );
}

export default SignupIllustration;
