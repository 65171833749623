/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React examples
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { Grid } from "@mui/material";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

function DashboardNavbar({ absolute, info, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const route2 = useLocation();
  const { programSlugGlobal, lessonNameGlobal, courseNameGlobal } = useHookstate(globalStore);

  let titleSpecific;
  if (route2.pathname === "/courses/classroom/class") {
    titleSpecific = courseNameGlobal.get() || " ";
  } else if (route2.pathname === "/dashboards/enrolled-courses") {
    titleSpecific = programSlugGlobal.get() || " ";
  }

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(false);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  // const iconsStyle = ({ palette: { dark, white, text, info }, functions: { rgba } }) => ({
  //   color: () => {
  //     let colorValue = info || darkMode ? info.main : dark.main;

  //     if (transparentNavbar && !info) {
  //       colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
  //     }

  //     return colorValue;
  //   },
  // });
  const iconsStyle = ({ palette: { white } }) => ({
    color: white.main,
  });

  return (
    <AppBar
      position={absolute ? "absolute" : "relative"}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, info, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <IconButton
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
            size="medium"
            disableRipple
          >
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={info ? "primary" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              <Link to="/authentication/sign-in/illustration">
                <IconButton
                  sx={navbarIconButton}
                  size="medium"
                  disableRipple
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    localStorage.clear();
                  }}
                >
                  <Icon sx={iconsStyle}>power_settings_new</Icon>
                </IconButton>
              </Link>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MDBox
              color="inherit"
              mb={{ xs: 1, md: 0 }}
              sx={(theme) => navbarRow(theme, { isMini })}
            >
              <Breadcrumbs
                icon="home"
                title={
                  route2.pathname === "/courses/classroom/class" ||
                  route2.pathname === "/courses/classroom/lesson" ||
                  route2.pathname === "/dashboards/enrolled-courses"
                    ? titleSpecific !== "undefined" && titleSpecific !== null
                      ? titleSpecific
                      : " "
                    : route[route.length - 1]
                }
                route={route}
                info={info}
              />
            </MDBox>
          </Grid>
        </Grid>
      </Toolbar>

      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MDBox color="white" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                “You are Born to Succeed, because you are Designed to Succeed”<br></br>- Siddha
                Guru Atmananda ji
              </span>
            </MDBox>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  info: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  info: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
