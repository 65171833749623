import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { Link } from "react-router-dom";

const EventCard = ({
  id,
  image,
  title,
  dateTime,
  description,
  members,
  action,
  enrolledCourses,
  totalCourses,
  onClick,
}) => {
  const handleClick = () => {
    // console.log("Button clicked");
    if (action.onClick) {
      action.onClick(id); // Call the onClick function with the program ID
    }
  };

  const renderMembers = members.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      <MDAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          ml: -1.25,
          cursor: "pointer",
          position: "relative",

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    </Tooltip>
  ));

  return (
    <Card>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
          <MDAvatar
            src={image}
            alt={title}
            size="lg"
            variant="rounded"
            sx={({ borders: { borderWidth }, palette: { white }, spacing }) => ({
              border: `${borderWidth[2]} solid ${white.main}`,
              // ml: spacing(2), // Add padding here
              cursor: "pointer",
              position: "relative",
              width: "45px", // Set the width to 45px
              height: "45px", // Set the height to 45px

              "&:hover, &:focus": {
                zIndex: "10",
              },
            })}
          />
          <MDTypography
            variant="h5"
            fontWeight="medium"
            textTransform="capitalize"
            sx={{ ml: 2 }} // Add margin here to create a gap
          >
            {title}
          </MDTypography>
          {dateTime ? (
            <MDTypography
              variant="caption"
              fontWeight="regular"
              color="text"
              textTransform="capitalize"
            >
              {dateTime}
            </MDTypography>
          ) : null}
        </MDBox>
        <MDBox my={2}>
          <MDTypography
            variant="body2"
            color="text"
            sx={{
              fontSize: "14px", // Set the font size to 14px
              fontWeight: 400, // Set the font weight to 400
            }}
          >
            {description}
          </MDTypography>
        </MDBox>
        {id ? (
          <MDBox>
            <MDTypography component="span" variant="body2" fontWeight="bold" color="text">
              Meeting ID:&nbsp;
            </MDTypography>
            <MDTypography component="span" variant="body2" color="text">
              {id}
            </MDTypography>
          </MDBox>
        ) : null}
        {totalCourses ? (
          <MDBox>
            <MDTypography component="span" variant="body2" fontWeight="bold">
              Total Courses:&nbsp;
            </MDTypography>
            <MDTypography component="span" variant="body2" color="text">
              {totalCourses}
            </MDTypography>
          </MDBox>
        ) : null}
        {enrolledCourses ? (
          <MDBox>
            <MDTypography component="span" variant="body2" fontWeight="bold">
              Enrolled Courses:&nbsp;
            </MDTypography>
            <MDTypography component="span" variant="body2" color="text">
              {enrolledCourses}
            </MDTypography>
          </MDBox>
        ) : null}
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDButton
            component={action.type === "internal" ? Link : "a"}
            to={action.route}
            href={action.route}
            variant="gradient"
            color="transparent"
            size="small"
            sx={{
              backgroundImage: "linear-gradient(310deg,#7928CA,#FF0080)",
              padding: "15px",
              color: "white",
            }}
            onClick={handleClick} // Use handleClick function
          >
            {action.label}
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
};

EventCard.defaultProps = {
  id: "",
  dateTime: "",
  members: [],
  totalCourses: "",
  enrolledCourses: "",
};

EventCard.propTypes = {
  image: PropTypes.string.isRequired,
  id: PropTypes.string,
  totalCourses: PropTypes.string,
  enrolledCourses: PropTypes.string,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  description: PropTypes.node.isRequired,
  members: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["internal", "external"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }).isRequired,
};

export default EventCard;
