import React from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

function ButtonCell({ color, label, route, courseId, id }) {
  const navigate = useNavigate(); // Initialize the navigate function
  const { cidGlobal, assidGlobal } = useHookstate(globalStore);
  const handleButtonClick = () => {
    if (route) {
      // If a route is specified, navigate to it when the button is clicked
      navigate(route);

      // Save courseId and id to localStorage
      // localStorage.setItem("cid", courseId);
      cidGlobal.set(courseId);
      // localStorage.setItem("assid", id);
      assidGlobal.set(id);
    }
  };

  return (
    <MDBox display="flex" alignItems="center">
      <MDButton variant="contained" color={color} onClick={handleButtonClick}>
        {label}
      </MDButton>
    </MDBox>
  );
}

ButtonCell.defaultProps = {
  color: "primary",
  label: "Button",
  route: null,
  courseId: null,
  id: null,
};

ButtonCell.propTypes = {
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  label: PropTypes.string,
  route: PropTypes.string,
  courseId: PropTypes.number, // Add prop type for courseId
  id: PropTypes.number, // Add prop type for id
};

export default ButtonCell;
