import React, { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/account/components/FormField";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import { Padding } from "@mui/icons-material";
import Loader from "components/Loader/Loader";
import ToasterMessage from "components/Toaster";

function FamilyDetails({ userData }) {
  let user = JSON.parse(localStorage.getItem("user"));
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("token");

  const [formData, setFormData] = useState({
    fatherName: "",
    fatherMailId: "",
    fatherMobileNumber: "",
    fatherOccupation: "",
    motherName: "",
    motherMailId: "",
    motherMobileNumber: "",
    motherOccupation: "",
  });
  useEffect(() => {
    if (userData) {
      setFormData({
        fatherName: userData.fatherName ? userData.fatherName : "",
        fatherMailId: userData.fatherMailId ? userData.fatherMailId : "",
        fatherMobileNumber: userData.fatherMobileNumber ? userData.fatherMobileNumber : "",
        fatherOccupation: userData.fatherOccupation ? userData.fatherOccupation : "",
        motherName: userData.motherName ? userData.motherName : "",
        motherMailId: userData.motherMailId ? userData.motherMailId : "",
        motherMobileNumber: userData.motherMobileNumber ? userData.motherMobileNumber : "",
        motherOccupation: userData.motherOccupation ? userData.motherOccupation : "",
      });
    }
  }, [userData]);

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const formRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validations
    const {
      fatherName,
      fatherMailId,
      fatherMobileNumber,
      fatherOccupation,
      motherName,
      motherMailId,
      motherMobileNumber,
      motherOccupation,
    } = formData;
    if (
      !fatherName ||
      !fatherMailId ||
      !fatherMobileNumber ||
      !fatherOccupation ||
      !motherName ||
      !motherMailId ||
      !motherMobileNumber ||
      !motherOccupation
    ) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }

    const payload = {
      user_id: user?.user_data?.sub,
      data: [
        ...Object.entries(formData).map(([key, value]) => ({
          meta_key: key,
          meta_value: value,
        })),
      ],
    };
    try {
      const response = await fetch(
        "https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/update",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        // console.log("Profile updated successfully!");
        setSuccessMessage("Profile updated successfully!");

        // Fetch the updated user data from the API
        const updatedDataResponse = await fetch(
          `https://dev.guruatmananda.org//wp-json/custom-user-meta/v1/fetch/${user?.user_data?.sub}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (updatedDataResponse.ok) {
          const updatedUserData = await updatedDataResponse.json();
          // Handle the updated user data as needed
          // console.log("Updated user data:", updatedUserData);
        } else {
          setErrorMessage("Error fetching updated user data");

          console.error("Error fetching updated user data:", updatedDataResponse.statusText);
        }

        // Redirect to the desired page after successful update
        // navigate("/dashboards/enrolled-courses");
      } else {
        setErrorMessage("Error updating profile");
        console.error("Error updating profile:", response.statusText);
      }
    } catch (error) {
      setErrorMessage("Error updating profile");

      console.error("Error updating profile:", error);
    }
  };
  const handleToasterDismiss = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  if (!user) {
    return <Navigate to="/auth/login" replace />;
  }

  return (
    <>
      <Card id="basic-info" pb={3} sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">Family Information</MDTypography>
        </MDBox>
        <form onSubmit={handleSubmit}>
          <MDBox component="form" pb={3} px={3} ref={formRef}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  value={formData.fatherName}
                  onChange={handleInputChange}
                  name="fatherName"
                  label="Father's Name"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                {" "}
                <FormField
                  type="email"
                  value={formData.fatherMailId}
                  onChange={handleInputChange}
                  name="fatherMailId"
                  label="Father's Mail ID"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="tel"
                  value={formData.fatherMobileNumber}
                  onChange={handleInputChange}
                  name="fatherMobileNumber"
                  label="Father's Mobile Number"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  value={formData.fatherOccupation}
                  onChange={handleInputChange}
                  name="fatherOccupation"
                  label="Father's Occupation"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  value={formData.motherName}
                  onChange={handleInputChange}
                  name="motherName"
                  label="Mother's Name"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="email"
                  value={formData.motherMailId}
                  onChange={handleInputChange}
                  name="motherMailId"
                  label="Mother's Mail ID"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {" "}
                <FormField
                  type="tel"
                  value={formData.motherMobileNumber}
                  onChange={handleInputChange}
                  name="motherMobileNumber"
                  label="Mother's Mobile Number"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {" "}
                <FormField
                  type="text"
                  value={formData.motherOccupation}
                  onChange={handleInputChange}
                  name="motherOccupation"
                  label="Mother's Occupation"
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" alignItems="center" p={2}>
            <MDButton
              color="dark"
              type="submit"
              size="small"
              sx={{
                background: "linear-gradient(310deg, #7928CA, #FF0080)",
                color: "white", // You might need to adjust the text color for better contrast
              }}
            >
              Update
            </MDButton>
          </MDBox>
        </form>
      </Card>
      {successMessage && (
        <ToasterMessage
          message={successMessage}
          color="success"
          duration={1000}
          onDismiss={handleToasterDismiss}
        />
      )}

      {errorMessage && (
        <ToasterMessage
          message={errorMessage}
          color="error"
          duration={1000}
          onDismiss={handleToasterDismiss}
        />
      )}
    </>
  );
}
FamilyDetails.propTypes = {
  userData: PropTypes.object,
};

export default FamilyDetails;
