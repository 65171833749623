import React from "react";
import "./Loader.css"; // Make sure to import your CSS file

const LoaderNew = () => {
  return (
    <div className="loader">
      <div className="loader__before"></div>
      <div className="loader__after"></div>
    </div>
  );
};

export default LoaderNew;
