import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

const META_API = "https://dev.guruatmananda.org//wp-json/custom-user-meta/v1/fetch/";

function CheckoutPage() {
  const [paymentMethod, setPaymentMethod] = useState("wallet");
  const [walletBalance, setWalletBalance] = useState(null);
  const [courseData, setCourseData] = useState();
  const [selectedPackage, setSelectedPackage] = useState();
  const [matchingPackage, setMatchingPackage] = useState(null);
  const [orderId, setorderId] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const userId = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const { pslugGlobal, orderIdGlobal } = useHookstate(globalStore);
  const { spidGlobal } = useHookstate(globalStore);
  const [apiResponse, setApiResponse] = useState(null);
  const [metaData, setMetaData] = useState();
  const navigate = useNavigate();
  const [billingDetails, setBillingDetails] = useState({
    firstName: metaData?.billing_firstName || "",
    lastName: metaData?.billing_lastName || "",
    email: metaData?.billing_emailAddress || "",
    phone: metaData?.billing_phoneNumber || "",
    address: metaData?.billing_address || "",
    country: metaData?.billing_country || "",
    state: metaData?.billing_state || "",
    city: metaData?.billing_city || "",
    pincode: metaData?.billing_pincode || "",
    governmentId: metaData?.billing_governmentId || "",
  });
  const [loadingRazorpay, setLoadingRazorpay] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [walletTrans, setWalletTrans] = useState(false);
  const [completingOrder, setCompletingOrder] = useState(false);
  const [transactionCancelled, setTransactionCancelled] = useState(false);
  const [rechargeButton, setRechargeButton] = useState(false);
  const [generatingOrderText, setGeneratingOrderText] = useState(false);
  const [balanceCheck, setBalanceCheck] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [updatingSubscription, setUpdateSubscription] = useState(false);
  const [availableCoupons, setAvailableCoupons] = useState();
  const [selectedCouponDetails, setSelectedCouponDetails] = useState();
  const [couponLoader, setCouponLoader] = useState();
  const [myCoupons, setMyCoupons] = useState();
  const [selectedCoupon, setSelectedCoupon] = useState();

  // fetch user Meta
  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await fetch(`${META_API}${userId?.user_data?.sub}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.replace(/"/g, "")}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setMetaData(data.meta_data);
        } else {
          throw new Error("Failed to fetch metadata");
        }
      } catch (error) {
        console.error("Error fetching metadata:", error);
      }
    };

    fetchMetadata();
  }, []);

  // setting billing details
  useEffect(() => {
    if (metaData) {
      setBillingDetails({
        firstName: metaData?.billing_firstName || "",
        lastName: metaData?.billing_lastName || "",
        email: metaData?.billing_emailAddress || "",
        phone: metaData?.billing_phoneNumber || "",
        address: metaData?.billing_address || "",
        country: metaData?.billing_country || "",
        state: metaData?.billing_state || "",
        city: metaData?.billing_city || "",
        pincode: metaData?.billing_pincode || "",
        governmentId: metaData?.billing_governmentId || "",
      });
    }
  }, [metaData]);

  // Single user course
  const fetchCourseData = async () => {
    try {
      const courseApiUrl = `https://dev.guruatmananda.org/wp-json/bts/v1/single_user_course/${pslugGlobal.get(
        { noproxy: true }
      )}/${userId?.user_data?.sub}`;

      const response = await fetch(courseApiUrl);

      if (response.ok) {
        const data = await response.json();
        setCourseData(data);
        const matchingPackage = data.packages.find(
          (pkg) => pkg.id == spidGlobal.get({ noproxy: true })
        );

        if (matchingPackage) {
          setMatchingPackage(matchingPackage);
        }
      } else {
        navigate(-2);
        console.error("Failed to fetch course data");
      }
    } catch (error) {
      navigate(-2);

      console.error("Error fetching course data:", error);
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, []);
  //  fetch wallet balance
  const fetchWalletBalance = async () => {
    try {
      const apiUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/wallet/balance?email=${userId?.user_data?.user_email}`;

      const username = "ck_e07619a34325d4367c758704b04844c734ecc86d";
      const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";

      const base64Credentials = btoa(`${username}:${password}`);

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setWalletBalance(data.balance);
      } else {
        console.error("Failed to fetch wallet balance");
      }
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  useEffect(() => {
    fetchWalletBalance();
  }, [token]);

  const handleBillingDetailsChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails({ ...billingDetails, [name]: value });
  };

  // // single coupon details
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const username = "ck_e07619a34325d4367c758704b04844c734ecc86d";
  //     const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
  //     const apiEndpoint = "https://dev.guruatmananda.org/wp-json/wc/v3/coupons";

  //     const base64Credentials = btoa(`${username}:${password}`);
  //     const headers = new Headers({
  //       Authorization: `Basic ${base64Credentials}`,
  //       "Content-Type": "application/json",
  //     });

  //     try {
  //       const response = await fetch(apiEndpoint, { headers });
  //       if (response.ok) {
  //         const data = await response.json();
  //         setAvailableCoupons(data);
  //       } else {
  //         console.error("Failed to fetch data from API:", response.statusText);
  //       }
  //     } catch (error) {
  //       console.error("Error during API request:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  // // all coupons according to user
  // useEffect(() => {
  //   const fetchCoupons = async () => {
  //     try {
  //       const apiUrl = `https://dev.guruatmananda.org/wp-json/myapi/v1/available-coupons/?user_id=${userId?.user_data?.sub}&product_id=${courseData?.linked_product}`;

  //       const response = await fetch(apiUrl, {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }

  //       const data = await response.json();
  //       setMyCoupons(data);
  //     } catch (error) {
  //       console.error("Error fetching coupons:", error.message);
  //     }
  //   };

  //   fetchCoupons();
  // }, [token, userId]);

  // const couponCodes = availableCoupons?.map((coupon) => coupon?.code);

  // let productCoupons = availableCoupons?.filter((coupon) =>
  //   coupon.product_ids.includes(courseData?.linked_product)
  // );
  // const apiUrlCoupon = "https://dev.guruatmananda.org/wp-json/myplugin/v1/check-coupon-usage/";

  // async function checkCouponUsage(user_id, coupon_code) {
  //   const url = `${apiUrlCoupon}?user_id=${user_id}&coupon_code=${coupon_code}`;

  //   const response = await fetch(url, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       // Add other headers if needed
  //     },
  //   });

  //   if (response.ok) {
  //     const data = await response.json();
  //     // console.log(`Coupon ${coupon_code} usage response:`, data?.limit_reached);
  //   } else {
  //     console.error(`Error checking coupon ${coupon_code} usage. Status: ${response.status}`);
  //   }
  // }

  // // Iterate through coupon codes and make API requests
  // availableCoupons?.forEach((coupon) => {
  //   checkCouponUsage(userId?.user_data?.sub, coupon.code);
  // });

  // const CouponList = () => {
  //   const handleCardClick = (coupon) => {
  //     setSelectedCoupon((prevSelectedCoupon) => {
  //       if (prevSelectedCoupon === coupon.id) {
  //         // If the same coupon is clicked again, unselect it
  //         return null;
  //       } else {
  //         // Otherwise, select the clicked coupon
  //         return coupon.id;
  //       }
  //     });
  //   };

  //   return (
  //     <MDBox
  //       display="flex"
  //       justifyContent="center"
  //       alignItems="left"
  //       flexDirection="column"
  //       paddingBottom="15px"
  //       style={{ marginTop: "10px" }}
  //     >
  //       {productCoupons?.length > 0 && (
  //         <MDTypography variant="h6" gutterBottom>
  //           Apply Coupon
  //         </MDTypography>
  //       )}

  //       {productCoupons?.map((coupon, index) => (
  //         <label
  //           key={index}
  //           style={{
  //             display: "flex",
  //             alignItems: "center",
  //             padding: "5px",
  //             border: selectedCoupon === coupon.id ? " 2px solid #1A73E8" : " 1px solid #ccc",
  //             background: selectedCoupon === coupon.id ? "#1A73E8" : "#fff",
  //             borderRadius: "10px",
  //             margin: "10px",
  //             cursor: "pointer",
  //           }}
  //           onClick={() => handleCardClick(coupon)}
  //         >
  //           <Checkbox
  //             checked={selectedCoupon === coupon.id}
  //             style={{
  //               marginRight: "10px",
  //               color: selectedCoupon === coupon.id ? "#fff" : "#000",
  //             }}
  //             icon={
  //               <span
  //                 style={{
  //                   borderRadius: "50%",
  //                   background: "#fff",
  //                   border: `2px solid ${selectedCoupon === coupon.id ? "#000" : "#ccc"}`,
  //                   width: 20,
  //                   height: 20,
  //                   display: "flex",
  //                   alignItems: "center",
  //                   justifyContent: "center",
  //                 }}
  //               />
  //             }
  //             checkedIcon={
  //               <span
  //                 style={{
  //                   borderRadius: "50%",
  //                   background: "#1A73E8",
  //                   width: 20,
  //                   height: 20,
  //                   display: "flex",
  //                   alignItems: "center",
  //                   justifyContent: "center",
  //                 }}
  //               >
  //                 ✓
  //               </span>
  //             }
  //           />
  //           <MDTypography
  //             variant="body1"
  //             style={{
  //               margin: "5px",
  //               fontFamily: "Nunito, sans-serif",
  //               fontWeight: 700,
  //               fontSize: "16px",
  //               color: selectedCoupon === coupon.id ? "#fff" : "#000",
  //             }}
  //           >
  //             {coupon.code} - Flat {coupon.amount}
  //             {coupon.discount_type === "percent" ? "%" : "₹"} OFF
  //           </MDTypography>
  //         </label>
  //       ))}
  //     </MDBox>
  //   );
  // };
  // useEffect(() => {
  //   const fetchCouponData = async () => {
  //     setCouponLoader(true);
  //     const apiUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/coupons/${selectedCoupon}`;
  //     const username = "ck_e07619a34325d4367c758704b04844c734ecc86d";
  //     const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";

  //     // Encode username and password for basic authentication
  //     const base64Credentials = btoa(`${username}:${password}`);

  //     try {
  //       const response = await fetch(apiUrl, {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Basic ${base64Credentials}`,
  //           "Content-Type": "application/json",
  //         },
  //       });

  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //         setCouponLoader(false);
  //       }

  //       const data = await response.json();
  //       setSelectedCouponDetails(data);
  //       // Process the data as needed
  //       setCouponLoader(false);
  //     } catch (error) {
  //       setSelectedCouponDetails("");
  //       setCouponLoader(false);
  //       console.error("Error fetching coupon data:", error.message);
  //     }
  //   };

  //   fetchCouponData();
  // }, [selectedCoupon]);

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  // async function checkCouponUsage(user_id, coupon_code) {
  //   const apiUrl = "https://dev.guruatmananda.org/wp-json/myplugin/v1/check-coupon-usage/";

  //   const url = `${apiUrl}?user_id=&coupon_code=${coupon_code}`;

  //   const response = await fetch(url, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       // Add other headers if needed
  //     },
  //   });

  //   if (response.ok) {
  //     const data = await response.json();
  //     // console.log(`Coupon ${coupon_code} usage response:`, data?.limit_reached);
  //   } else {
  //     console.error(`Error checking coupon ${coupon_code} usage. Status: ${response.status}`);
  //   }
  // }

  // // Replace '1' with the actual user ID you want to check for

  // // Iterate through coupon codes and make API requests
  // availableCoupons?.forEach((coupon) => {
  //   checkCouponUsage(userId, coupon.code);
  // });
  // let discount = selectedCouponDetails
  //   ? selectedCouponDetails?.discount_type === "percent"
  //     ? !isNaN(courseData?.price) && !isNaN(selectedCouponDetails?.amount)
  //       ? Math.min(parseFloat(courseData?.price), parseFloat(selectedCouponDetails?.amount))
  //       : 0
  //     : !isNaN(courseData?.price) && !isNaN(selectedCouponDetails?.amount)
  //     ? Math.min(parseFloat(courseData?.price), parseFloat(selectedCouponDetails?.amount))
  //     : 0
  //   : 0;

  // let total = selectedCouponDetails
  //   ? selectedCouponDetails?.discount_type === "percent"
  //     ? !isNaN(courseData?.price) && !isNaN(selectedCouponDetails?.amount)
  //       ? Math.max(0, parseFloat(courseData?.price) - parseFloat(selectedCouponDetails?.amount))
  //       : parseFloat(courseData?.price)
  //     : !isNaN(courseData?.price) && !isNaN(selectedCouponDetails?.amount)
  //     ? Math.max(0, parseFloat(courseData?.price) - parseFloat(selectedCouponDetails?.amount))
  //     : parseFloat(courseData?.price)
  //   : parseFloat(courseData?.price);

  /* Loading script for Razorpay*/
  function loadScript(src) {
    setLoadingRazorpay(true);
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        setLoadingRazorpay(false);
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  useEffect(() => {
    const fetchData = async () => {
      const url = "https://dev.guruatmananda.org//wp-json/myplugin/v1/create_razorpay_order/";
      const username = "rzp_live_BEu8ZlChbYTdWW";
      const password = "OxFhXEVWMWOBwfDdpCcy67wD";
      const razorPayAuth = btoa(`${username}:${password}`);
      const token = localStorage.getItem("token");
      const requestBody = {
        amount: matchingPackage?.price * 100,
        // amount: 100,
        currency: "INR",
        receipt: "receipt#1",
        notes: {
          key: "value",
        },
      };

      const requestBodyJSON = JSON.stringify(requestBody);

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: requestBodyJSON,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        setApiResponse(responseData);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [matchingPackage]);

  const handleDonateNow = async () => {
    // Basic validation
    if (
      !billingDetails.firstName ||
      !billingDetails.lastName ||
      !billingDetails.email ||
      !billingDetails.phone ||
      !billingDetails.governmentId ||
      !billingDetails.address ||
      !billingDetails.country ||
      !billingDetails.state ||
      !billingDetails.city ||
      !billingDetails.pincode
    ) {
      alert("Please fill in all the required fields.");
      return;
    }

    if (paymentMethod === "wallet") {
      if (walletBalance === null || walletBalance === "Loading") {
        console.log("Wallet balance is loading or unavailable.");
      } else if (parseFloat(walletBalance) >= parseFloat(matchingPackage?.price)) {
        setLoadingOrder(true);

        const orderData = {
          customer_id: userId?.user_data?.sub,
          billing: {
            first_name: billingDetails.firstName,
            last_name: billingDetails.lastName,
            email: billingDetails.email,
            phone: billingDetails.phone,
            address_1: billingDetails.address,
            country: billingDetails.country,
            state: billingDetails.state,
            city: billingDetails.city,
            postcode: billingDetails.pincode,
          },
          line_items: [
            {
              product_id: matchingPackage?.id,
              quantity: 1,
            },
          ],
          customer_note: "Please deliver before 5 PM",
        };

        const apiUrl = "https://dev.guruatmananda.org/wp-json/wc/v3/orders";
        const consumerKey = "ck_e07619a34325d4367c758704b04844c734ecc86d";
        const consumerSecret = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
        const authToken = btoa(`${consumerKey}:${consumerSecret}`);

        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${authToken}`,
            },
            body: JSON.stringify(orderData),
          });

          if (response.ok) {
            const responseData = await response.json();
            setLoadingOrder(false);
            setWalletTrans(true);
            // After a successful order, hit the wallet API
            const walletApiUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/wallet?email=${userId?.user_data.user_email}&type=debit&amount=${matchingPackage?.price}`;
            const walletAuthToken = btoa(`${consumerKey}:${consumerSecret}`);
            const walletResponse = await fetch(walletApiUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${walletAuthToken}`,
              },
            });
            if (walletResponse.ok) {
              // Wallet API call successful
              const walletData = await walletResponse.json();
              // localStorage.setItem("orderId", responseData.id);
              orderIdGlobal.set(responseData.id);
              setWalletTrans(false);
              setLoadingOrder(true);
              setCompletingOrder(true);
              // setSuccessMessage("Initiating Transaction....");
              // setShowSuccessModal(true);
              // Update the order with Wallet data
              try {
                const orderUpdateUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/orders/${responseData.id}`;
                const authToken = btoa(`${consumerKey}:${consumerSecret}`);
                const walletTransactionId = walletData.id;
                const orderUpdateData = {
                  // parent_id: responseData.id,
                  // transaction_id: walletTransactionId,
                  // payment_method: "wallet",
                  // payment_method_title: "Wallet",
                  set_paid: true,
                  status: "completed",
                };

                const orderUpdateResponse = await fetch(orderUpdateUrl, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Basic ${authToken}`,
                  },
                  body: JSON.stringify(orderUpdateData),
                });

                if (orderUpdateResponse.ok) {
                  // setSuccessMessage("Generating subscription...");
                  // setShowSuccessModal(true);
                  setLoadingOrder(false);
                  setCompletingOrder(false);
                  setSubscription(true);

                  //   After a successful order update, hit the subscription API
                  try {
                    const username = "ck_e07619a34325d4367c758704b04844c734ecc86d";
                    const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
                    const basicAuth = btoa(`${username}:${password}`);

                    const subscriptionsApi = `https://dev.guruatmananda.org/wp-json/wc/v3/subscriptions?customer=${userId?.user_data.sub}`;
                    // Define the data you want to send to the subscription API
                    const subscriptionData =
                      courseData.batch_status === "yes"
                        ? {
                            customer_id: userId?.user_data?.sub,
                            billing_period: matchingPackage?.billing_period,
                            billing_interval: matchingPackage?.billing_interval,
                            parent_id: responseData?.id,
                            line_items: [
                              {
                                product_id: matchingPackage?.id,
                                quantity: 1,
                              },
                            ],
                          }
                        : {
                            customer_id: userId?.user_data?.sub,
                            billing_period: matchingPackage?.billing_period,
                            billing_interval: matchingPackage?.billing_interval,
                            start_date: matchingPackage?.start_date,
                            next_payment_date:
                              matchingPackage?.next_payment_date !== matchingPackage?.end_date
                                ? matchingPackage?.next_payment_date
                                : null,
                            end_date: matchingPackage?.end_date,
                            status: "active",
                            set_paid: true,
                            parent_id: responseData?.id,
                            line_items: [
                              {
                                product_id: matchingPackage?.id,
                                quantity: 1,
                              },
                            ],
                          };

                    const subscriptionResponse = await fetch(subscriptionsApi, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Basic ${basicAuth}`,
                      },
                      body: JSON.stringify(subscriptionData),
                    });

                    if (subscriptionResponse.ok) {
                      // Subscription API call successful
                      const subscriptionSuccess = await subscriptionResponse.json();
                      setUpdateSubscription(true);
                      // You can handle the response data here
                      //   console.log("Subscription API response:", subscriptionSuccess);

                      // Update user metadata, if needed
                      const metaData = {
                        user_id: userId?.user_data.sub,
                        data: [
                          {
                            meta_key: `billing_period_${courseData?.linked_product}`,
                            meta_value: matchingPackage?.billing_period,
                          },
                          {
                            meta_key: `billing_interval_${courseData?.linked_product}`,
                            meta_value: matchingPackage?.billing_interval,
                          },
                          {
                            meta_key: `subscription_id_${courseData?.linked_product}`,
                            meta_value: subscriptionSuccess?.id,
                          },
                          {
                            meta_key: `billing_length_${courseData?.linked_product}`,
                            meta_value: matchingPackage?.billing_length,
                          },
                          {
                            meta_key: `package_id_${courseData?.linked_product}`,
                            meta_value: matchingPackage?.id,
                          },
                        ],
                      };
                      const metaAPI = `https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/update`;
                      const token = localStorage.getItem("token");

                      const metaResponse = await fetch(metaAPI, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(metaData),
                      });

                      if (metaResponse.ok) {
                        // console.log("User metadata updated successfully");
                        // setSuccessMessage("Subscription generated Successfully!");
                        // setShowSuccessModal(true);
                        setSubscription(false);
                        setUpdateSubscription(false);
                        navigate("/order-details");
                      } else {
                        const metaErrorData = await metaResponse.json();
                        console.error("Error updating user metadata:", metaErrorData);
                      }
                    } else {
                      // Handle error response from the Subscription API
                      console.error(
                        "Error hitting subscription API:",
                        await subscriptionResponse.json()
                      );
                      setSuccessMessage("error generating subscription. Contact support");
                      setShowSuccessModal(true);
                    }
                  } catch (error) {
                    console.error("Error hitting subscription API", error);
                  }
                } else {
                  const orderUpdateErrorData = await orderUpdateResponse.json();
                  console.error("Error updating order:", orderUpdateErrorData);
                  setSuccessMessage("Order update failed. Contact support");
                  setShowSuccessModal(true);
                }
              } catch (error) {
                console.error("Error updating order:", error);
                // alert("An error occurred while processing your request. Please try again later.");
                setSuccessMessage(
                  "An error occurred while processing your request. Please try again later."
                );
                setShowSuccessModal(true);
              }
            } else {
              // Handle error response from the Wallet API
              console.error("Error hitting wallet API:", await walletResponse.json());
              setSuccessMessage("Error hitting wallet API. Contact Support");
              setShowSuccessModal(true);
            }
          } else {
            // Handle error response from the API
            const errorData = await response.json();
            console.error("Error creating order:", errorData);
            setSuccessMessage("Order creation failed. Please try again later.");
            setShowSuccessModal(true);
          }
        } catch (error) {
          console.error("Error creating order:", error);
          //   alert("An error occurred while processing your request. Please try again later.");
          setSuccessMessage(
            "An error occurred while processing your request. Please try again later."
          );
          setShowSuccessModal(true);
        }
      } else {
        alert("Insufficient Wallet Balance. Please choose another payment method.");
        setSuccessMessage("Insufficient Wallet Balance. Please choose another payment method.");
        setShowSuccessModal(true);
      }
    } else {
      setLoadingOrder(true);
      setRechargeButton(true);
      const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        setRechargeButton(false);
        return;
      }
      const orderData = {
        customer_id: userId?.user_data?.sub,
        billing: {
          first_name: billingDetails.firstName,
          last_name: billingDetails.lastName,
          email: billingDetails.email,
          phone: billingDetails.phone,
          address_1: billingDetails.address,
          country: billingDetails.country,
          state: billingDetails.state,
          city: billingDetails.city,
          postcode: billingDetails.pincode,
        },
        line_items: [
          {
            product_id: matchingPackage?.id,
            quantity: 1,
          },
        ],
        // Add other relevant data
      };
      const apiUrl = "https://dev.guruatmananda.org/wp-json/wc/v3/orders";
      const consumerKey = "ck_e07619a34325d4367c758704b04844c734ecc86d";
      const consumerSecret = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";

      const authToken = btoa(`${consumerKey}:${consumerSecret}`);
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${authToken}`,
          },
          body: JSON.stringify(orderData),
        });

        if (response.ok) {
          const responseData = await response.json();
          setLoadingOrder(false);
          fetch("https://dev.guruatmananda.org/wp-json/wc/v3/payment_gateways/razorpay", {
            headers: {
              Authorization: `Basic ${authToken}`,
            },
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                setRechargeButton(false);
                setTransactionCancelled(true);
                throw new Error("Failed to fetch API: " + response.status);
              }
            })
            .then((data) => {
              const opt = {
                id: responseData.id,
                order_id: apiResponse.id,
                amount: matchingPackage?.price * 100,
                // amount: 100,
                handler: async (response) => {
                  if (response.razorpay_payment_id) {
                    setLoadingOrder(true);
                    setCompletingOrder(true);
                    var paymentId = response.razorpay_payment_id;
                    const data = {
                      orderCreationId: responseData.id,
                      razorpayPaymentId: response.razorpay_payment_id,
                      razorpayOrderId: response.razorpay_order_id,
                      razorpaySignature: response.razorpay_signature,
                      payment_method: "razorpay",
                      payment_method_title: "Razorpay",
                      set_paid: true,
                      status: "completed",
                    };

                    const username = "ck_e07619a34325d4367c758704b04844c734ecc86d";
                    const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
                    const basicAuth = btoa(`${username}:${password}`); // Base64 encoding
                    orderIdGlobal.set(responseData.id);

                    try {
                      const apiUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/orders/${responseData.id}`;
                      const response = await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Basic ${basicAuth}`,
                        },
                        body: JSON.stringify(data),
                      });

                      if (response.ok) {
                        setLoadingOrder(false);
                        setCompletingOrder(false);
                        setSubscription(true);
                        const username = "ck_e07619a34325d4367c758704b04844c734ecc86d";
                        const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
                        const basicAuth = btoa(`${username}:${password}`);
                        const subscriptions = `https://dev.guruatmananda.org/wp-json/wc/v3/subscriptions?customer=${userId?.user_data.sub}`;
                        const subsData =
                          courseData.batch_status === "yes"
                            ? {
                                customer_id: userId?.user_data?.sub,
                                billing_period: matchingPackage?.billing_period,
                                billing_interval: matchingPackage?.billing_interval,
                                parent_id: responseData?.id,
                                line_items: [
                                  {
                                    product_id: matchingPackage?.id,
                                    quantity: 1,
                                  },
                                ],
                              }
                            : {
                                customer_id: userId?.user_data?.sub,
                                billing_period: matchingPackage?.billing_period,
                                billing_interval: matchingPackage?.billing_interval,
                                start_date: matchingPackage?.start_date,
                                next_payment_date:
                                  matchingPackage?.next_payment_date !== matchingPackage?.end_date
                                    ? matchingPackage?.next_payment_date
                                    : null,
                                end_date: matchingPackage?.end_date,
                                status: "active",
                                set_paid: true,
                                parent_id: responseData?.id,
                                line_items: [
                                  {
                                    product_id: matchingPackage?.id,
                                    quantity: 1,
                                  },
                                ],
                              };

                        const subscriptionResponse = await fetch(subscriptions, {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Basic ${basicAuth}`,
                          },
                          body: JSON.stringify(subsData),
                        });
                        // console.log("subscription hit");
                        if (subscriptionResponse.ok) {
                          setUpdateSubscription(true);
                          //   console.log("subscription success");
                          const subscriptionSuccess = await subscriptionResponse.json();
                          const metaData = {
                            user_id: userId?.user_data.sub,
                            data: [
                              {
                                meta_key: `billing_period_${courseData?.linked_product}`,
                                meta_value: matchingPackage?.billing_period,
                              },
                              {
                                meta_key: `billing_interval_${courseData?.linked_product}`,
                                meta_value: matchingPackage?.billing_interval,
                              },
                              {
                                meta_key: `subscription_id_${courseData?.linked_product}`,
                                meta_value: subscriptionSuccess?.id,
                              },
                              {
                                meta_key: `billing_length_${courseData?.linked_product}`,
                                meta_value: matchingPackage?.billing_length,
                              },
                              {
                                meta_key: `package_id_${courseData?.linked_product}`,
                                meta_value: matchingPackage?.id,
                              },
                            ],
                          };
                          const metaAPI = `https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/update`;
                          const token = localStorage.getItem("token");
                          await fetch(metaAPI, {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${token}`,
                            },
                            body: JSON.stringify(metaData),
                          });
                        }
                        setSubscription(false);
                        setUpdateSubscription(false);
                        setRechargeButton(false);
                        navigate("/order-details");
                      } else {
                        const errorData = await response.json();
                        setRechargeButton(false);
                        setTransactionCancelled(true);
                        console.error("Error while posting data to /payment/success:", errorData);
                      }
                    } catch (error) {
                      setRechargeButton(false);
                      setTransactionCancelled(true);
                      console.error("Error while posting data to /payment/success:", error);
                    }
                  }
                },
                currency: "INR",
                modal: {
                  ondismiss: function () {
                    setRechargeButton(false);
                    navigate(-2);
                  },
                },
                // order_id: "order_L0nS83FfCHaWqV",
                prefill: {
                  name: billingDetails.firstName,
                  email: billingDetails.email,
                  contact: billingDetails.phone,
                },
                notes: {
                  first_name: billingDetails.firstName,
                  last_name: billingDetails.lastName,
                  email: billingDetails.email,
                  phone: billingDetails.phone,
                  address_1: billingDetails.address,
                  country: billingDetails.country,
                  state: billingDetails.state,
                  city: billingDetails.city,
                  postcode: billingDetails.pincode,
                  mentorship_program: courseData?.title,
                  donors_governmentId: billingDetails.governmentId || "NA",
                },
                name: "Sri Siddha Sanmarga",
                key: data?.settings?.key_id?.value,
              };
              const razorpay = new window.Razorpay(opt);

              razorpay.on("payment.failed", (response) => {
                // console.log("Payment Failed here", response);
              });

              razorpay.on("payment.success", async (res) => {
                // console.log("Payment success", res);
                try {
                  // navigate("/order-details");
                } catch (error) {
                  console.error("Error handling payment success:", error);
                  // navigate("/success");
                }
              });
              razorpay.open();
            })
            .catch((error) => {
              setRechargeButton(false);
              setTransactionCancelled(true);
              console.error("API error:", error);
            });
        } else {
          setRechargeButton(false);
          setTransactionCancelled(true);
          console.error("Failed to create order:", response.status, response.statusText);
        }
      } catch (error) {
        setRechargeButton(false);
        setTransactionCancelled(true);
        console.error("Error creating order:", error);
      }
    }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    setSuccessMessage("");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container mt={3} spacing={3}>
        <Grid item xs={12} md={7.2} lg={7.2}>
          <MDTypography variant="h3" fontWeight="bold" mb={3}>
            Billing Info
          </MDTypography>
          <Card id="basic-info" pb={3} sx={{ overflow: "visible" }}>
            <MDBox p={2}>
              <form>
                <MDBox component="form" pb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="First Name"
                        name="firstName"
                        value={billingDetails?.firstName}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Last Name"
                        name="lastName"
                        value={billingDetails?.lastName}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Email"
                        name="email"
                        value={billingDetails?.email}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Phone"
                        name="phone"
                        value={billingDetails?.phone}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Government ID"
                        name="governmentId"
                        value={billingDetails?.governmentId}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Address"
                        name="address"
                        value={billingDetails?.address}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Country"
                        name="country"
                        value={billingDetails?.country}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="State"
                        name="state"
                        value={billingDetails?.state}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="City"
                        name="city"
                        value={billingDetails?.city}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="PIN Code"
                        name="pincode"
                        value={billingDetails?.pincode}
                        onChange={handleBillingDetailsChange}
                        required
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </form>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={4.8} lg={4.8}>
          <MDBox mt={8}>
            <img
              src={courseData?.featured_image}
              alt="Program"
              style={{
                maxWidth: "100%",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <MDTypography variant="h6">Plan</MDTypography>
              <MDTypography style={{ textAlign: "right" }}>{matchingPackage?.name}</MDTypography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <MDTypography variant="h6">Donation</MDTypography>
              <MDTypography textAlign="right">₹ {matchingPackage?.price}</MDTypography>
            </div>
            {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <MDTypography variant="h6">Coupon Discount</MDTypography>
              <MDTypography textAlign="right">₹ {discount}</MDTypography>
            </div> */}
            <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
              {/* <MDTypography variant="h6">Donation Method</MDTypography> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={paymentMethod === "wallet"}
                    onChange={handlePaymentMethodChange}
                    value="wallet"
                  />
                }
                label={`Wallet (Balance: ₹ ${walletBalance || "Loading..."})`}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={paymentMethod === "online"}
                    onChange={handlePaymentMethodChange}
                    value="online"
                  />
                }
                label="Online(Credit/Debit/UPI)"
              />
            </div>
            {/* <CouponList /> */}
            <MDButton
              variant="contained"
              onClick={handleDonateNow}
              color="info"
              style={{
                width: "100%",
                //   background: "linear-gradient(310deg, #7928CA, #FF0080)",
                //   color: "#FFFFFF",
              }}
            >
              Proceed to checkout
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>

      {/* Success Modal */}
      <Dialog open={showSuccessModal} onClose={handleCloseSuccessModal}>
        <DialogContent>
          {/* <MDTypography variant="h5" fontWeight="bold">
            Success!
          </MDTypography> */}
          <MDTypography>{successMessage}</MDTypography>
        </DialogContent>
      </Dialog>
      <Dialog open={balanceCheck} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#1A73E8" }} />
          <p style={{ marginTop: "16px" }}>Balance is still loading or unavailable</p>
        </div>
      </Dialog>
      <Dialog open={loadingOrder} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#1A73E8" }} />
          <p style={{ marginTop: "16px" }}>
            {completingOrder ? "Completing order..." : "Generating order..."}
          </p>
        </div>
      </Dialog>
      <Dialog open={walletTrans} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#1A73E8" }} />
          <p style={{ marginTop: "16px" }}>Processing wallet transaction</p>
        </div>
      </Dialog>
      <Dialog open={subscription} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#1A73E8" }} />
          <p style={{ marginTop: "16px" }}>
            {updatingSubscription ? "Updating Subscription..." : "Processing wallet transaction"}
          </p>
        </div>
      </Dialog>
      <Dialog open={transactionCancelled} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <p style={{ marginTop: "16px" }}>
            You may have cancelled the payment or there was a delay in response from the UPI app.
          </p>
        </div>
      </Dialog>
      <Dialog open={loadingRazorpay} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#1A73E8" }} />
          <p style={{ marginTop: "16px" }}>Loading Razorpay...</p>
        </div>
      </Dialog>
      <Dialog open={rechargeButton} aria-labelledby="loading-dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <CircularProgress style={{ color: "#1A73E8" }} />
          <p style={{ marginTop: "16px" }}>Processing...</p>
        </div>
      </Dialog>
    </DashboardLayout>
  );
}

export default CheckoutPage;
