/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";

// Images
import orderImage from "assets/images/product-12.jpg";

function OrderInfo({ orderInfo }) {
  // console.log("order", orderInfo);
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} md={6}>
        <MDBox display="flex" alignItems="center">
          <MDBox lineHeight={1}>
            <MDTypography variant="h6" fontWeight="medium">
              {orderInfo.line_items[0]?.name || "Product"}
            </MDTypography>{" "}
          </MDBox>
          {/* <MDBox mr={2}>
            <MDAvatar
              size="xxl"
              src={
                orderInfo.line_items[0].image.src ||
                "https://sukshmadhrashta.guruatmananda.org/wp-content/uploads/2022/12/Teal-Modern-Business-YouTube-Thumbnail-13.jpg"
              }
              alt="Product"
            />
          </MDBox> */}
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
        {/* <MDButton variant="gradient" color="dark" size="small">
          contact us
        </MDButton> */}
        {/* <MDBox mr={2}>
            <MDAvatar
              size="xxl"
              src={
                orderInfo.line_items[0].image.src ||
                "https://sukshmadhrashta.guruatmananda.org/wp-content/uploads/2022/12/Teal-Modern-Business-YouTube-Thumbnail-13.jpg"
              }
              alt="Product"
            />
          </MDBox> */}
        <MDBox mt={0.5}>
          <MDBadge
            variant="gradient"
            color="success"
            size="xs"
            badgeContent="Completed"
            container
          />
        </MDBox>
      </Grid>
    </Grid>
  );
}
OrderInfo.propTypes = {
  orderInfo: PropTypes.object.isRequired,
};

export default OrderInfo;
