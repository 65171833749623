import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "./assets/theme";
import themeRTL from "./assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "./assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "./routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "./context";

import RedirectLoginPage from "components/Redirection";
import Class from "layouts/courses/classroom/class";
import Lesson from "layouts/courses/classroom/lecture";
import Analytics from "layouts/dashboards/analytics";
import brand from "./assets/images/SD_LOGO4.png";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

export default function App() {
  const { userGlobal } = useHookstate(globalStore);
  // const userGlobalId = userGlobal.get({noproxy:true});
  const userId = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    // transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const [userData, setUserData] = useState();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  useEffect(() => {
    // Get the authentication token from local storage
    if (userId && token) {
      const apiUrl = `https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/fetch/${userId?.user_data?.sub}`;

      fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setUserData(data);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  }, []);
  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });
  // const validate = userData?.meta_data?.user_token;
  // if (validate !== undefined && validate !== token) {
  //   localStorage.clear();
  // }
  // console.log(validate);
  // useEffect(() => {
  //   const fetchValidity = async () => {
  //     if (!(userId && token)) return;

  //     try {
  //       const response = await fetch(
  //         "https://dev.guruatmananda.org//wp-json/custom-api/v1/check-and-validate-token?website_id=3",
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         }
  //       );

  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }

  //       const result = await response.json();
  //       if (result?.status === "invalid") {
  //         // window.location.href("/login");
  //         localStorage.removeItem("token");
  //         localStorage.removeItem("user");
  //         localStorage.clear();

  //         navigate("/login", { replace: true });
  //         // Handle error in a user-friendly way
  //       }
  //     } catch (error) {
  //       console.error("Error checking token validity:", error);
  //       // Handle error in a user-friendly way
  //     }
  //   };

  //   if (userId && token) {
  //     fetchValidity();
  //   }
  // }, [navigate]);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  // Redirect to the login page if token is not present
  if (
    !token &&
    pathname !== "/authentication/sign-in/illustration" &&
    pathname !== "/sign-up" &&
    pathname !== "/reset-password"
  ) {
    return <Navigate to="/authentication/sign-in/illustration" replace={true} />;
  }

  if (token) {
    if (
      pathname === "/authentication/sign-in/illustration" ||
      pathname === "/sign-up" ||
      pathname === "/reset-password"
    ) {
      return <Navigate to="/dashboards/programs" replace={true} />;
    }
  }

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brand={brand}
              brandName="Sukshma Dhrashta"
              page=""
              pageName="Enrolled Courses"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            {/* <Configurator />
            {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboards/programs" replace={true} />} />
          <Route exact path="/courses/classroom/class" component={Class} />
          <Route exact path="/authentication/sign-in/illustration" component={SignInIllustration} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Sukshma Dhrashta"
            page=""
            pageName="Enrolled Courses"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator />
          {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboards/programs" replace={true} />} />
        <Route exact path="/courses/classroom/class" component={Class} />
        <Route exact path="/courses/classroom/lecture" component={Lesson} />
      </Routes>
    </ThemeProvider>
  );
}
