import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import Loader from "components/Loader/Loader";
import { dummyData } from "./dummyData";
import ComingImage from "./ComingSoon2.png";

function Videos() {
  const [socialMedia, setSocialMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://dev.guruatmananda.org/wp-json/custom/v1/get-social-media-content")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSocialMedia(data || []);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  });
  const handleClick = (link) => {
    window.open(link, "_blank");
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            {socialMedia?.length > 0 ? (
              socialMedia?.map((item, index) => (
                <Grid item xs={12} md={6} lg={3} key={item?.ID}>
                  <MDBox mt={3}>
                    <SimpleBlogCard
                      image={item?.featured_image_url}
                      title={
                        <MDTypography variant="h6" fontWeight="bold" fontSize={18}>
                          {item?.title}
                        </MDTypography>
                      }
                      description={item?.content}
                      action={{
                        type: "internal",
                        color: "info",
                        label: (
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => handleClick(item?.post_video_link)}
                          >
                            Open Link
                          </span>
                        ),
                      }}
                    />
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <MDBox mt={3}>
                  <img src={ComingImage} style={{ height: "auto", width: "100%" }} />
                </MDBox>
              </Grid>
            )}
            {!dummyData && <Loader />}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Videos;
