import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./ToasterMessage.css"; // Import the CSS file for styling

function ToasterMessage({ message, duration, color, onDismiss }) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onDismiss();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onDismiss]);

  if (!visible) {
    return null; // Hide the toaster message when not visible
  }

  return (
    <div className={`toaster-message-container ${visible ? "visible" : ""}`}>
      <div className={`toaster-message ${color}`}>
        <div className="toaster-message-content">{message}</div>
      </div>
    </div>
  );
}

ToasterMessage.propTypes = {
  message: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default ToasterMessage;
