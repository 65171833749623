/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import { Grid } from "@mui/material";
import ComplexProjectCardClone from "examples/Cards/ProjectCards/ComplexProjectCard copy";
import { Value } from "sass";

/* eslint-disable react/prop-types */

function OrderList() {
  const [menu, setMenu] = useState(null);
  const [orders, setOrders] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    let period = "AM";

    if (hours > 12) {
      hours -= 12;
      period = "PM";
    }

    const formattedTime = `${hours}:${minutes} ${period}`;

    return `${day}/${month}/${year} ${formattedTime}`;
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const username = "ck_e07619a34325d4367c758704b04844c734ecc86d";
        const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
        const authString = `${username}:${password}`;
        const encodedAuth = base64Encode(authString);

        const url = `https://dev.guruatmananda.org/wp-json/wc/v3/orders?customer=${user?.user_data?.sub}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Basic ${encodedAuth}`, // Use Basic Authentication
          },
        });
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);
  let data = orders?.map((order) => parseFloat(order.number));

  const dataTableDataOrder = {
    columns: [
      { Header: "Order ID", accessor: "id", Cell: ({ value }) => <IdCell id={value} /> },
      {
        Header: "Course Name",
        accessor: "product",
        Cell: ({ value }) => {
          const [name, data] = value;

          return (
            <DefaultCell
              value={typeof value === "string" ? value : name}
              suffix={data?.suffix || false}
            />
          );
        },
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      { Header: "Price", accessor: "revenue", Cell: ({ value }) => <DefaultCell value={value} /> },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          let status;

          if (value === "completed") {
            status = <StatusCell icon="done" color="success" status="Completed" />;
          } else if (value === "processing") {
            status = <StatusCell icon="replay" color="warning" status="Processing" />;
          } else {
            status = <StatusCell icon="close" color="error" status="Canceled" />;
          }

          return status;
        },
      },
    ],

    rows:
      orders?.map((order) => ({
        id: `${order.id}`,
        date: formatDate(order.date_paid),
        status: order.status,
        product: order.line_items?.map((item) => item.name).join(", "),
        revenue: order.total,
      })) || [],
  };
  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );
  function base64Encode(input) {
    const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let output = "";
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output +=
        keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4);
    }

    return output;
  }

  return (
    <MDBox my={3}>
      <Card>
        <DataTable
          heading="Order List"
          table={dataTableDataOrder}
          entriesPerPage={true}
          canSearch
        />
      </Card>
    </MDBox>
  );
}

export default OrderList;
