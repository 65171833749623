export const dummyData = {
  data: [
    {
      link: "https://vedantastudents.com/wp-content/uploads/2018/11/01-Aparokshanubhuti-Volume-01.pdf",
      thumbnail: "https://srisiddhasanmarga.com/wp-content/uploads/2020/05/bg-21.png",
      title: "Video Title 1",
      id: 1,
    },
    {
      link: "https://sukshmadhrashta.guruatmananda.org/",
      thumbnail: "https://srisiddhasanmarga.com/wp-content/uploads/2020/05/bg-21.png",
      title: "Video Title 2",
      id: 2,
    },
    {
      link: "https://sukshmadhrashta.guruatmananda.org/testimonials/",
      thumbnail: "https://srisiddhasanmarga.com/wp-content/uploads/2020/05/bg-21.png",
      title: "Video Title 3",
      id: 3,
    },
    {
      link: "https://sukshmadhrashta.guruatmananda.org/videos/",
      thumbnail: "https://srisiddhasanmarga.com/wp-content/uploads/2020/05/bg-21.png",
      title: "Video Title 4",
      id: 4,
    },
    {
      link: "https://www.youtube.com/watch?v=anNPW8_ii0k",
      thumbnail: "https://srisiddhasanmarga.com/wp-content/uploads/2020/05/bg-21.png",
      title: "Video Title 5",
      id: 5,
    },
    {
      link: "https://www.youtube.com/watch?v=TGI6DiJhry4",
      thumbnail: "https://srisiddhasanmarga.com/wp-content/uploads/2020/05/bg-21.png",
      title: "Video Title 6",
      id: 6,
      clicked: false,
    },
    {
      link: "https://www.youtube.com/watch?v=e8p6a-0JBhk",
      thumbnail: "https://srisiddhasanmarga.com/wp-content/uploads/2020/05/bg-21.png",
      title: "Video Title 7",
      id: 7,
    },
    {
      link: "https://www.youtube.com/watch?v=u97PgRf7AP8",
      thumbnail: "https://srisiddhasanmarga.com/wp-content/uploads/2020/05/bg-21.png",
      title: "Video Title 8",
      id: 8,
      clicked: false,
    },
  ],
};
