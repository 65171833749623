import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ModifiedProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard copy";
import AllCertificateTracking from "layouts/ecommerce/orders/order-list copy 3";

function CertificateApplication() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [activeStatus, setActiveStatus] = useState("All");
  const [data, setData] = useState([]); // State to store API data
  const [approvalData, setApprovalData] = useState([]);
  const [uploadData, setUploadData] = useState([]);

  const handleStatusChange = (status) => {
    setActiveStatus(status);
  };

  useEffect(() => {
    // Fetch subscriptions data from the API using the token from localStorage
    const token = localStorage.getItem("token");
    const key = "ck_e07619a34325d4367c758704b04844c734ecc86d";
    const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";

    if (token) {
      fetch(
        "https://dev.guruatmananda.org/wp-json/custom/v1/users_meta_course/?pattern=course_completed_%",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include key and password in the Authorization header
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // Store the API data in the state
          setData(data);

          // Filter data based on status and store in different states
          const approvalData = data.filter((item) => item.status === "certificateApproved");
          const uploadData = data.filter((item) => item.status === "certificate_issued");
          setApprovalData(approvalData);
          setUploadData(uploadData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);
  const completed = data.filter((data) => data.status === "course_completed");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <div
          style={{
            overflowX: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={4}>
              <ModifiedProjectCard
                icon="sync"
                number={completed.length}
                description={
                  <span
                    style={{ fontSize: "20px", fontWeight: 600, color: "black", lineHeight: "1.5" }}
                  >
                    Course Completed
                  </span>
                }
                iconBackgroundColor="linear-gradient(310deg, #7928CA, #FF0080)"
                iconColor="white"
                numberColor="#851aa1"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <ModifiedProjectCard
                icon="sync"
                number={approvalData.length}
                description={
                  <span
                    style={{ fontSize: "20px", fontWeight: 600, color: "black", lineHeight: "1.5" }}
                  >
                    Certificate Applications
                  </span>
                }
                iconBackgroundColor="linear-gradient(310deg, #7928CA, #FF0080)"
                iconColor="white"
                numberColor="#0d7419"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <ModifiedProjectCard
                icon="sync"
                number={uploadData.length}
                description={
                  <span
                    style={{ fontSize: "20px", fontWeight: 600, color: "black", lineHeight: "1.5" }}
                  >
                    Certificates Issued
                  </span>
                }
                iconBackgroundColor="linear-gradient(310deg, #7928CA, #FF0080)"
                iconColor="white"
                numberColor="#740d0d"
              />
            </Grid>
          </Grid>
        </div>
      </MDBox>
      <MDBox my={3}>
        <div style={{ display: "flex" }}>
          {["All", "Approval", "Upload"].map((status, index) => (
            <MDButton
              key={index}
              variant="contained"
              style={{
                background:
                  activeStatus === status ? "linear-gradient(310deg, #7928CA, #FF0080)" : "",
                color: activeStatus === status ? "white" : "#344767",
                marginRight: "10px",
                marginLeft: "2px",
              }}
              onClick={() => handleStatusChange(status)}
              px={2}
            >
              {status}
            </MDButton>
          ))}
        </div>
      </MDBox>
      {activeStatus === "All" && <AllCertificateTracking data={completed} />}
      {activeStatus === "Approval" && <AllCertificateTracking data={approvalData} />}
      {activeStatus === "Upload" && <AllCertificateTracking data={uploadData} />}
    </DashboardLayout>
  );
}

export default CertificateApplication;
