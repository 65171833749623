import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import ProductInfo from "layouts/ecommerce/products/product-page/components/ProductInfo";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import CategoriesList from "examples/Lists/CategoriesList";
import { Icon } from "@mui/material";
import Header from "./components/Header/Header";
import Loader from "components/Loader/Loader";
import LoaderNew from "components/Loader/LoaderNew";
import { useNavigate } from "react-router-dom";
import Lesson from "../lecture";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

function Class() {
  const navigate = useNavigate();
  const { courseNameGlobal, lessonNameGlobal } = useHookstate(globalStore);
  const [topicData, setTopicData] = useState();
  const [check, setCheck] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedLessonTitle, setSelectedLessonTitle] = useState("");
  const courseIdGlobal = useHookstate(globalStore);
  let user = JSON.parse(localStorage.getItem("user"));
  const linkedGlobal = useHookstate(globalStore);
  const linkedProduct = linkedGlobal.linkedGlobal.get({ noproxy: true });
  const [metaData, setMetaData] = useState();
  const token = localStorage.getItem("token");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const subscriptionIdProp = `subscription_id_${linkedProduct}`;
  const [linkedsub, setLinkedSub] = useState("");

  const url = `https://dev.guruatmananda.org/wp-json/my-plugin/v1/courses_lesson/${courseIdGlobal.courseIdGlobal.get(
    { noproxy: true }
  )}/subscription`;
  const metaUrl = `https://dev.guruatmananda.org//wp-json/custom-user-meta/v1/fetch/${user?.user_data?.sub}`;

  useEffect(() => {
    fetchMetadata();
  }, [navigate]);

  useEffect(() => {
    fetchTopicData();
  }, []);

  async function fetchMetadata() {
    try {
      const response = await fetch(metaUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch metadata");
      }

      const data = await response.json();
      setMetaData(data.meta_data);
      const subscriptionIdPropValue = data.meta_data?.[subscriptionIdProp] || null;
      if (!subscriptionIdPropValue) {
        navigate("/");
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  async function fetchTopicData() {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch topic data");
      }

      const data = await response.json();
      setTopicData(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  // topics listing
  const topicLessonData = [];
  courseNameGlobal.set(topicData?.title);
  topicData?.course_content?.forEach((content) => {
    const topicData = content?.course_data?.map((courseData) => ({
      color: "light",
      icon: "play_circle",
      name: courseData?.title,
    }));
    topicLessonData.push(topicData);
  });
  useEffect(() => {
    if (topicData?.course_content?.length > 0) {
      const firstLessonTitle = topicData.course_content[0]?.course_data[0]?.title;
      if (firstLessonTitle) {
        localStorage.setItem("lessonName", firstLessonTitle);
      }
    }
  }, [topicData]);
  const handleClick = (name) => {
    localStorage.setItem("lessonName", name);
  };
  const handleAccessNow = async (id, linked, title) => {
    setLinkedSub(id);
    try {
      // Construct the URL with the 'id' parameter
      const accessUrl = `https://dev.guruatmananda.org/wp-json/my-plugin/v1/courses_lesson/${id}/subscription`;

      // Make the API call
      const response = await fetch(accessUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to access lesson: ${response.statusText}`);
      }

      // Handle the response data here (e.g., parse JSON, update state)
      const responseData = await response.json();
      setTopicData(responseData);

      // You can add your logic to handle the API response data here
    } catch (error) {
      console.error("Error accessing lesson:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  function NoMeditationCard() {
    return (
      <SimpleBlogCard
        title="No meditation available"
        description="Sorry, there are no meditations available at the moment."
        action={{ type: "internal", route: "/", label: "Go Back" }}
      />
    );
  }

  return linkedProduct ? (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : error ? (
        <div>Error: {error}</div>
      ) : topicData && topicData?.course_content?.length > 0 ? (
        <div>
          <Lesson linkedSub={linkedsub} />
          <MDBox mb={2}>
            <MDTypography variant="h4">All Meditations</MDTypography>
          </MDBox>
          <Grid container spacing={3}>
            {topicData?.course_content?.map((content, index) => (
              <Grid
                item
                xs={12}
                lg={6}
                key={index}
                mb={index === topicData.course_content.length - 1 ? 10 : 0}
              >
                <MDBox>
                  <CategoriesList
                    title={content?.topic}
                    categories={topicLessonData[index]}
                    handleClick={handleClick}
                  />
                </MDBox>
              </Grid>
            ))}
          </Grid>
          {topicData && topicData?.free_course.length > 0 && (
            <div>
              <MDBox my={5}>
                <MDTypography variant="h4">Linked Meditations</MDTypography>
              </MDBox>
              <Grid container spacing={3}>
                {topicData?.free_course?.map((course, index) => (
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    key={index}
                    mb={index === topicData.free_course.length - 1 ? 10 : 0}
                  >
                    <MDBox>
                      <SimpleBlogCard
                        image={course?.featured_image}
                        title={
                          <MDTypography variant="h6" fontWeight="bold" fontSize={18}>
                            {course?.title}
                          </MDTypography>
                        }
                        description=""
                        onClick={() =>
                          handleAccessNow(course?.id, course?.linked_product, course?.title)
                        }
                        action={{
                          type: "internal",
                          route: "#", // Update this route as needed
                          label: "Access Now",
                          color: "info",
                        }}
                      />
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </div>
      ) : (
        <NoMeditationCard />
      )}
      <Footer />
    </DashboardLayout>
  ) : (
    navigate("/dashboards/active-courses")
  );
}

export default Class;
