import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function DefaultInfoCard({ color, icon, title, description, value, action, onClick }) {
  return (
    <Card style={{ height: "200px" }}>
      <MDBox p={2} mx={3} display="flex" justifyContent="center">
        <MDBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor="info"
          color="white"
          width="4rem"
          height="4rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
        >
          <Icon fontSize="medium">{icon}</Icon>
        </MDBox>
      </MDBox>
      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        {description && (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            {description}
          </MDTypography>
        )}
        {description && !value ? null : <Divider />}
        {value && (
          <MDTypography
            variant="h5"
            fontWeight="medium"
            onClick={onClick}
            style={onClick ? { cursor: "pointer" } : {}}
          >
            {value}
          </MDTypography>
        )}
        {action && (
          <MDBox>
            <MDButton variant="contained" color={color} onClick={onClick}>
              {action}
            </MDButton>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

DefaultInfoCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
};

DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  action: PropTypes.string,
  onClick: PropTypes.func,
};

export default DefaultInfoCard;
