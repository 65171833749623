import React from "react";
import PropTypes from "prop-types";
import MDInput from "components/MDInput";

function FormField({ label, value, onChange, name, ...rest }) {
  const handleChange = (event) => {
    if (onChange) {
      onChange(name, event.target.value);
    }
  };

  return (
    <MDInput
      variant="standard"
      label={label}
      value={value}
      fullWidth
      InputLabelProps={{
        shrink: true,
        style: { color: "#7502A8", fontWeight: 700 },
      }}
      onChange={handleChange}
      {...rest}
    />
  );
}

FormField.defaultProps = {
  label: "",
  value: "",
};

FormField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
};

export default FormField;
