import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ModifiedProjectCard({
  icon,
  number,
  description,
  iconBackgroundColor,
  iconColor,
  numberColor,
}) {
  const iconStyle = {
    background: iconBackgroundColor,
    color: iconColor,
  };

  const numberStyle = {
    fontSize: "36px",
    fontWeight: "700",
    color: numberColor,
  };

  return (
    <Card style={{ height: "100px", overflow: "hidden" }}>
      <MDBox p={1}>
        <MDBox display="flex" alignItems="center">
          <MDBox ml={2} lineHeight={0} flexGrow={1} textAlign="left">
            <MDTypography variant="button" fontWeight="light" color="text">
              <div style={numberStyle}>{number}</div> {description}
            </MDTypography>
          </MDBox>
          <MDBox
            display="grid"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            shadow="md"
            borderRadius="lg"
            variant="gradient"
            style={iconStyle}
            mr={2}
          >
            <Icon fontSize="medium">{icon}</Icon>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

ModifiedProjectCard.propTypes = {
  icon: PropTypes.elementType.isRequired,
  number: PropTypes.node.isRequired, // You can change the type as needed (string, number, etc.)
  description: PropTypes.node.isRequired,
  iconBackgroundColor: PropTypes.string,
  iconColor: PropTypes.string,
  numberColor: PropTypes.string,
};

export default ModifiedProjectCard;
