import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ChatHeader = ({ selectedChat }) => {
  return (
    <HeaderContainer>
      <ProfilePic src={selectedChat.profilePic} alt={selectedChat.name} />
      <ChatInfo>
        <h3>{selectedChat.name}</h3>
        {/* Additional information, if needed */}
      </ChatInfo>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
`;

const ProfilePic = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const ChatInfo = styled.div`
  margin-left: 10px;
`;

ChatHeader.propTypes = {
  selectedChat: PropTypes.shape({
    profilePic: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    // Add additional properties here, if needed
  }).isRequired,
};
export default ChatHeader;
