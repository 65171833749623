import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import ProductInfo from "./components/ProductInfo";
import Loader from "components/Loader/Loader";
import fallbackImage from "assets/images/illustrations/TWS-image.jpg";
import RedirectLoginPage from "components/Redirection";
import AudioPlayerTest from "./components/AudioPlayerTest";
import { Grid, useMediaQuery } from "@mui/material";
import ReactPlayer from "react-player";
import MDTypography from "components/MDTypography";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline"; // Import the icon
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

function Lesson(linkedSub) {
  const [topicData, setTopicData] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  // const courseId = localStorage.getItem("courseId");
  const { courseIdGlobal, lessonNameGlobal } = useHookstate(globalStore);
  const lessonName = localStorage.getItem("lessonName");
  const [videoType, setVideoType] = useState(null);
  const [defaultContent, setDefaultContent] = useState(null);
  const url = `https://dev.guruatmananda.org/wp-json/my-plugin/v1/courses_lesson/${
    linkedSub.linkedSub !== "" ? linkedSub.linkedSub : courseIdGlobal.get()
  }/subscription`;
  const token = localStorage?.getItem("token");

  useEffect(() => {
    if (!courseIdGlobal.get({ noproxy: true }) || !token) {
      console.error("Course ID or token not available.");
      return;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTopicData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [courseIdGlobal.get({ noproxy: true }), token, url]);

  useEffect(() => {
    if (topicData?.course_content) {
      const apiUrls = topicData?.course_content
        ?.flatMap((content) =>
          content?.course_data?.filter((courseData) => courseData?.title === lessonName)
        )
        .map((courseData) => courseData?.api_url);

      topicData?.course_content
        ?.flatMap((content) =>
          content?.course_data?.filter((courseData) => courseData?.title === lessonName)
        )
        .forEach((courseData) => setVideoType(courseData));

      if (!lessonName && topicData?.course_content[0]?.course_data) {
        // If lessonName is not found, set the first available content as default
        setDefaultContent(topicData.course_content[0].course_data[0]);
      }

      apiUrls.forEach((source) => {
        fetch(source)
          .then((response) => response.json())
          .then((data) => {
            setImageUrl(data);
            generateBlobUrls(data);
          })
          .catch((error) => {
            console.error("API error:", error);
          });
      });
    }
  }, [topicData, lessonName]);

  const generateBlobUrls = (data) => {
    if (data?.video_url) {
      fetch(data?.video_url, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // console.log("Generated video Blob URL:", URL.createObjectURL(blob));
        })
        .catch((error) => {
          console.error("Error generating video Blob URL:", error);
        });
    }

    if (data?.audio_url) {
      fetch(data?.audio_url, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // console.log("Generated audio Blob URL:", URL.createObjectURL(blob));
        })
        .catch((error) => {
          console.error("Error generating audio Blob URL:", error);
        });
    }
  };

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  // console.log("Debugging - lessonName:", lessonName);
  // console.log("Debugging - topicData:", topicData);
  // console.log("Debugging - imageUrl:", imageUrl);
  // console.log("Debugging - videoType:", videoType);
  // console.log("Debugging - defaultContent:", defaultContent);
  return (
    <>
      {token ? (
        <MDBox py={3}>
          <MDBox>
            <MDBox mb={1} mt={3} display="flex" alignItems="center">
              <PlayCircleOutlineIcon
                fontSize="large"
                style={{ marginRight: "8px", color: "green" }}
              />{" "}
              {/* Icon */}
              <MDTypography variant="h5" color="textSecondary">
                Now Playing
              </MDTypography>
            </MDBox>
            <MDBox mb={3}>
              <MDTypography variant="h5" fontWeight="medium">
                {localStorage.getItem("lessonName")
                  ? localStorage.getItem("lessonName")
                  : defaultContent?.title}
              </MDTypography>
            </MDBox>
            <Grid container spacing={0} justifyContent="center">
              <Grid item xs={12} lg={12} xl={12}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ height: "auto", width: "100%" }}
                >
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    style={{ height: "auto", width: "100%" }}
                  >
                    {(videoType || defaultContent) && (
                      <>
                        {(videoType?.fetch_type === "Meditation" ||
                          defaultContent?.fetch_type === "Meditation") && (
                          <>
                            <img
                              src={
                                imageUrl?.featured_image ||
                                defaultContent?.featured_image ||
                                fallbackImage
                              }
                              style={{ height: "auto", width: "100%" }}
                            />
                            <AudioPlayerTest
                              source={lessonName ? imageUrl?.audio_url : defaultContent?.audio_url}
                            />
                          </>
                        )}
                        {(videoType?.fetch_type === "Kriya" ||
                          defaultContent?.fetch_type === "Kriya") && (
                          <ReactPlayer
                            url={lessonName ? imageUrl?.video_url : defaultContent?.video_url}
                            controls
                            width="100%"
                            height="50vh"
                            config={{
                              file: {
                                attributes: {
                                  poster:
                                    imageUrl?.featured_image || defaultContent?.featured_image,
                                },
                              },
                            }}
                          />
                        )}
                        {videoType?.fetch_type === "Offline" &&
                          (videoType?.data_source === "MP3" ||
                            defaultContent?.data_source === "MP3") && (
                            <>
                              <img
                                src={
                                  imageUrl?.featured_image ||
                                  defaultContent?.featured_image ||
                                  fallbackImage
                                }
                                style={{ height: "auto", width: "100%" }}
                              />
                              <MDBox className={isDesktop ? "lesson-media" : "lesson-audio-player"}>
                                <AudioPlayerTest
                                  source={
                                    lessonName ? videoType?.media_link : defaultContent?.audio_url
                                  }
                                />
                              </MDBox>
                            </>
                          )}
                        {videoType?.fetch_type === "Offline" &&
                          (videoType?.data_source === "MP4" ||
                            videoType?.data_source === "Vimeo" ||
                            videoType?.data_source === "Youtube" ||
                            videoType?.data_source === "Embedded" ||
                            defaultContent?.data_source === "MP4" ||
                            defaultContent?.data_source === "Vimeo" ||
                            defaultContent?.data_source === "Youtube" ||
                            defaultContent?.data_source === "Embedded") && (
                            <ReactPlayer
                              url={lessonName ? videoType?.media_link : defaultContent?.media_link}
                              controls
                              width="100%"
                              height="50vh"
                              config={{
                                file: {
                                  attributes: {
                                    poster:
                                      imageUrl?.featured_image || defaultContent?.featured_image,
                                  },
                                },
                              }}
                            />
                          )}
                      </>
                    )}

                    {!(videoType || defaultContent) && <Loader />}
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      ) : (
        <RedirectLoginPage />
      )}
    </>
  );
}

export default Lesson;
