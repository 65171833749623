import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader/Loader";
import Countdown from "react-countdown";
import LoaderNew from "components/Loader/LoaderNew";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";
import EventCard from "examples/Cards/BlogCards/EventCard";
import logoSd from "./SD_LOGO.png";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

// Constants for better readability
const COURSE_NOT_STARTED = "no\r\n";

function CurrentEnrolledCourses() {
  const [meditationData, setMeditationData] = useState([]);
  const Navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user")) || {};
  // const programSlug = localStorage.getItem("programSlug") || "";
  const [timer, setTimer] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const { courseIdGlobal, pslugGlobal, linkedGlobal } = useHookstate(globalStore);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  useEffect(() => {
    fetch(`https://dev.guruatmananda.org/wp-json/bts/v1/courses/${userData?.user_data?.sub}`)
      .then((response) => response.json())
      .then((data) => setMeditationData(data))
      .catch((error) => console.error(error));
  }, []);

  const convertDateToTimestamp = (dateString) => {
    const [datePart, timePart] = (dateString || "").split(", ");
    const [month, day, year] = (datePart || "").split("-");
    const [time, meridiem] = (timePart || "").split(" ");
    const [hours, minutes, seconds] = (time || "").split(":");
    let hours24 = parseInt(hours, 10);
    if (meridiem === "PM" && hours24 < 12) {
      hours24 += 12;
    } else if (meridiem === "AM" && hours24 === 12) {
      hours24 = 0;
    }
    const timestamp = new Date(year, month - 1, day, hours24, minutes, seconds).getTime();
    return timestamp;
  };

  const isFutureStartDate = (startDate) => {
    const currentDate = new Date();
    return new Date(startDate) > currentDate;
  };

  const dateArray = (meditationData?.courses || [])
    .map((course, index) => {
      if (!course) {
        return null; // Handle missing course data
      }
      const timestamp = convertDateToTimestamp(course?.next_pay);
      const difference = timestamp - Date.now();
      const targetDate = Date.now() + difference;

      return {
        targetDate,
        title: course?.title,
        isFutureStartDate: isFutureStartDate(course?.start_date),
      };
    })
    .filter(Boolean); // Remove null values

  const Completionist = () => (
    <span style={{ fontSize: "14px", fontWeight: 500, color: "red" }}>Renew Now</span>
  );

  const handleDownload = (pdfUrl) => {
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.target = "_blank";
    anchor.rel = "noopener noreferrer";
    anchor.download = "certificate.pdf";
    anchor.click();
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const formattedDays = days.toString().padStart(2, "0");
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    if (completed) {
      return <Completionist />;
    } else {
      if (days > 4) {
        return (
          <span style={{ fontSize: "14px", fontWeight: 500, color: "green" }}>
            Next Donation After - {formattedDays} Days &#128513;
          </span>
        );
      } else if (days > 1 && days <= 4) {
        return (
          <span style={{ fontSize: "14px", fontWeight: 500, color: "yellow" }}>
            Next Donation After - {formattedDays} Days
          </span>
        );
      } else {
        return (
          <span style={{ fontSize: "14px", fontWeight: 500, color: "red" }}>
            Next Donation After - {formattedHours}:{formattedMinutes}:{formattedSeconds} &#9200;
          </span>
        );
      }
    }
  };

  const handleCourseClick = (courseId) => {
    // localStorage.setItem("courseId", courseId);
    courseIdGlobal.set(courseId);
  };
  const handleEnrollNowClick = (item) => {
    // localStorage.setItem("pslug", item?.slug);
    pslugGlobal.set(item?.slug);
  };

  const handleRenewClick = (item) => {
    // localStorage.setItem("pslug", item?.slug);
    pslugGlobal.set(item?.slug);
    // localStorage.setItem("linked", item?.linked_product);
    linkedGlobal.set(item?.linked_product);
  };
  const handleAccessNowClick = (item) => {
    // localStorage.setItem("linked", item?.linked_product);
    linkedGlobal.set(item?.linked_product);
    if (
      item?.course_completed === "start" &&
      item?.status === "active" &&
      !isFutureStartDate(item?.start_date)
    ) {
    }
    Navigate(`/courses/classroom/class`);
  };
  const handleOnboardingClick = (item) => {
    // localStorage.setItem("pslug", item?.slug);
    pslugGlobal.set(item?.slug);

    // localStorage.setItem("linked", item?.linked_product);
    linkedGlobal.set(item?.linked_product);
  };
  const handleApplyNow = async (item) => {
    try {
      const userId = userData?.user_data?.sub;
      const courseId = item?.id;
      const token = localStorage.getItem("token");

      const requestData = {
        user_id: userId,
        data: [
          {
            meta_key: `application_${courseId}`,
            meta_value: "applied",
          },
        ],
      };

      const response = await fetch(
        "https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/update",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error("API Request Error");
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error("Error:", error.message);
      return Promise.reject(error.message);
    }
  };

  if (!meditationData) {
    return <LoaderNew />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={6}>
        {/* Your JSX content here */}
        <Grid container spacing={3}>
          {meditationData?.courses?.length > 0 ? (
            meditationData?.courses?.map((item, index) => (
              <Grid item xs={12} md={6} lg={4} key={item?.id}>
                <MDBox mt={3}>
                  <SimpleBlogCard
                    image={item?.featured_image}
                    title={
                      <MDTypography variant="h6" fontWeight="bold" fontSize={18}>
                        {item?.title}
                      </MDTypography>
                    }
                    description={
                      item?.status === "non_active" ? null : (item?.status === "active" ||
                          item?.status === "pending") &&
                        item?.course_completed === "no" ? (
                        <span style={{ color: "#FB8C00", fontSize: "14px", fontWeight: 500 }}>
                          Complete Onboarding &#128071;
                        </span>
                      ) : item?.course_completed === "start" && item?.next_pay == null ? (
                        <span style={{ color: "green", fontSize: "14px", fontWeight: 500 }}>
                          Congratulations !!! No Dues &#128076;{" "}
                        </span>
                      ) : item?.course_completed === "start" &&
                        item?.status === "active" &&
                        !isFutureStartDate(item?.start_date) ? (
                        <>
                          <Countdown date={dateArray[index]?.targetDate} renderer={renderer} />
                          {/* Add your "Access Now" button here */}
                          {/* <button onClick={() => handleAccessNowClick(item)}>Access Now</button> */}
                        </>
                      ) : (item?.status === "on-hold" || item?.status === "renew") &&
                        item?.course_completed === "start" ? (
                        <span style={{ color: "red", fontSize: "14px", fontWeight: 500 }}>
                          Renew Course &#129311;{" "}
                        </span>
                      ) : null
                    }
                    onClick={() => handleCourseClick(item?.id)}
                    action={
                      item?.apply === "Yes" && item?.application === ""
                        ? {
                            type: "internal",
                            route: "#",
                            color: "success",
                            label: (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleApplyNow(item)}
                              >
                                Apply Now
                              </span>
                            ),
                          }
                        : item?.apply === "Yes" && item?.application === "rejected"
                        ? {
                            type: "internal",
                            route: "#",
                            color: "warning",
                            label: (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleApplyNow(item)}
                              >
                                Apply Now
                              </span>
                            ),
                          }
                        : item?.apply === "Yes" && item?.application === "applied"
                        ? {
                            type: "disabled",
                            label: "Applied",
                          }
                        : item?.apply === "No" &&
                          item?.course_completed === "completed" &&
                          item?.certificate === "no"
                        ? {
                            type: "internal",
                            route: "/request-certificate",
                            color: "dark",
                            label: "Request Certificate",
                          }
                        : item?.status === "non_active" &&
                          (item?.course_completed === "no" ||
                            item?.course_completed === "pending-cancel")
                        ? {
                            type: "internal",
                            route: "/payment-plans",
                            color: "info",
                            label: (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEnrollNowClick(item)}
                              >
                                Enroll Now
                              </span>
                            ),
                          }
                        : (item?.status === "active" || item?.status === "pending") &&
                          item?.course_completed === "no"
                        ? {
                            type: "internal",
                            route: "/program-onboarding",
                            color: "warning",
                            label: (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleOnboardingClick(item)}
                              >
                                Complete Onboarding
                              </span>
                            ),
                          }
                        : item?.status === "active" &&
                          item?.course_completed === "start" &&
                          item?.start_date &&
                          new Date(item?.start_date) >= new Date()
                        ? {
                            type: "disabled",
                            label: "Starts Soon",
                          }
                        : item?.status === "active" &&
                          item?.course_completed === "start" &&
                          item?.start_date &&
                          new Date(item?.start_date) < new Date()
                        ? {
                            type: "internal",
                            route: `/courses/classroom/class`,
                            color: "info",
                            label: (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleAccessNowClick(item)}
                              >
                                Access Now
                              </span>
                            ),
                          }
                        : item?.status === "on-hold" || item?.status === "renew"
                        ? {
                            type: "internal",
                            route: "/renew",
                            color: "error",
                            label: (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRenewClick(item)}
                              >
                                Renew Course
                              </span>
                            ),
                          }
                        : item?.certificate === "Yes" && item?.course_completed === "completed"
                        ? {
                            type: "internal",
                            route: "/request-certificate",
                            color: "dark",
                            label: "Request Certificate",
                          }
                        : item?.certificate === "Yes" && item?.course_completed === "requested"
                        ? {
                            type: "disabled",
                            label: "Certificate Requested",
                          }
                        : item?.certificate === "Yes" &&
                          item?.course_completed === "certificate_issued"
                        ? {
                            type: "internal",
                            // route: '/download-certificate',
                            color: "success",
                            label: (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleDownload(
                                    "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                                  )
                                }
                              >
                                Download Certificate
                              </span>
                            ),
                          }
                        : {
                            type: "internal",
                            route: `/courses/classroom/class`,
                            color: "info",
                            label: (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleAccessNowClick(item)}
                              >
                                Access Now
                              </span>
                            ),
                          }
                    }
                  />
                </MDBox>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <MDBox mt={3}>
                <MDTypography variant="h6" fontWeight="bold">
                  No Subscription found
                </MDTypography>
              </MDBox>
            </Grid>
          )}
          {!meditationData && <Loader />}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CurrentEnrolledCourses;
