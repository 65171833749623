import React, { useState } from "react";
import PropTypes from "prop-types";
import AssignmentDataTable from "examples/Tables/AssignmentDatatable";
import ButtonCell from "./components/ButtonCell";
import { Modal, Backdrop, Fade, Card, CardContent } from "@mui/material";
import MDButton from "components/MDButton";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

/* eslint-disable react/prop-types */
const key = "ck_e07619a34325d4367c758704b04844c734ecc86d";
const password = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";

const AllPaymentTracking = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [error, setError] = useState(null);
  const { subidGlobal } = useHookstate(globalStore);

  const openModal = (rowData) => {
    // localStorage.setItem("subid", rowData.subscription_id);
    subidGlobal.set(rowData.subscription_id);
    setSelectedApplication(rowData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedApplication(null);
    setIsModalOpen(false);
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const isOverdue = (nextPaymentDate) => {
    const today = new Date();
    const paymentDate = new Date(nextPaymentDate);
    return today > paymentDate ? "Yes" : "No";
  };

  // Map the API response data to the required format for the table
  const rows = data.map((application) => ({
    subscription_id: application.subscription_id,
    full_name: application.full_name,
    email: application.email,
    phone_number: application.phone_number,
    product_name: application.product_name,
    amount: application.amount,
    subscription_status: application.subscription_status,
    next_payment_date:
      application.next_payment_date === "NA"
        ? "No Dues"
        : formatDate(application.next_payment_date),
    overdue:
      application.subscription_status === "on-hold" ||
      application.subscription_status === "pending-cancel"
        ? "Yes"
        : isOverdue(application.next_payment_date), // Calculate "Overdue" status
    dropout: application.subscription_status === "pending-cancel" ? "Yes" : "No", // Calculate "Dropout" status
  }));

  const handleCancelSubscription = async () => {
    // const subscription = localStorage.getItem("subid");
    const subscription = subidGlobal.get({ noproxy: true });

    try {
      // Define the API URL
      const apiUrl = `https://dev.guruatmananda.org/wp-json/wc/v3/subscriptions/${subscription}`;
      const token = localStorage.getItem("token");
      // Define the request headers, including the Authorization header with your token
      const headers = {
        Authorization: `Basic ${btoa(`${key}:${password}`)}`, // Include key and password in the Authorization header
        "Content-Type": "application/json",
      };

      // Define the request body
      const requestBody = JSON.stringify({
        status: "pending-cancel",
      });

      // Make the API request using fetch
      const response = await fetch(apiUrl, {
        method: "PUT", // Use PUT method for updating resource
        headers: headers,
        body: requestBody,
      });

      // Check if the response is successful (status code 200)
      if (response.ok) {
        // Handle success here
        console.log("Subscription status updated successfully.");
      } else {
        // Handle errors here
        console.error("Failed to update subscription status.");
      }

      // Close the modal after handling the request
      closeModal();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const columns = [
    {
      Header: "Name",
      accessor: "full_name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone No.",
      accessor: "phone_number",
    },
    {
      Header: "Program",
      accessor: "product_name",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Next Payment",
      accessor: "next_payment_date",
    },
    {
      Header: "Overdue",
      accessor: "overdue",
    },
    {
      Header: "Dropout",
      accessor: "dropout",
    },
    {
      Header: "Action",
      accessor: "subscription_id",
      Cell: ({ row }) => {
        const isOverdue = row.original.overdue === "Yes";
        const isDropout = row.original.dropout === "Yes";

        // Show the button only if overdue is "Yes" and dropout is "No"
        if (isOverdue && !isDropout) {
          return (
            <ButtonCell
              color="primary"
              label="Dropout"
              onClick={() => openModal(row.original)}
              rowData={row.original}
            />
          );
        } else {
          return null;
        }
      },
    },
  ];

  return (
    <>
      {data.length === 0 ? (
        <div>No data available.</div>
      ) : (
        <AssignmentDataTable
          heading="Payment Tracking"
          table={{ columns, rows }}
          canSearch
          entriesPerPage={true}
        />
      )}
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Card>
              <CardContent>
                <h2> Mark Dropout</h2>
                {selectedApplication && (
                  <>
                    <p>
                      You will not be able to revert the subscription status.
                      <br />
                      Are you sure?
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <MDButton
                        variant="contained"
                        color="error"
                        onClick={handleCancelSubscription}
                      >
                        Yes
                      </MDButton>
                      <MDButton variant="contained" color="success" onClick={closeModal}>
                        No
                      </MDButton>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

AllPaymentTracking.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      subscription_id: PropTypes.number.isRequired,
      full_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone_number: PropTypes.string.isRequired,
      product_name: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
      subscription_status: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AllPaymentTracking;
