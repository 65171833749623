import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ModifiedProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard copy";
import AllApplicationTracking from "layouts/ecommerce/orders/order-list copy 5";

function ApplicationTracking() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [activeStatus, setActiveStatus] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Function to fetch data from the API
  const fetchData = () => {
    const token = localStorage.getItem("token");

    if (token) {
      fetch(
        "https://dev.guruatmananda.org/wp-json/custom/v1/users_meta_course/?pattern=application_%",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setAllData(data);
          setFilteredData(data); // Initially, set the filtered data to all data
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);

  const handleStatusChange = (status) => {
    setActiveStatus(status);

    if (status === "All") {
      setFilteredData(allData); // Show all data
    } else {
      const filtered = allData.filter((item) => item.status === status);
      setFilteredData(filtered); // Filter data based on status
    }
  };
  const allCount = allData.length;
  const approvedCount = allData.filter((item) => item.status === "approved").length;
  const rejectedCount = allData.filter((item) => item.status === "rejected").length;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <div
          style={{
            overflowX: "auto",
            display: "flex",
            flexDirection: "row", // Change to row on smaller screens
            alignItems: "flex-start",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={4}>
              <ModifiedProjectCard
                icon="sync"
                number={allCount}
                description={
                  <span
                    style={{ fontSize: "20px", fontWeight: 600, color: "black", lineHeight: "1.5" }}
                  >
                    Applied Applications
                  </span>
                }
                iconBackgroundColor="linear-gradient(310deg, #7928CA, #FF0080)"
                iconColor="white"
                numberColor="#851aa1"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <ModifiedProjectCard
                icon="sync"
                number={approvedCount}
                description={
                  <span
                    style={{ fontSize: "20px", fontWeight: 600, color: "black", lineHeight: "1.5" }}
                  >
                    Approved Applications
                  </span>
                }
                iconBackgroundColor="linear-gradient(310deg, #7928CA, #FF0080)"
                iconColor="white"
                numberColor="#0d7419"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <ModifiedProjectCard
                icon="sync"
                number={rejectedCount}
                description={
                  <span
                    style={{ fontSize: "20px", fontWeight: 600, color: "black", lineHeight: "1.5" }}
                  >
                    Rejected Applications
                  </span>
                }
                iconBackgroundColor="linear-gradient(310deg, #7928CA, #FF0080)"
                iconColor="white"
                numberColor="#740d0d"
              />
            </Grid>
            {/* Add more cards as needed */}
          </Grid>
        </div>
      </MDBox>
      <MDBox my={3}>
        <div style={{ overflowX: "auto", display: "flex" }}>
          {["All", "applied", "rejected", "approved"].map((status, index) => (
            <MDButton
              key={index}
              variant="contained"
              style={{
                background:
                  activeStatus === status ? "linear-gradient(310deg, #7928CA, #FF0080)" : "",
                color: activeStatus === status ? "white" : "#344767",
                marginRight: "10px",
              }}
              onClick={() => handleStatusChange(status)}
              px={2}
            >
              {status}
            </MDButton>
          ))}
        </div>
      </MDBox>
      {isLoading ? <div>Loading...</div> : <AllApplicationTracking data={filteredData} />}
    </DashboardLayout>
  );
}

export default ApplicationTracking;
