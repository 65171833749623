import React from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom"; // Import useNavigate

function ButtonCell({ color, label, route, onClick }) {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleButtonClick = () => {
    if (route) {
      // If a route is specified, navigate to it when the button is clicked
      navigate(route);
    }

    if (onClick) {
      // If an onClick function is provided, call it when the button is clicked
      onClick();
    }
  };

  return (
    <MDBox display="flex" alignItems="center">
      <MDButton variant="contained" color={color} onClick={handleButtonClick}>
        {label}
      </MDButton>
    </MDBox>
  );
}

ButtonCell.defaultProps = {
  color: "primary",
  label: "Button",
  route: null,
  onClick: null,
};

ButtonCell.propTypes = {
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  label: PropTypes.string,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonCell;
