import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import logo from "./Sanmarga Awakening 01.png";

function ResetIllustration() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Email validation regex pattern
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailPattern.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    // Clear the error message if validation passes
    setError("");

    try {
      // Define the API endpoint and request options
      const apiUrl = "https://dev.guruatmananda.org//wp-json/bts1/v1/forgot-password";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }), // Pass the email in the request body
      };

      // Make the API call
      const response = await fetch(apiUrl, requestOptions);

      // Check if the API call was successful
      if (response.ok) {
        // API call successful, you can handle the response here
        setMessage("Password reset link sent successfully!");
        navigate("/");
      } else {
        // API call failed, handle the error here
        setMessage("Failed to send password reset link.");
      }
    } catch (error) {
      console.error("An error occurred while making the API call:", error);
      setMessage("An error occurred while sending the request.");
    }
  };

  const illustration = "https://runcloud-programs.s3.ap-south-1.amazonaws.com/SD+login.png";

  return (
    <IllustrationLayout
      // title="Reset Password"
      logo={logo}
      // description="Enter your email to receive reset password link"
      illustration={illustration}
    >
      <MDBox component="form" role="form" onSubmit={handleFormSubmit}>
        <MDBox mb={2}>
          <MDInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            fullWidth
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" size="large" fullWidth>
            Send Reset Link
          </MDButton>
        </MDBox>

        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Already have an account?{" "}
            <MDTypography
              component={Link}
              to="/authentication/sign-in/illustration"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Log In
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
      {error && (
        <MDBox mb={2} color="error">
          {error}
        </MDBox>
      )}
      {message && (
        <MDBox
          mb={2}
          color={message.includes("Failed to send password reset link.") ? "error" : "success"}
        >
          {message}
        </MDBox>
      )}
    </IllustrationLayout>
  );
}

export default ResetIllustration;
