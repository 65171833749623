import { hookstate } from "@hookstate/core";

const globalStore = hookstate({
  name: "",
  userGlobal: "",
  programSlugGlobal: "",
  tokenGlobal: "",
  courseNameGlobal: "",
  lessonNameGlobal: "",
  courseIdGlobal: "",
  pslugGlobal: "",
  linkedGlobal: "",
  orderIdGlobal: "",
  spidGlobal: "",
  meditationDetailGlobal: "",
  cidGlobal: "",
  assidGlobal: "",
  subidGlobal: "",
});

export default globalStore;
