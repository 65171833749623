import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import Header from "layouts/pages/account/settings/components/Header";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
import Authentication from "layouts/pages/account/settings/components/Authentication";
import Accounts from "layouts/pages/account/settings/components/Accounts";
import Notifications from "layouts/pages/account/settings/components/Notifications";
import Sessions from "layouts/pages/account/settings/components/Sessions";
import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";
import BillingAddress from "./components/BillingAddress";
import ShippingAddress from "./components/ShippingAddress";
import StudentDetails from "../settings/components/StudentDetails/studentdetails";
import FamilyDetails from "../settings/components/FamilyDetails/familyDetails";
import MedicalDetails from "../settings/components/MedicalInformation/medicalDetails";
import AdditionalDetails from "../settings/components/billing/components/AdditionalDetails/additionalDetails";
import Loader from "components/Loader/Loader";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AdditionalDetailsClone from "../settings/components/billing/components/AdditionalDetails copy/additionalDetailsClone";
import Address from "../address";
import ManagePassword from "../managePassword";
import BillingAddressMain from "../settings/components/BillingAddress";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";

function BasicInfoMain() {
  const [userData, setUserData] = useState(null);
  const [header, setheader] = useState(null);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const { name } = useHookstate(globalStore);
  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem("token"); // Get the token from local storage
        const response = await fetch(
          `https://dev.guruatmananda.org/wp-json/custom-user-meta/v1/fetch/${user?.user_data?.sub}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request headers
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setUserData(data.meta_data);
        } else {
          console.error("Error fetching user data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `https://dev.guruatmananda.org/wp-json/bts/my-account/v1/user/${user?.user_data?.sub}`
        );
        if (response.ok) {
          const data = await response.json();
          setheader(data);
        } else {
          console.error("Error fetching user data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    fetchData();
  }, []);

  if (!userData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader />
      </div>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid> */}
          <Grid item xs={12} lg={12}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header userData={header} metaData={userData} />
                </Grid>

                <Grid item xs={12}>
                  <StudentDetails userData={userData} />
                </Grid>
                <Grid item xs={12}>
                  <FamilyDetails userData={userData} />
                </Grid>
                <Grid item xs={12}>
                  <BillingAddressMain userData={userData} />
                </Grid>
                <Grid item xs={12}>
                  <ManagePassword />
                </Grid>
                {/* <Grid item xs={12}>
                  <AdditionalDetailsClone userData={userData} />
                </Grid>
                <Grid item xs={12}>
                  <MedicalDetails userData={userData} /> */}
                {/* </Grid/> */}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default BasicInfoMain;
