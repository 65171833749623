/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React ./layouts
import Analytics from "./layouts/dashboards/analytics";
import Sales from "./layouts/dashboards/sales";
import ProfileOverview from "./layouts/pages/profile/profile-overview";
import AllProjects from "./layouts/pages/profile/all-projects";
import NewUser from "./layouts/pages/users/new-user";
import Settings from "./layouts/pages/account/settings";
import Billing from "./layouts/pages/account/billing";
import Invoice from "././layouts/pages/account/invoice";
import Timeline from "./layouts/pages/projects/timeline";
import PricingPage from "./layouts/pages/pricing-page";
import Widgets from "./layouts/pages/widgets";
import RTL from "./layouts/pages/rtl";
import Charts from "./layouts/pages/charts";
import Notifications from "./layouts/pages/notifications";
import Kanban from "./layouts/applications/kanban";
import Wizard from "./layouts/applications/wizard";
import DataTables from "./layouts/applications/data-tables";
import Calendar from "./layouts/applications/calendar";
import NewProduct from "./layouts/ecommerce/products/new-product";
import EditProduct from "./layouts/ecommerce/products/edit-product";
import ProductPage from "./layouts/ecommerce/products/product-page";
import OrderList from "./layouts/ecommerce/orders/order-list";
import OrderDetails from "./layouts/ecommerce/orders/order-details";
import SignInBasic from "./layouts/authentication/sign-in/basic";
import SignInCover from "./layouts/authentication/sign-in/cover";
import SignInIllustration from "./layouts/authentication/sign-in/illustration";
import SignUpCover from "./layouts/authentication/sign-up/cover";
import ResetCover from "./layouts/authentication/reset-password/cover";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import EnrolledCourses from "./layouts/dashboards/EnrolledCourses";
import Class from "./layouts/courses/classroom/class";
// import Webinar from "./layouts/webinar/webinar";
import SubscriptionsList from "./layouts/ecommerce/orders/subscriptions-list";
import Wallet from "./layouts/pages/account/wallet";
import LinkedPayments from "./layouts/pages/account/wallet/linkedPayment";
import BasicInfoMain from "./layouts/pages/account/basicInfo";
import Address from "./layouts/pages/account/address";
import ManagePassword from "./layouts/pages/account/managePassword";
// import Lecture from "./layouts/courses/classroom/lecture";
import Lesson from "./layouts/courses/classroom/lecture";
import WebinarFinal from "./layouts/webinar/calendar";
import Illustration from "./layouts/authentication/sign-in/illustration";
import WizardDetails from "./layouts/applications/wizard_student_details";
import TutorialVideos from "./layouts/dashboards/TutorialVideos";
import Videos from "./layouts/dashboards/Videos";
import Chats from "./layouts/dashboards/Chats";
import Assignment from "./layouts/applications/assignmentWizard";
import RenewCourses from "./layouts/dashboards/sales copy";
import AssignmentList from "./layouts/applications/assignmentWizard/assignmentList";
import Programs from "layouts/dashboards/EnrolledCourses copy";
import CompleteOrderList from "layouts/ecommerce/orders/order-list copy";
import MeditationListing from "layouts/dashboards/meditationListing";
import MeditationDetails from "layouts/dashboards/meditationListing/meditationDetails";
import PodcastListing from "layouts/dashboards/podcasts/podcast-listing";
import BlogListing from "layouts/dashboards/blogs/blog-listing";
import BlogDetails from "layouts/dashboards/blogs/blog-details";
import QuoteListing from "layouts/dashboards/quotes";
import EventListing from "layouts/dashboards/event/event-listing";
import PodcastDetails from "layouts/dashboards/podcasts/podcast-details";
import EventDetails from "layouts/dashboards/event/event-details";
import TrackPayments from "layouts/applications/assignmentWizard copy";
import CertificateApplication from "layouts/applications/assignmentWizard copy 2";
import ApplicationTracking from "layouts/applications/assignmentWizard copy 3";
import CurrentEnrolledCourses from "layouts/dashboards/EnrolledCourses/currentenrolledCourses";
import SignupIllustration from "layouts/authentication/sign-up/illustration";
import ResetIllustration from "layouts/authentication/reset-password/illustration";
import PaymentPlans from "layouts/dashboards/EnrolledCourses copy/paymentPlan";
import CheckoutPage from "layouts/dashboards/EnrolledCourses copy/checkout";
import RenewCheckoutPage from "layouts/dashboards/EnrolledCourses copy/renewCheckout";
import LinkedClass from "layouts/courses/classroom/class/freeClass";
/* eslint-disable */
let routes = [];
let user = JSON.parse(localStorage.getItem("user"));

const additionalRoutes = [
  {
    name: "Class",
    route: "/courses/classroom/class",
    key: "class",
    component: <Class />,
  },
  {
    name: "Blog Details",
    route: "/blog-details",
    key: "blog-details",
    component: <BlogDetails />,
  },
  {
    name: "Podcast Details",
    route: "/podcast-details",
    key: "podcast-details",
    component: <PodcastDetails />,
  },
  {
    name: "Event Details",
    route: "/event-details",
    key: "event-details",
    component: <EventDetails />,
  },
  {
    name: "Meditation Details",
    route: "/meditation-details",
    key: "meditation-details",
    component: <MeditationDetails />,
  },
  {
    name: "Enrolled Courses",
    route: "/dashboards/enrolled-courses",
    key: "enrolled-courses",
    component: <EnrolledCourses />,
  },
  // {
  //   name: "Active Courses",
  //   route: "/dashboards/active-courses",
  //   key: "active-courses",
  //   component: <CurrentEnrolledCourses />,
  // },
  // {
  //   name: "Lesson",
  //   route: "/courses/classroom/lesson",
  //   key: "lesson",
  //   component: <Lesson />,
  // },
  {
    name: "Payment Plans",
    route: "/payment-plans",
    key: "payment-plans",
    component: <PaymentPlans />,
  },
  {
    name: "Sign-in",
    route: "/authentication/sign-in/illustration",
    key: "illustration",
    component: <SignInIllustration />,
  },
  {
    name: "Reset",
    route: "/reset-password",
    key: "reset-password",
    component: <ResetIllustration />,
  },
  {
    name: "Sign-up",
    route: "/sign-up",
    key: "sign-up",
    component: <SignupIllustration />,
  },
  {
    name: "Checkout",
    route: "/checkout",
    key: "checkout",
    component: <CheckoutPage />,
  },
  {
    name: "Renew Checkout",
    route: "/renew",
    key: "renew",
    component: <RenewCheckoutPage />,
  },
  {
    name: "Wizard",
    route: "/program-onboarding",
    key: "program-onboarding",
    component: <WizardDetails />,
  },
  {
    name: "Order Details",
    route: `/order-details`,
    key: "order-details",
    component: <OrderDetails />,
  },
  // {
  //   name: "Renew",
  //   route: `/ecommerce/orders/renew`,
  //   key: "renew-details",
  //   component: <RenewCourses />,
  // },
];

// Use optional chaining to safely access user.roles
const roles = user?.roles;

if (roles?.includes("administrator") || roles?.includes("mentor")) {
  routes = [
    // {
    //   type: "collapse",
    //   name: "Profile",
    //   key: "my-profile",
    //   icon: <MDAvatar src={""} alt="User Photo" size="sm" />,
    //   collapse: [
    //     {
    //       name: "Profile Info",
    //       key: "basicInfo",
    //       route: "/pages/account/my-profile",
    //       component: <BasicInfoMain />,
    //     },
    //   ],
    // },
    {
      type: "nonCollapse",
      name: "Profile",
      icon: <Icon>person_icon</Icon>,
      route: "/account/my-profile",
      key: "my-profile",
      component: <BasicInfoMain />,
    },
    {
      type: "nonCollapse",
      name: "Programs",
      icon: <Icon>apps_icon</Icon>,
      route: "/dashboards/programs",
      key: "programs",
      component: <Programs />,
    },
    {
      type: "nonCollapse",
      name: "Active Courses",
      icon: <Icon>school_icon</Icon>,
      route: "/dashboards/active-courses",
      key: "active-courses",
      component: <CurrentEnrolledCourses />,
    },
    {
      type: "nonCollapse",
      name: "Wallet",
      icon: <Icon>account_balance_wallet_icon</Icon>,
      key: "wallet",
      route: "/account/wallet",
      component: <Wallet />,
    },
    // {
    //   type: "nonCollapse",
    //   name: "Linked Payments",
    //   icon: <Icon>insert_link_icon</Icon>,
    //   key: "linked-payments",
    //   route: "/account/linked-payments",
    //   component: <LinkedPayments />,
    // },
    {
      type: "nonCollapse",
      name: "Payment Details",
      icon: <Icon>article_icon</Icon>,
      key: "payment-details",
      route: "/payment-details",
      component: <CompleteOrderList />,
    },
    {
      type: "nonCollapse",
      name: "My Calendar",
      icon: <Icon>event_note_icon</Icon>,
      key: "event-calendar",
      route: "/webinar/event-calendar",
      component: <WebinarFinal />,
    },
    {
      type: "nonCollapse",
      name: "Tutorial Videos",
      icon: <Icon>video_library_icon</Icon>,
      key: "tutorial-video",
      route: "/tutorial-video",
      component: <TutorialVideos />,
    },
    {
      type: "nonCollapse",
      name: "Social Media",
      icon: <Icon>book_icon</Icon>,
      key: "social-media",
      route: "/social-media",
      component: <Videos />,
    },
    { type: "divider", key: "divider-0" },

    {
      type: "nonCollapse",
      name: "Track Payments",
      icon: <Icon>payments_outlined_icon</Icon>,
      route: "/payments",
      key: "payments",
      component: <TrackPayments />,
    },
    {
      type: "nonCollapse",
      name: "Track Certificate",
      icon: <Icon>emoji_events_icon</Icon>,
      route: "/track-certificate",
      key: "track-certificate",
      component: !(user && user.roles?.includes("subscriber")) ? <CertificateApplication /> : null,
    },
    {
      type: "nonCollapse",
      name: "Track Application",
      icon: <Icon>post_add_icon</Icon>,
      route: "/application-tracker",
      key: "application-tracker",
      component: <ApplicationTracking />,
    },
    ...additionalRoutes,
  ];
} else {
  routes = [
    {
      type: "nonCollapse",
      name: "Profile",
      icon: <Icon>person_icon</Icon>,
      route: "/account/my-profile",
      key: "my-profile",
      component: <BasicInfoMain />,
    },
    {
      type: "nonCollapse",
      name: "Programs",
      icon: <Icon>apps_icon</Icon>,
      route: "/dashboards/programs",
      key: "programs",
      component: <Programs />,
    },
    {
      type: "nonCollapse",
      name: "Active Courses",
      icon: <Icon>school_icon</Icon>,
      route: "/dashboards/active-courses",
      key: "active-courses",
      component: <CurrentEnrolledCourses />,
    },
    {
      type: "nonCollapse",
      name: "Wallet",
      icon: <Icon>account_balance_wallet_icon</Icon>,
      key: "wallet",
      route: "/account/wallet",
      component: <Wallet />,
    },
    // {
    //   type: "nonCollapse",
    //   name: "Linked Payments",
    //   icon: <Icon>insert_link_icon</Icon>,
    //   key: "linked-payments",
    //   route: "/account/linked-payments",
    //   component: <LinkedPayments />,
    // },
    {
      type: "nonCollapse",
      name: "Payment Details",
      icon: <Icon>article_icon</Icon>,
      key: "payment-details",
      route: "/payment-details",
      component: <CompleteOrderList />,
    },
    {
      type: "nonCollapse",
      name: "My Calendar",
      icon: <Icon>event_note_icon</Icon>,
      key: "event-calendar",
      route: "/webinar/event-calendar",
      component: <WebinarFinal />,
    },
    {
      type: "nonCollapse",
      name: "Tutorial Videos",
      icon: <Icon>video_library_icon</Icon>,
      key: "tutorial-video",
      route: "/tutorial-video",
      component: <TutorialVideos />,
    },
    {
      type: "nonCollapse",
      name: "Social Media",
      icon: <Icon>book_icon</Icon>,
      key: "social-media",
      route: "/social-media",
      component: <Videos />,
    },
    ...additionalRoutes,
  ];
}

export default routes;

// {
//   type: "nonCollapse",
//   name: "Chats",
//   icon: <Icon>messages</Icon>,
//   key: "Chats",
//   route: "/Chats",
//   component: <Chats />,
// },
// {
//   type: "nonCollapse",
//   name: "Assignments",
//   icon: <Icon>paper</Icon>,
//   route: `/assignment-list`,
//   key: "list",
//   component: <AssignmentList />,
// },
// {
//   type: "nonCollapse",
//   name: "Chats",
//   icon: <Icon>messages</Icon>,
//   key: "Chats",
//   route: "/Chats",
//   component: <Chats />,
// },
// {
//   type: "nonCollapse",
//   name: "Assignments",
//   icon: <Icon>paper</Icon>,
//   route: `/assignment-list`,
//   key: "list",
//   component: <AssignmentList />,
// },
// {
//   type: "nonCollapse",
//   name: "Track Payments",
//   icon: <Icon>paper_icon</Icon>,
//   route: "/payments",
//   key: "payments",
//   component: <TrackPayments />,
// },
// {
//   type: "nonCollapse",
//   name: "Track Certificate",
//   icon: <Icon>paper_icon</Icon>,
//   route: "/track-certificate",
//   key: "track-certificate",
//   component: user && user.roles.includes("mentor") ? <CertificateApplication /> : null,
// },
// {
//   type: "nonCollapse",
//   name: "Track Application",
//   icon: <Icon>paper_icon</Icon>,
//   route: "/application-tracker",
//   key: "application-tracker",
//   component: <ApplicationTracking />,
// },
// {
//   type: "nonCollapse",
//   name: "Meditation",
//   icon: <Icon>paper_icon</Icon>,
//   route: "/meditation-listing",
//   key: "meditation-list",
//   component: <MeditationListing />,
// },
// {
//   type: "nonCollapse",
//   name: "Podcast",
//   icon: <Icon>paper_icon</Icon>,
//   route: "/podcast-listing",
//   key: "podcast-list",
//   component: <PodcastListing />,
// },
// {
//   type: "nonCollapse",
//   name: "Blog",
//   icon: <Icon>paper_icon</Icon>,
//   route: "/blog-listing",
//   key: "blog-list",
//   component: <BlogListing />,
// },
// {
//   type: "nonCollapse",
//   name: "Quotes",
//   icon: <Icon>paper_icon</Icon>,
//   route: "/quotes",
//   key: "quotes",
//   component: <QuoteListing />,
// },
// {
//   type: "nonCollapse",
//   name: "Events",
//   icon: <Icon>paper_icon</Icon>,
//   route: "/events",
//   key: "events",
//   component: <EventListing />,
// },
