import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Header from "layouts/ecommerce/orders/order-details/components/Header";
import OrderInfo from "layouts/ecommerce/orders/order-details/components/OrderInfo";
import BillingInformation from "layouts/ecommerce/orders/order-details/components/BillingInformation";
import OrderSummary from "layouts/ecommerce/orders/order-details/components/OrderSummary";
import { useHookstate } from "@hookstate/core";
import globalStore from "store/global";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

function OrderDetails() {
  const [orderData, setOrderData] = useState(null);
  const [error, setError] = useState(null);
  const { orderIdGlobal } = useHookstate(globalStore);
  // const orderId = localStorage.getItem("order");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const token = localStorage.getItem("token");
        const consumerKey = "ck_e07619a34325d4367c758704b04844c734ecc86d";
        const consumerSecret = "cs_a4fdc60518dc42f90b04aba9729f84560bad1ef9";
        const authToken = btoa(`${consumerKey}:${consumerSecret}`);

        const response = await fetch(
          `https://dev.guruatmananda.org/wp-json/wc/v3/orders/${orderIdGlobal.get({
            noproxy: true,
          })}`,
          {
            headers: {
              Authorization: `Basic ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch order data");
        }

        const data = await response.json();
        setOrderData(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchOrderData();
  }, []);

  if (!orderData) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const { header, orderInfo, billingInformation, orderSummary } = orderData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={6}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox pt={2} px={2}>
                <Header header={orderData} />
              </MDBox>
              <Divider />
              <MDBox pt={1} pb={3} px={2}>
                <MDBox mb={3}>
                  <OrderInfo orderInfo={orderData} />
                </MDBox>
                <Divider />
                <MDBox mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MDBox display="flex" flexDirection="column" height="100%">
                        <BillingInformation billingInformation={orderData} />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox display="flex" flexDirection="column" height="100%">
                        <OrderSummary orderSummary={orderData} />
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* Sticky Button */}
      <MDBox>
        <MDButton
          variant="contained"
          color="primary"
          onClick={() => navigate("/dashboards/active-courses")}
          style={{
            position: "fixed",
            bottom: 16,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
        >
          Go to Active Courses
        </MDButton>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderDetails;
