import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import FormField from "layouts/applications/wizard/components/FormField";

function FamilyDetails({ formData, setFormData, errors, setErrors }) {
  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  return (
    <MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <MDBox mb={2}>
              <FormField
                type="text"
                label="Father's Name"
                value={formData.fatherName || ""}
                onChange={(e) => handleInputChange("fatherName", e.target.value)}
                error={errors.fatherName}
              />
            </MDBox>
            <MDBox mb={2}>
              <FormField
                type="text"
                label="Father's Email"
                value={formData.fatherMailId || ""}
                onChange={(e) => handleInputChange("fatherMailId", e.target.value)}
                error={errors.fatherMailId}
              />
            </MDBox>
            <MDBox mb={2}>
              <FormField
                type="tel"
                label="Father's Mobile Number"
                value={formData.fatherMobileNumber || ""}
                onChange={(e) => handleInputChange("fatherMobileNumber", e.target.value)}
                error={errors.fatherMobileNumber}
              />
            </MDBox>
            <MDBox mb={2}>
              <FormField
                type="text"
                label="Father's Occupation"
                value={formData.fatherOccupation || ""}
                onChange={(e) => handleInputChange("fatherOccupation", e.target.value)}
                error={errors.fatherOccupation}
              />
            </MDBox>
            <MDBox mb={2}>
              <FormField
                type="text"
                label="Mother's Name"
                value={formData.motherName || ""}
                onChange={(e) => handleInputChange("motherName", e.target.value)}
                error={errors.motherName}
              />
            </MDBox>
            <MDBox mb={2}>
              <FormField
                type="text"
                label="Mother's Email"
                value={formData.motherMailId || ""}
                onChange={(e) => handleInputChange("motherMailId", e.target.value)}
                error={errors.motherMailId}
              />
            </MDBox>
            <MDBox mb={2}>
              <FormField
                type="tel"
                label="Mother's Mobile Number"
                value={formData.motherMobileNumber || ""}
                onChange={(e) => handleInputChange("motherMobileNumber", e.target.value)}
                error={errors.motherMobileNumber}
              />
            </MDBox>
            <MDBox mb={2}>
              <FormField
                type="text"
                label="Mother's Occupation"
                value={formData.motherOccupation || ""}
                onChange={(e) => handleInputChange("motherOccupation", e.target.value)}
                error={errors.motherOccupation}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

FamilyDetails.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default FamilyDetails;
