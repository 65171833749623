import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultItem from "examples/Items/DefaultItem";
import Loader from "components/Loader/Loader";
import ComplexProjectCardClone from "examples/Cards/ProjectCards/ComplexProjectCard copy";
import { Grid } from "@mui/material";
import { Padding } from "@mui/icons-material";
import WebinarCard from "examples/Cards/ProjectCards/ComplexProjectCard copy/WebinarCard";

function WebinarEvents() {
  const [webinarData, setWebinarData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userData = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetch(
      `https://dev.guruatmananda.org//wp-json/custom/v1/webinars?user_id=${userData.user_data.sub}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setWebinarData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);
  function convertDateFormat(dateString) {
    const parts = dateString.split("/");
    const day = parts[0].padStart(2, "0");
    const month = parts[1].padStart(2, "0");
    const year = parts[2];

    // Rearrange the parts to the desired format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  const getColor = (webinarDateTime) => {
    const webinarDate = new Date(webinarDateTime);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 2);

    if (webinarDate < today) {
      return "light";
    } else if (webinarDate.toDateString() === tomorrow.toDateString()) {
      return "success";
    } else {
      return "info";
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const currentDate = new Date();
  const pastEvents = [];
  const upcomingEvents = [];

  webinarData.forEach((webinar) => {
    const webinarDateTime = new Date(webinar.webinar_times);
    const color = getColor(webinarDateTime);

    const card = (
      <MDBox p={1} key={webinar.title}>
        <a href={webinar.webinar_link}>
          <WebinarCard
            icon="computer_icon"
            title={webinar.title}
            // You can add other details as needed
            buttonLabel="Join Webinar"
            webinarSchedule={webinar.webinar_times}
            sx={{
              title: { fontSize: 20, fontWeight: 500 },
              webinarSchedule: { color: "green", fontSize: 14 },
              webinarTitle: { fontSize: 20, fontWeight: 400 },
            }}
          />
        </a>
      </MDBox>
    );

    if (webinarDateTime > currentDate) {
      upcomingEvents.push(card);
    } else {
      pastEvents.push(card);
    }
  });
  return (
    <div>
      <MDBox px={2}>
        <MDTypography
          variant="h3"
          fontWeight="medium"
          sx={{ fontSize: "24px", paddingTop: "64px", color: "green" }}
        >
          Events
        </MDTypography>
      </MDBox>
      <MDBox>
        <Grid container spacing={3}>
          {upcomingEvents.length > 0 ? (
            upcomingEvents.map((event, index) => (
              <Grid item key={index} xs={12} md={6} xl={4}>
                {event}
              </Grid>
            ))
          ) : (
            <MDBox py={2} px={2}>
              <Card sx={{ height: "100%", width: "100%", marginTop: "16px", padding: "10px" }}>
                <MDBox px={1}>No Upcoming Events</MDBox>
              </Card>
            </MDBox>
          )}
        </Grid>
      </MDBox>
      <MDBox pt={3} px={2}>
        <MDTypography variant="h3" fontWeight="medium" sx={{ fontSize: "24px" }}>
          Past Events
        </MDTypography>
      </MDBox>
      <MDBox>
        <Grid container spacing={3}>
          {pastEvents.length > 0 ? (
            pastEvents.map((event, index) => (
              <Grid item key={index} xs={12} md={6} xl={4}>
                {event}
              </Grid>
            ))
          ) : (
            <MDBox py={2} px={2}>
              <Card sx={{ height: "100%", width: "100%", marginTop: "16px", padding: "10px" }}>
                <MDBox px={1}>No Past Events</MDBox>
              </Card>
            </MDBox>
          )}
        </Grid>
      </MDBox>
    </div>
  );
}

export default WebinarEvents;
