import React, { useEffect, useState } from "react";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AudioPlayerTest from "layouts/courses/classroom/lecture/components/AudioPlayerTest";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider-custom.css";

function MeditationDetails() {
  const [meditationDetail, setMeditationDetail] = useState("");
  const [meditationData, setMeditationData] = useState(null);

  useEffect(() => {
    const storedMeditationDetail = localStorage.getItem("meditationDetail");
    if (storedMeditationDetail) {
      setMeditationDetail(storedMeditationDetail);
    }
  }, []);

  useEffect(() => {
    if (!meditationDetail) {
      return;
    }

    const apiUrl = `https://dev.guruatmananda.org/wp-json/bts/v1/single_meditation/${meditationDetail}/1`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setMeditationData(data);
      })
      .catch((error) => {
        console.error("Error fetching meditation details:", error);
      });
  }, [meditationDetail]);

  const data = [
    {
      image:
        "https://cdn.shortpixel.ai/spai/q_lossy+w_1029+to_avif+ret_img/www.mindful.org/content/uploads/how-to-meditate.jpg",
      title: "scandinavian",
      description: "descr descr descr descrdescr",
      action: {
        type: "internal",
        route: "/pages/profile/profile-overview",
        color: "info",
        label: "view project",
      },
    },
    {
      image:
        "https://cdn.shortpixel.ai/spai/q_lossy+w_1029+to_avif+ret_img/www.mindful.org/content/uploads/how-to-meditate.jpg",
      title: "modern art",
      description: "descr descr descr descrdescr",
      action: {
        type: "internal",
        route: "/pages/projects/project-2",
        color: "info",
        label: "explore",
      },
    },
    {
      image:
        "https://cdn.shortpixel.ai/spai/q_lossy+w_1029+to_avif+ret_img/www.mindful.org/content/uploads/how-to-meditate.jpg",
      title: "culinary delights",
      description: "descr descr descr descrdescr",
      action: {
        type: "external",
        url: "https://www.culinarydelights.com",
        color: "info",
        label: "visit website",
      },
    },
    {
      image:
        "https://cdn.shortpixel.ai/spai/q_lossy+w_1029+to_avif+ret_img/www.mindful.org/content/uploads/how-to-meditate.jpg",
      title: "adventure travel",
      description: "descr descr descr descrdescr",
      action: {
        type: "internal",
        route: "/pages/travel/adventures",
        color: "info",
        label: "join the adventure",
      },
    },
    {
      image:
        "https://cdn.shortpixel.ai/spai/q_lossy+w_1029+to_avif+ret_img/www.mindful.org/content/uploads/how-to-meditate.jpg",
      title: "nature conservation",
      description: "descr descr descr descrdescr",
      action: {
        type: "internal",
        route: "/pages/initiatives/nature-conservation",
        color: "info",
        label: "get involved",
      },
    },
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={5}>
        <Card style={{ display: "flex", overflow: "hidden" }}>
          <Grid container spacing={2} style={{ flex: "1" }}>
            <Grid item xs={12} md={6} xl={6}>
              <MDBox p={2} style={{ height: "100%" }}>
                <MDBox
                  bgcolor="#E5E7E8"
                  p={2}
                  borderRadius="lg"
                  style={{ display: "flex", flexDirection: "column", height: "50%" }}
                >
                  <img
                    src={meditationData ? meditationData.featured_image : ""}
                    style={{
                      height: "auto", // Both sections take up 100% of their parent's height
                      width: "100%",
                      // maxWidth: "100%",
                    }}
                    alt="Meditation"
                  />

                  <AudioPlayerTest source="https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3" />
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <MDBox style={{ display: "flex", flexDirection: "column", height: "80%" }}>
                <MDTypography
                  variant="h2"
                  fontWeight="bold"
                  style={{ color: "#9103A7" }}
                  textTransform="capitalize"
                  py={3}
                >
                  {meditationData ? meditationData.title : ""}
                </MDTypography>
                <div
                  style={{
                    maxHeight: "auto", // Both sections take up 100% of their parent's height
                    overflow: "auto",
                    padding: "10px",
                  }}
                >
                  <MDTypography variant="body1" color="text">
                    {meditationData ? (
                      <div dangerouslySetInnerHTML={{ __html: meditationData.content }} />
                    ) : (
                      ""
                    )}
                  </MDTypography>
                </div>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <MDBox>
        <MDBox py={3}>
          <MDTypography
            variant="h2"
            fontWeight="bold"
            style={{ color: "#9103A7" }}
            yytextTransform="capitalize"
            py={3}
          >
            Master Grace Meditation
          </MDTypography>
          <Slider {...sliderSettings}>
            {data.map((project, index) => (
              <Grid item xs={12} md={6} xl={3} key={index}>
                <MDBox px={2}>
                  <img src={project.image} style={{ height: "100%", width: "100%" }} />
                </MDBox>
              </Grid>
            ))}
          </Slider>
        </MDBox>
        <MDBox py={3}>
          <MDTypography
            variant="h2"
            fontWeight="bold"
            style={{ color: "#9103A7" }}
            yytextTransform="capitalize"
            py={3}
          >
            Bundled Meditation
          </MDTypography>
          <Slider {...sliderSettings}>
            {data.map((project, index) => (
              <Grid item xs={12} md={6} xl={3} key={index}>
                <MDBox px={2}>
                  <img src={project.image} style={{ height: "100%", width: "100%" }} />
                </MDBox>
              </Grid>
            ))}
          </Slider>
        </MDBox>
        <MDBox py={3}>
          <MDTypography
            variant="h2"
            fontWeight="bold"
            style={{ color: "#9103A7" }}
            textTransform="capitalize"
            py={3}
          >
            Focussed Meditation
          </MDTypography>
          <Slider {...sliderSettings}>
            {data.map((project, index) => (
              <Grid item xs={12} md={6} xl={3} key={index}>
                <MDBox px={2}>
                  <img src={project.image} style={{ height: "100%", width: "100%" }} />
                </MDBox>
              </Grid>
            ))}
          </Slider>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default MeditationDetails;
